import React from 'react';
import cn from 'classnames';

import { Modal } from 'components/_shared/Modal';
import { Button } from 'components/_shared/Button';

import './AuthErrorModal.styles.scss';
import { navigate } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthErrorModal = ({ className, message, ...props }) => {
  const { loginWithRedirect: login } = useAuth0();
  const _className = cn('auth-error-modal', className);

  return (
    <Modal {...props} className={_className}>
      {message && <p className='auth-error-message'>{message}</p>}

      <div className='flex w-full space-x-4 justify-between'>
        <Button
          className='auth-error-button'
          variant='primary'
          onClick={() => navigate('/')}
        >
          Back to site
        </Button>
        <Button
          className='auth-error-button'
          variant='primary'
          onClick={() => login({ prompt: 'login' })}
        >
          Try again
        </Button>
      </div>
    </Modal>
  );
};
