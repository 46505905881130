import { useMemo } from 'react';
import { unescape } from 'lodash';
import * as Yup from 'yup';

import { ApplicationFileField } from '../ApplicationFileField';
import { ApplicationLinksField } from '../ApplicationLinksField';
import { useSpecialFields } from './use-special-fields';
import { useApplicationFormLabels } from './use-application-form-labels';
import { asRequired } from 'utils';

const questionTypeToFieldType = {
  input_text: 'text',
  textarea: 'textarea',
  input_file: 'file',
  multi_value_single_select: 'radio'
};

function questionToField(
  key,
  { description, label, required, fields, placeholder }
) {
  const field = fields[0];
  if (!field || !(field.type in questionTypeToFieldType)) {
    return;
  }
  const options =
    field.values && field.values.length > 0 ? field.values : undefined;
  let type = questionTypeToFieldType[field.type];
  if (type === 'radio' && options.length > 2) {
    type = 'select';
  }

  const props = {
    label: required ? asRequired(label) : label,
    type,
    name: field.name,
    required,
    ...(placeholder ? { placeholder } : {}),
    ...(key === 'email' ? { disabled: true } : {})
  };

  if (key === 'links') {
    return {
      key,
      component: { type: ApplicationLinksField, props }
    };
  }

  if (type === 'file') {
    return {
      key,
      component: {
        type: ApplicationFileField,
        props
      }
    };
  }

  return {
    key,
    field: {
      ...props,
      description,
      options:
        options && options.map(v => ({ ...v, value: v.value.toString() }))
    }
  };
}

function parseCompliance(compliance) {
  const children = [];
  for (const complianceItem of compliance) {
    if (complianceItem.description) {
      children.push({
        component: {
          type: 'div',
          props: {
            className: 'application-form-modal__compliance',
            dangerouslySetInnerHTML: {
              __html: unescape(complianceItem.description)
            }
          }
        }
      });
    }
    for (const question of complianceItem.questions) {
      children.push(questionToField('compliance', question));
    }
  }
  return children.map(v => [v]);
}

function parseRows(stepRows, specialFields, position) {
  const rows = [];
  for (const row of stepRows) {
    let children = [];
    for (const { key, question, questions, config } of row) {
      const { placeholder, validation } = config || {};
      if (key === 'compliance') {
        rows.push(...parseCompliance(position.compliance));
      } else if (key in specialFields) {
        children.push({ key, ...specialFields[key] });
      } else if (question) {
        children.push({
          ...questionToField(key, {
            ...question,
            placeholder
          }),
          validationRule: validation
        });
      } else if (questions) {
        if (children.length) {
          rows.push(children);
          children = [];
        }
        questions.forEach(question =>
          rows.push([questionToField(key, question)])
        );
      }
    }

    if (children.length) {
      rows.push(children);
    }
  }
  return rows;
}

const URL_RX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

function applyCustomValidationRule(currentRule, validationRule) {
  if (validationRule.type && validationRule.type === 'email') {
    return currentRule.email(validationRule.errorMessage);
  } else if (validationRule.type && validationRule.type === 'url') {
    return currentRule.matches(URL_RX, validationRule.errorMessage);
  } else if (validationRule.regex) {
    return currentRule.matches(
      new RegExp(validationRule.regex),
      validationRule.errorMessage
    );
  }
  return currentRule;
}

function isFileNotTooBig(maxSizeInMB) {
  const maxSizeInB = maxSizeInMB * 1024 * 1024;
  return file => file && file.size <= maxSizeInB;
}

function getValidationSchema(
  sections,
  { maxUploadFileSizeInMB },
  { fieldIsRequired, fileTooLargeError }
) {
  let validation = {};

  for (const section of sections) {
    for (const row of section.rows) {
      for (const { key, field, component, validationRule } of row) {
        if (!key) continue;
        if (component && component.type === ApplicationFileField) {
          let rule = Yup.mixed();
          if (component.props.required) {
            rule = rule.required(fieldIsRequired);
          }

          validation[component.props.name] = rule.test(
            'checkFileSize',
            fileTooLargeError,
            isFileNotTooBig(maxUploadFileSizeInMB)
          );
        } else if (field) {
          validation[field.name] = Yup.string();
          if (validationRule) {
            validation[field.name] = applyCustomValidationRule(
              validation[field.name],
              validationRule
            );
          }
          if (field.type === 'radio' || field.type === 'select') {
            validation[field.name] = validation[field.name].oneOf(
              field.options.map(o => o.value)
            );
          }
          if (field.required === true) {
            validation[field.name] =
              validation[field.name].required(fieldIsRequired);
          }
        }
      }
    }
  }

  return Yup.object().shape(validation);
}

export function useFormSteps(position, applicationFormSteps, config) {
  const labels = useApplicationFormLabels();
  const specialFields = useSpecialFields();
  
  const steps = useMemo(() => {
    const steps = [];
    for (const { label, sections } of applicationFormSteps) {
      const step = {
        label,
        sections: sections.map(section => ({
          label: section.label,
          rows: parseRows(section.rows, specialFields, position)
        }))
      };
      step.validationSchema = getValidationSchema(
        step.sections,
        config,
        labels
      );
      steps.push(step);
    }
    return steps;
  }, [position, applicationFormSteps, config, specialFields, labels]);

  return { totalSteps: steps.length, steps };
}