import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import { google } from 'calendar-link';

import { Modal } from 'components/_shared/Modal';
import { Section } from 'components/_shared/Section';
import { Divider } from 'components/_shared/Divider';
import { Button } from 'components/_shared/Button';

import { simulateLinkClick, trackEvent } from 'utils';

import './EventRSVPModal.styles.scss';

const query = graphql`
  query EventRSVPModalQuery {
    strapiVideospage {
      RSVP_thank_you_message
    }
  }
`;

export const EventRSVPModal = ({
  className,
  event = {},
  onSubmit,
  ...props
}) => {
  const {
    strapiVideospage: { RSVP_thank_you_message: message }
  } = useStaticQuery(query);

  const _className = cn('event-rsvp-modal', className);

  const handleAddToCalendarClick = () => {
    trackEvent('Event RSVP', event);

    const eventCalendarLink = google({
      title: event.event_name,
      description: event.event_descriptiion,
      start: new Date(event.event_date),
      allDay: true
    });

    simulateLinkClick(eventCalendarLink);
  };

  return (
    <Modal {...props} className={_className} classes={{ children: 'h-full' }}>
      <Section
        variant='aside'
        heading='Your registration has been confirmed'
        supheading='RSVP'
      >
        <h2 className='subsection-heading'>Event details</h2>

        <h4 className='event-heading'>{event.event_name}</h4>

        {event.event_date && (
          <time className='event-date' dateTime={event.event_date}>
            {event.event_date}
          </time>
        )}

        <p className='event-message'>{message}</p>

        <Divider variant='secondary' />

        <Button
          className='event-button-add-to-calendar'
          variant='primary'
          onClick={handleAddToCalendarClick}
        >
          Add to calendar →
        </Button>
      </Section>
    </Modal>
  );
};
