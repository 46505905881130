import React from 'react';
import { Link } from 'gatsby';

import { ROUTES } from '_constants';

import { IconLinkedIn, IconMedium, IconTwitter } from 'assets';

import { useStrapiSocialLinks, useStrapiCommonContent } from 'hooks';
import { parseMarkdown } from 'utils';

import './ConnectSection.styles.scss';

export const ConnectSection = () => {
  const socialLinks = useStrapiSocialLinks();
  const commonContent = useStrapiCommonContent();

  return (
    <div className='connect-section w-full px-3 md:px-6 mt-12 md:mt-24 lg:mt-32'>
      <section className='section-connect w-full max-w-devrev mx-auto'>
        <div className='action-card'>
          <h4 className='text-lg md:text-xl leading-tight whitespace-nowrap lg:text-2xl md:mb-4'>
            {commonContent.lets_connect}
          </h4>

          <div className='flex space-x-3 lg:mt-6'>
            <a
              className='w-8 outer-hairline rounded-full text-white bg-black lg:w-16 transform hover:-translate-y-1 transition-all duration-300'
              href={socialLinks.linkedin.url}
              title={socialLinks.linkedin.label}
              target='_blank'
              rel='noreferrer'
            >
              <IconLinkedIn />
            </a>
            <a
              className='w-8 outer-hairline rounded-full text-white bg-black lg:w-16 transform hover:-translate-y-1 transition-all duration-300'
              href={socialLinks.medium.url}
              title={socialLinks.medium.label}
              target='_blank'
              rel='noreferrer'
            >
              <IconMedium />
            </a>
            <a
              className='w-8 outer-hairline rounded-full text-white bg-black lg:w-16 transform hover:-translate-y-1 transition-all duration-300'
              href={socialLinks.twitter.url}
              title={socialLinks.twitter.label}
              target='_blank'
              rel='noreferrer'
            >
              <IconTwitter />
            </a>
          </div>
        </div>
        <Link className='action-card clickable' to={ROUTES.JOIN}>
          <h4 className='w-full text-lg md:text-xl leading-tight whitespace-nowrap lg:text-2xl'>
            {`${commonContent.join_us_heading} →`}
          </h4>

          <div className='text-lg leading-tight md:text-xl md:leading-tight mt-3 md:mt-6 hidden md:block'>
            {parseMarkdown(commonContent.join_us_subheading)}
          </div>
        </Link>
      </section>
    </div>
  );
};
