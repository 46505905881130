import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';

import { ROUTES } from '_constants';

import { Form, FormField, FormGroup } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';

import { useUserActions, selectUserCookie } from 'state';

import './CookiePanel.styles.scss';

const query = graphql`
  query CookiePanel {
    fields: strapiCommonContent {
      cookieMessage
      cookiesNecessary
      cookiesTargeting
      learnMore
      confirm
    }
  }
`;

export const CookiePanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isChecked } = useSelector(selectUserCookie);
  const { setCookiePreference } = useUserActions();

  const { fields } = useStaticQuery(query);

  const initialValues = {
    cookieTypes: [fields.cookiesNecessary, fields.cookiesTargeting]
  };

  const handleSubmit = values => {
    const isAllowed = values.cookieTypes.includes(fields.cookiesTargeting);
    setCookiePreference({ isAllowed, isChecked: true });
  };

  useEffect(() => setIsOpen(!isChecked), [isChecked]);

  if (!isOpen) return null;

  return (
    <div className='cookie-panel'>
      <div className='cookie-panel-message'>
        This site uses cookie to ensure that you get the best experience possible.&nbsp;
        <a
          href={ROUTES.TERMS_OF_SERVICE}
          target='_blank'
          rel='noreferrer'
          className='form-action hover:underline'
        >
          Learn more →
        </a>
      </div>

      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <FormGroup size='s'>
        <div className='flex flex-wrap items-center gap-smSpacing'>
          <FormField
            name='cookieTypes'
            type='checkboxGroup'
            size='s'
            options={[
              {
                label: fields.cookiesTargeting,
                value: fields.cookiesTargeting
              }
            ]}
          />
          <Button type='submit' className='form-action'>
            {fields.confirm}
          </Button>
          </div>
        </FormGroup>
      </Form>
    </div>
  );
};
