import React, { useRef } from 'react';
import cn from 'classnames';
import { navigate } from 'gatsby';

import { IconArrowRight } from 'assets';

import { useStrapiActions } from 'hooks';

import './BetaSignUpButton.styles.scss';

const isBrowser = () => typeof window !== 'undefined';

export const BetaSignUpButton = ({
  className,
  text,
  variant,
  color = 'blackShade-90',
  onClick
}) => {
  const _className = cn(
    'beta-sign-up-button',
    variant,
    `color-${color}`,
    className
  );
  const { getActionBySlug } = useStrapiActions();
  const betaSignupAction = getActionBySlug('open-beta-sign-up');
  const mobileSignupAction = getActionBySlug('mobile-sign-up');
  const action = useRef(betaSignupAction);

  if (isBrowser() && window.outerWidth < 720) {
    action.current = mobileSignupAction;
  } else {
    action.current = betaSignupAction;
  }

  const handleClick = () => {
    if (onClick) return onClick();
    navigate(action.current.link);
  };

  return (
    <button className={_className} onClick={handleClick}>
      <span className='beta-sign-up-button-text'>
        {text || action.current.text}
      </span>
      <span className='beta-sign-up-button-icon'>
        <IconArrowRight />
      </span>
    </button>
  );
};
