import { urlBuilder } from '../../utils';
import { axiosInstance } from '../instance';
import { eventsEndpoints } from './events.endpoints';

const addEvent = id => {
  return axiosInstance.put(urlBuilder(eventsEndpoints.register, { id }));
};

export const eventsRequests = {
  addEvent
};
