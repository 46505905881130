import { useMemo } from 'react';

export function useSpecialFields() {
  return useMemo(
    () => ({
      '-': {
        component: {
          type: 'div',
          props: {}
        }
      },
      location: {
        field: {
          label: 'Currently based in *',
          name: 'location',
          type: 'text',
          required: true
        }
      }
    }),
    []
  );
}
