import EventEmitter from 'events';

class LoaderEvents extends EventEmitter {
  toggleLoader = props => {
    this.emit('toggleLoader', props);
  };

  onToggleLoader = callback => {
    this.on('toggleLoader', callback);
    return () => this.removeListener('toggleLoader', callback);
  };
}

export const loaderEvents = new LoaderEvents();
