import React, { Children, cloneElement } from 'react';
import cn from 'classnames';

import { useFormContext } from '../Form.context';

import './FormGroup.styles.scss';

export const FormGroup = ({
  className,
  children,
  size,
  direction = 'col',
  variant: initialVariant
}) => {
  const formContext = useFormContext();
  const variant = initialVariant || formContext.variant;

  const _className = cn('form-group', variant, size, direction, className);

  return (
    <div className={_className}>
      {Children.map(children, child => {
        return child
          ? cloneElement(child, {
              ...child.props,
              className: cn('form-group-item', child.props.className, {
                'mt-12': variant === 'secondary'
              })
            })
          : null;
      })}
    </div>
  );
};
