import React, { useCallback, useMemo } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { ROUTES } from '_constants';
import { IconPlayButton, LogoIconColor } from 'assets';

import { Page } from 'components/_shared/Page';

import { useStrapiCommonContent } from 'hooks';
import { modalControllerEvents } from '_events';
import {
  getYouTubeEmbedVideoById,
  getYouTubeVideoIdByURL,
  parseMarkdown,
  sortByKey
} from 'utils';

import './HackathonSubmissionsPage.styles.scss';

export const HackathonSubmissionsPage = props => {
  const {
    page,
    allStrapiHack0GalleryItem: { submissions }
  } = props.data;

  const commonContent = useStrapiCommonContent();
  const _submissions = useMemo(() => {
    return submissions.map(submission => ({
      ...submission,
      hasVideo: !!submission.video_url,
      videoId: getYouTubeVideoIdByURL(submission.video_url)
    }));
  }, [submissions]);

  const handleVideoClick = useCallback(submission => {
    return modalControllerEvents.toggleModal('videoPlayer', {
      isOpen: true,
      video: (
        <iframe
          title={submission.title}
          src={getYouTubeEmbedVideoById(submission.videoId)}
          allowFullScreen
          frameBorder='0'
          webkitallowfullscreen='true'
          mozallowfullscreen='true'
        />
      )
    });
  }, []);

  const renderSubmissions = sortByKey(_submissions).map(submission => (
    <article key={submission.id} className='hackathon-submission'>
      <div className='submission-preview'>
        {submission.preview_image?.localFile ? (
          <div className='submission-preview image'>
            <GatsbyImage
              image={getImage(submission.preview_image.localFile)}
              alt={submission.title}
            />
          </div>
        ) : (
          <div className='default'>
            <LogoIconColor className='devrev-logo-icon' />
          </div>
        )}

        {submission.hasVideo && (
          <button
            className='submission-button-play'
            onClick={() => handleVideoClick(submission)}
          >
            <IconPlayButton />
          </button>
        )}
      </div>

      <div className='submission-content'>
        <h4 className='submission-title'>{submission.title}</h4>

        {submission.team_name && (
          <h5 className='submission-team'>{submission.team_name}</h5>
        )}

        {submission.short_description && (
          <p className='submission-description'>
            {submission.short_description}
          </p>
        )}

        {submission.github_url && (
          <a
            className='submission-link-github'
            href={submission.github_url}
            target='_blank'
            rel='noreferrer'
          >
            {`${commonContent.view_on_github} ${commonContent.arrow}`}
          </a>
        )}

        <Link
          to={ROUTES.HACKATHON_SUBMISSION_BY_TITLE(submission.title)}
          className='submission-link'
        />
      </div>
    </article>
  ));

  return (
    <Page
      className='hackathon-submissions'
      meta={{
        title: page.seo_title,
        description: page.seo_description,
        keywords: page.seo_keywords
      }}
    >
      <div className='header-spacer h-24' />

      <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 px-3 md:px-6 relative lg:pt-12 lg:flex-row'>
        <div className='lg:w-80 lg:mr-12 flex-shrink-0'>
          <div className='buggy-align text-3xl flex justify-center text-center md:text-left md:justify-start pb-6 md:text-4xl lg:text-5xl leading-none'>
            <h2 className='font-title'>{page.title}</h2>
          </div>

          <div className='buggy-align mx-auto w-5/6 md:w-full mb-12 lg:m-0 text-center md:text-left text-lg px-3 md:px-0 lg:max-width-none leading-tight'>
            {parseMarkdown(page.description)}
          </div>
        </div>

        <section className='hackathon-submissions-list'>
          {renderSubmissions}
        </section>
      </div>
    </Page>
  );
};
