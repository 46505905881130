import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import isNumber from 'lodash-es/isNumber';

import { loaderEvents } from '_events';

export const Loader = ({ isOpen = false, isFullPage = true }) => {
  const prevTimeoutRef = useRef(null);

  const [_isOpen, setIsOpen] = useState(isOpen);
  useEffect(() => setIsOpen(isOpen), [isOpen]);

  useEffect(() => {
    return loaderEvents.onToggleLoader(event => {
      prevTimeoutRef.current && clearTimeout(prevTimeoutRef.current);
      prevTimeoutRef.current = setTimeout(
        () => setIsOpen(event.isOpen),
        isNumber(event.timeout) ? event.timeout : 0
      );
    });
  }, []);

  return (
    <div
      className={cn(
        'w-full h-full flex items-center justify-center z-50 transform transition-all',
        {
          'visible opacity-100': _isOpen,
          'invisible opacity-0': !_isOpen,
          'fixed top-0 left-0 bg-white': isFullPage
        }
      )}
    >
      <svg
        className='full-page-loader'
        width='69'
        height='70'
        viewBox='0 0 69 70'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M68.3245 51.0674C68.1344 47.7896 67.0006 44.6367 65.0596 41.9885C63.1185 39.3403 60.4531 37.3098 57.3844 36.1416C54.3157 34.9733 50.9747 34.7172 47.7638 35.4041C44.5529 36.0909 41.6091 37.6915 39.287 40.0129C36.9649 42.3343 35.3637 45.2775 34.6762 48.488C33.9886 51.6985 34.2441 55.0393 35.4119 58.1079C36.5797 61.1765 38.6099 63.842 41.2579 65.7834C43.906 67.7248 47.059 68.8591 50.337 69.0498C50.464 69.0549 50.5906 69.0341 50.7093 68.9887C50.828 68.9432 50.9362 68.8741 51.0273 68.7856C51.1184 68.697 51.1905 68.5909 51.2393 68.4735C51.2881 68.3562 51.3125 68.2302 51.311 68.1032V52.048H67.3778C67.5053 52.0491 67.6317 52.0242 67.7493 51.9748C67.8669 51.9255 67.9732 51.8527 68.0618 51.761C68.1503 51.6692 68.2193 51.5604 68.2645 51.4412C68.3096 51.3219 68.3301 51.1947 68.3245 51.0674Z'
          fill='#f4f4f4'
        />
        <path
          d='M18.4179 67.7382L1.13191 38.3555C1.047 38.2126 1.0015 38.0498 1.00004 37.8836C0.998576 37.7174 1.04121 37.5538 1.1236 37.4095C1.20598 37.2652 1.32517 37.1453 1.46901 37.062C1.61285 36.9787 1.7762 36.9351 1.9424 36.9356L36.5144 36.9356C36.6805 36.9356 36.8436 36.9794 36.9873 37.0627C37.131 37.1459 37.2502 37.2657 37.3327 37.4097C37.4153 37.5538 37.4584 37.7171 37.4576 37.8832C37.4568 38.0493 37.4122 38.2122 37.3283 38.3555L20.0423 67.7382C19.9589 67.8794 19.8401 67.9964 19.6977 68.0777C19.5553 68.159 19.3941 68.2017 19.2301 68.2017C19.0661 68.2017 18.9049 68.159 18.7625 68.0777C18.6201 67.9964 18.5013 67.8794 18.4179 67.7382Z'
          fill='#f4f4f4'
        />
        <path
          d='M35.4724 17.0366C35.2817 13.759 34.1473 10.6065 32.2059 7.95878C30.2645 5.31105 27.5989 3.28105 24.5301 2.11333C21.4614 0.945599 18.1205 0.689948 14.9098 1.37717C11.6991 2.06439 8.75566 3.66517 6.43393 5.98668C4.1122 8.30819 2.51127 11.2514 1.82398 14.4618C1.1367 17.6722 1.39237 21.0128 2.56021 24.0812C3.72805 27.1496 5.75823 29.815 8.40621 31.7562C11.0542 33.6974 14.207 34.8317 17.4849 35.0224C17.6116 35.0266 17.7378 35.0051 17.8561 34.9593C17.9743 34.9136 18.0821 34.8444 18.173 34.7561C18.2639 34.6677 18.3361 34.5619 18.3852 34.445C18.4343 34.3282 18.4594 34.2026 18.4588 34.0758L18.4588 17.9968L34.5257 17.9968C34.6513 17.9973 34.7757 17.9727 34.8917 17.9244C35.0077 17.8762 35.1128 17.8053 35.201 17.7158C35.2892 17.6264 35.3586 17.5202 35.4052 17.4036C35.4518 17.2869 35.4747 17.1622 35.4724 17.0366Z'
          fill='#f4f4f4'
        />
        <path
          d='M48.8455 32.6733L31.5561 3.28712C31.4722 3.14383 31.4276 2.98091 31.4268 2.81485C31.426 2.64879 31.4691 2.48546 31.5517 2.34138C31.6342 2.1973 31.7534 2.07758 31.8971 1.99432C32.0408 1.91105 32.2039 1.8672 32.37 1.86719L66.942 1.86719C67.1072 1.86898 67.269 1.91413 67.4113 1.99812C67.5536 2.08212 67.6713 2.202 67.7527 2.34578C67.8341 2.48956 67.8763 2.65219 67.875 2.81739C67.8738 2.98259 67.8292 3.14457 67.7457 3.28712L50.4665 32.6733C50.3829 32.8137 50.2642 32.93 50.1221 33.0107C49.9801 33.0915 49.8194 33.1339 49.656 33.1339C49.4926 33.1339 49.3319 33.0915 49.1899 33.0107C49.0478 32.93 48.9291 32.8137 48.8455 32.6733V32.6733Z'
          fill='#f4f4f4'
        />
      </svg>
    </div>
  );
};
