import React, { useState } from 'react';
import cn from 'classnames';

import { Button } from '../Button';

import { parseMarkdown } from 'utils';

import './TextContent.styles.scss';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query TextContentQuery {
    labels: strapiCommonContent {
      read_more
      read_less
    }
  }
`;

export const TextContent = ({
  children,
  className,
  maxLength = 320,
  maxRows = 3,
  withCollapseButton = false,
  ...props
}) => {
  const { labels } = useStaticQuery(query);
  const isExceedsMaxLength =
    withCollapseButton &&
    typeof maxLength === 'number' &&
    [...children].length > maxLength;

  const [isCollapsed, setIsCollapsed] = useState(isExceedsMaxLength);

  const _className = cn(
    'text-content',
    { isCollapsed, isExceedsMaxLength },
    className
  );

  const renderCollapseAction = (
    <Button
      type='button'
      className='text-content-action text-content-action-collapse'
      onClick={() => setIsCollapsed(is => !is)}
    >
      {isCollapsed ? labels.read_more : labels.read_less}
    </Button>
  );

  return (
    <div {...props} className={_className}>
      {parseMarkdown(children, {
        wrapper: { style: `-webkit-line-clamp: ${maxRows}` }
      })}

      {withCollapseButton && isExceedsMaxLength && renderCollapseAction}
    </div>
  );
};
