import Cookie from 'js-cookie';

const cookieValue = Cookie.get('userCookie');


let cookie = cookieValue ? JSON.parse(cookieValue) : { isAllowed: false, isChecked: false }

export function getCookieLaw() {
  return cookie;
}

export function setCookieLaw(value) {
  Cookie.set('userCookie', JSON.stringify(value), {expires: 365});
  cookie = value;
  window.dispatchEvent(new Event('cookie-law-changed', { bubbles: true }));
}


