import React, { cloneElement, isValidElement } from 'react';
import cn from 'classnames';

import { Modal } from 'components/_shared/Modal';

import './VideoPlayerModal.styles.scss';

export const VideoPlayerModal = ({ className, video, ...props }) => {
  const _className = cn('video-player-modal', className);

  const renderVideo = () => {
    if (!video || !isValidElement(video)) return null;

    return cloneElement(video, {
      className: cn('video-player-video', video.props?.className)
    });
  };

  return (
    <Modal {...props} className={_className}>
      {renderVideo()}
    </Modal>
  );
};
