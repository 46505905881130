import React from 'react';

import { FormField } from 'components/_shared/Form';
import { useApplicationFormLabels } from '../hooks';

export function ApplicationFileField({ className, name, type, ...props }) {
  const { fileUploadSelectFile } = useApplicationFormLabels();

  return (
    <div className={className}>
      <FormField
        {...props}
        className='max-w-md'
        name={name}
        buttonLabel={fileUploadSelectFile}
        accept='.pdf, .doc, .docx, .txt, .rtf'
        type='file'
      />
    </div>
  );
}
