import { jobsRequests } from './jobs';
import { userRequests } from './user';
import { eventsRequests } from './events';
import { hackathonRequests } from './hackathon';

export const API = {
  user: userRequests,
  jobs: jobsRequests,
  events: eventsRequests,
  hackathon: hackathonRequests

};
