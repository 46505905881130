import React, { forwardRef } from 'react';
import cn from 'classnames';

import './Overlay.styles.scss';

export const Overlay = forwardRef(({ className, ...props }, ref) => {
  const _className = cn('overlay', className);

  return <div {...props} ref={ref} className={_className} />;
});
