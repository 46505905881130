import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import './InputTextarea.styles.scss';
import { mergeProps } from 'utils';

export const InputTextarea = ({
  className,
  variant,
  hasError = false,
  ...props
}) => {
  const _className = cn(
    'input input-textarea',
    variant,
    { hasError },
    className
  );
  const element = useRef(null);
  const [style, setStyle] = useState({});

  useLayoutEffect(() => {
    if (style.height !== '5px') return;
    setStyle({ height: element.current.scrollHeight + 'px' });
  }, [style]);

  return (
    <textarea
      ref={element}
      {...mergeProps(
        {
          rows: 1,
          onInput: () => setStyle({ height: '5px' })
        },
        props
      )}
      className={_className}
      style={style}
    />
  );
};
