import React from 'react';

import { Tooltip } from './_shared/Tooltip';

export const WaitlistTooltip = ({
  heading = 'You are on the waitlist 🤞',
  text = `You will be able to access these benefits once you are approved as a member of DevRev Circle.`,
  ...props
}) => {
  if (!heading && !text) return null;

  return (
    <Tooltip {...props}>
      {heading && <h6 className='text-base'>{heading}</h6>}
      {text && <p className='text-xl mt-3'>{text}</p>}
    </Tooltip>
  );
};
