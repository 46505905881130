import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Logo as LogoImage } from 'assets';

import { mergeProps } from 'utils';

import './Logo.styles.scss';

const query = graphql`
  query LogoQuery {
    strapiCommonContent {
      logo {
        localFile {
          svg {
            content
          }
        }
      }
    }
  }
`;

export const Logo = ({ className, ...props }) => {
  const {
    strapiCommonContent: { logo }
  } = useStaticQuery(query);

  const _props = mergeProps({ className: 'logo' }, [
    props,
    logo?.localFile
      ? { dangerouslySetInnerHTML: { __html: logo.localFile.svg.content } }
      : { children: <LogoImage className='default' /> }
  ]);

  return <span {..._props} />;
};
