import { urlBuilder } from '../../utils';
import { axiosInstance } from '../instance';
import { jobsEndpoints } from './jobs.endpoints';

const getUserApplications = config => {
  return axiosInstance.get(jobsEndpoints.userApplications, config);
};

const applyForJob = (id, body) => {
  return axiosInstance.post(
    urlBuilder(jobsEndpoints.applyForJob, { id }),
    body
  );
};

export const jobsRequests = {
  getUserApplications,
  applyForJob
};
