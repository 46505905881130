import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { sortByKey } from 'utils';

const query = graphql`
  query PLGPostsQuery {
    allStrapiPlgPost {
      posts: nodes {
        id
        title
        excerpt {
          data {
            excerpt
          }
        }
        url: link
        order
        date(formatString: "DD MMM YYYY")
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }
  }
`;

export const useStrapiPLGPosts = () => {
  const {
    allStrapiPlgPost: { posts }
  } = useStaticQuery(query);

  const sortedPosts = useMemo(() => {
    return sortByKey(posts, { equalKey: 'date' });
  }, [posts]);

  return { posts, sortedPosts };
};
