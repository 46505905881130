import React from 'react';
import { useSelector } from 'react-redux';

import { Loader } from 'components/Loader';
import { UserJoinModal } from 'components/User';

import { useAuthSetCurrentUser } from './useAuthSetCurrentUser';
import { selectUser } from 'state';

export const AuthUserController = ({ children, withJoinModal }) => {
  const { isLoading } = useAuthSetCurrentUser();
  const user = useSelector(selectUser);
  const isUserReady = !!user?.email;

  const showJoinModal =
    withJoinModal &&
    isUserReady &&
    (!user.devrev_linkedin || !user.firstname || !user.lastname);

  if (isLoading) {
    return (
      <div>
        <Loader isOpen />
      </div>
    );
  }

  return showJoinModal ? <UserJoinModal isOpen isPersisted /> : children;
};
