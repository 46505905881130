const slugify = require('slugify');

const { ACTION_SLUGS } = require('./actions');

const ROOT = '/';

const slugifyTitle = title => slugify(title, { lower: true });

const pathWithRoot = path => `${ROOT}${path}`;

const pathWithTitle = path => title => `${path}/${slugifyTitle(title)}`;

const BASE_APP = pathWithRoot('app');

const AUTH = pathWithRoot('auth');
const AUTH_GET_STARTED = `${AUTH}/get-started`;

const BLOG = pathWithRoot('blog');
const BLOG_BY_TITLE = pathWithTitle(BLOG);

const COMPANY = pathWithRoot('company');

const CONTACT_US = pathWithRoot('contact-us');

const DEV_REV_CIRCLE = pathWithRoot('circle');
const DEV_REV_CIRCLE_VIDEOS = `${DEV_REV_CIRCLE}/videos`;
const CIRCLE_SUCCESS = `${DEV_REV_CIRCLE}/success`;

const HACKATHON = pathWithRoot('hack0');
const HACKATHON_SUBMISSIONS = `${HACKATHON}/gallery`;
const HACKATHON_SUBMISSION_BY_TITLE = pathWithTitle(HACKATHON_SUBMISSIONS);

const JOIN = pathWithRoot('join-us');
const JOIN_POSITIONS = `${JOIN}#positions`;

const NEWS = pathWithRoot('news');
const NEWS_BY_TITLE = pathWithTitle(NEWS);

const PEOPLE = pathWithRoot('people');

const POSITIONS = pathWithRoot('positions');
const POSITION_APPLY_BY_ID = id => `${POSITION_BY_ID(id)}?apply=true`;
const POSITION_BY_ID = id => `${POSITIONS}/${id || ':id'}`;

const PRIVACY_POLICY = pathWithRoot('privacy-policy');
const TERMS_OF_SERVICE = pathWithRoot('terms-of-service');

const USER_DEVREV_ACCOUNT = isLink => {
  return `${isLink ? BASE_APP : ''}/devrev-account`;
};

const BETA = pathWithRoot('beta');
const BETA_FORM_APPLY = `${BETA}/apply`;
const BETA_SUCCESS = `${BETA}/success`;

const MARKETPLACE = `${ROOT}marketplace`;
const MARKETPLACE_PRODUCT_BY_TITLE = pathWithTitle(MARKETPLACE);

const DOCS = `${ROOT}docs`;
const DOCS_APIS = `${DOCS}/apis`;
const DOCS_API_METHODS = `${DOCS_APIS}/methods`;
const DOCS_API_METHOD_BY_PATH = path => `${DOCS_API_METHODS}${path}`;
const DOCS_API_METHOD_EXTERNAL_BY_PATH = path => `https://api.devrev.ai${path}`;

const MULTI_ORG_SELECT = "https://app.devrev.ai/multi-org-select";

const ROUTES = {
  ROOT,
  BASE_APP,
  BETA,
  BETA_FORM_APPLY,
  BETA_SUCCESS,
  AUTH_GET_STARTED,
  BLOG,
  BLOG_BY_TITLE,
  COMPANY,
  CONTACT_US,
  DEV_REV_CIRCLE,
  DEV_REV_CIRCLE_VIDEOS,
  CIRCLE_SUCCESS,
  HACKATHON,
  HACKATHON_SUBMISSIONS,
  HACKATHON_SUBMISSION_BY_TITLE,
  JOIN,
  JOIN_POSITIONS,
  NEWS,
  NEWS_BY_TITLE,
  PEOPLE,
  POSITIONS,
  POSITION_APPLY_BY_ID,
  POSITION_BY_ID,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  USER_DEVREV_ACCOUNT,
  DOCS,
  DOCS_APIS,
  DOCS_API_METHODS,
  DOCS_API_METHOD_BY_PATH,
  DOCS_API_METHOD_EXTERNAL_BY_PATH,
  MARKETPLACE,
  MARKETPLACE_PRODUCT_BY_TITLE,
  MULTI_ORG_SELECT
};

const ACTION_ROUTES_BY_SLUG = {
  [ACTION_SLUGS.CONTACT_US_LINK]: ROUTES.CONTACT_US,
  [ACTION_SLUGS.EXPLORE_CIRCLE_VIDEOS]: ROUTES.DEV_REV_CIRCLE_VIDEOS,
  [ACTION_SLUGS.EXPLORE_PRODUCT_LED_RESOURCES]: ROUTES.BLOG,
  [ACTION_SLUGS.PRIVACY_POLICY_LINK]: ROUTES.PRIVACY_POLICY,
  [ACTION_SLUGS.TERMS_OF_SERVICE_LINK]: ROUTES.TERMS_OF_SERVICE,
  [ACTION_SLUGS.MY_ACCOUNT_LINK]: ROUTES.USER_DEVREV_ACCOUNT(true),
  [ACTION_SLUGS.CIRCLE_MY_ACCOUNT_LINK]: ROUTES.USER_DEVREV_ACCOUNT(true)
};

module.exports.ROUTES = ROUTES;
module.exports.ACTION_ROUTES_BY_SLUG = ACTION_ROUTES_BY_SLUG;

module.exports.slugifyTitle = slugifyTitle;
