import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from 'react';
import cn from 'classnames';
import isUndefined from 'lodash-es/isUndefined';

import { UseClickOutsideConditional } from 'hooks';

import './Tooltip.styles.scss';

export const Tooltip = forwardRef(
  (
    {
      className,
      triggerRefs = [],
      triggerClassNames = [],
      isOpen = false,
      ...props
    },
    ref
  ) => {
    const rootRef = useRef(null);

    const [_isOpen, setIsOpen] = useState(isOpen);
    const [coordinates, setCoordinates] = useState();

    const _className = cn('tooltip', className);

    useImperativeHandle(ref, () => ({ element: rootRef.current }), []);

    useEffect(() => {
      !isUndefined(isOpen) && setIsOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
      const handleMaouseEnter = element => {
        setIsOpen(isOpen => {
          if (isOpen) return isOpen;

          const rect = element.getBoundingClientRect();

          let coordinates = {
            top: rect.top + window.scrollY + element.offsetHeight + 16,
            left: rect.left + window.scrollX
          };

          setCoordinates(coordinates);

          return true;
        });
      };

      const handleMaouseLeave = event => {
        if (
          rootRef.current?.contains(event.relatedTarget) ||
          rootRef.current === event.relatedTarget
        ) {
          return;
        }

        setIsOpen(false);
      };

      const elements = [
        ...triggerRefs.map(ref => ref.current || undefined),
        ...triggerClassNames.reduce((elements, className) => {
          return [
            ...elements,
            ...Array.from(document.querySelectorAll(className))
          ];
        }, [])
      ].filter(Boolean);

      if (!elements.length) return;

      elements.forEach(element => {
        element.addEventListener(
          'mouseenter',
          () => handleMaouseEnter(element),
          false
        );
        element.addEventListener(
          'mouseleave',
          event => handleMaouseLeave(event),
          false
        );
      });

      return () => {
        elements.forEach(element => {
          element.removeEventListener('mouseenter', () => handleMaouseEnter());
          element.removeEventListener('mouseleave ', () => handleMaouseLeave());
        });
      };
    }, [triggerRefs, triggerClassNames]);

    useEffect(() => {
      if (!_isOpen || !rootRef.current) return;

      const rect = rootRef.current.getBoundingClientRect();

      if (rect.bottom > window.innerHeight) {
        const topOffset = rect.bottom - window.innerHeight + 24;
        setCoordinates(coordinates => ({
          ...coordinates,
          top: coordinates.top - topOffset
        }));
      }
    }, [_isOpen]);

    if (!_isOpen) return null;

    return (
      <>
        <UseClickOutsideConditional
          elementRef={rootRef}
          callback={() => setIsOpen(false)}
        />

        <span
          {...props}
          ref={rootRef}
          className={_className}
          style={coordinates}
          role='button'
          tabIndex='0'
          onMouseLeave={() => setIsOpen(false)}
        />
      </>
    );
  }
);
