import React, {
  forwardRef,
  useEffect,
  useState,
  useRef,
  useImperativeHandle
} from 'react';
import isUndefined from 'lodash-es/isUndefined';
import cn from 'classnames';

import { UseClickOutsideConditional } from 'hooks';

import './Dropdown.styles.scss';

export const Dropdown = forwardRef(
  (
    {
      className,
      isOpen = false,
      closeOnOutsideClick = true,
      clickOutsideParams,
      onClose,
      onOutsideClick,
      ...props
    },
    ref
  ) => {
    const rootRef = useRef(null);
    const [_isOpen, setIsOpen] = useState(isOpen);

    const _className = cn('dropdown inner-hairline', className);

    useEffect(() => !isUndefined(isOpen) && setIsOpen(isOpen), [isOpen]);

    useImperativeHandle(ref, () => ({ element: rootRef.current, setIsOpen }));

    const handleClose = () => {
      setIsOpen(false);
      onClose?.();
    };

    const handleOutsideClick = () => {
      closeOnOutsideClick && handleClose();
      onOutsideClick?.();
    };

    if (!_isOpen) return null;

    return (
      <>
        <UseClickOutsideConditional
          elementRef={rootRef}
          callback={handleOutsideClick}
          params={clickOutsideParams}
        />

        <span ref={rootRef} {...props} className={_className} />
      </>
    );
  }
);
