import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';

import { selectUser } from 'state/user';
import { useUserAccess } from 'state';

const query = graphql`
  query RightsQuery {
    rights {
      content
    }
  }
`;

export function useRights() {
  const data = useStaticQuery(query);
  const user = useSelector(selectUser);
  const { isAuthenticated } = useUserAccess();

  const initialRights = useMemo(
    () => JSON.parse(data.rights.content),
    [data.rights.content]
  );

  const rights = useMemo(() => {
    const sources = { user, isAuthenticated };

    const processRules = rules => {
      if (typeof rules === 'object') {
        return Object.entries(rules).some(([key, value]) => {
          const [source, _key] = key.split(/_(.*)/);
          const sourceValue = _key ? sources[source][_key] : sources[source];

          if (Array.isArray(value)) {
            return value.includes(sourceValue);
          }

          return sourceValue === value;
        });
      }

      return false;
    };

    return Object.entries(initialRights).reduce((rights, [key, rules]) => {
      let isAllowed = !!rules;

      if (Array.isArray(rules)) {
        isAllowed = rules.every(processRules);
      } else {
        isAllowed = processRules(rules);
      }

      return { ...rights, [key]: isAllowed };
    }, {});
  }, [initialRights, user, isAuthenticated]);

  const getRights = useCallback(
    (keys = []) => {
      const _keys = Array.isArray(keys) ? keys : [keys];

      return _keys.reduce((_rights, key) => {
        return { ..._rights, [key]: key in rights ? rights[key] : true };
      }, {});
    },
    [rights]
  );

  const rightHasRule = useCallback(
    (rightKey, ruleKey) => {
      const right = initialRights[rightKey];

      if (!right) return false;

      const hasRule = right => Object.keys(right).includes(ruleKey);

      if (Array.isArray(right)) return right.some(hasRule);

      return hasRule(right);
    },
    [initialRights]
  );

  return { initialRights, rights, getRights, rightHasRule };
}
