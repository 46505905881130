import { useCallback, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';

import { ACTION_SLUGS, BETA_STATUS, CIRCLE_RESOURCE_SLUGS } from '_constants';

import { useStrapiActions } from './useStrapiActions';
import { useRights } from './useRights';
import { selectUserBetaStatus } from 'state/user';
import { mergeProps, sortByKey } from 'utils';

const query = graphql`
  query CircleResourcesQuery {
    allStrapiCircleResource {
      resources: nodes {
        id
        title
        content {
          data {
            content
          }
        }
        order
        slug
        _action: action {
          actions {
            id
          }
        }
      }
    }
  }
`;

const rightKeyBySlugs = {
  [CIRCLE_RESOURCE_SLUGS.DOCS]: 'docs',
  [CIRCLE_RESOURCE_SLUGS.DEV_REV_BETA_ACCESS]: 'circle_resource_beta',
  [CIRCLE_RESOURCE_SLUGS.MEET_US_ON_DISCORD]: 'circle_resource_discord',
  [CIRCLE_RESOURCE_SLUGS.PRODUCT_LED_RESOURCES]: 'circle_resource_plr',
  [CIRCLE_RESOURCE_SLUGS.VIDEO_GALLERY]: 'circle_resource_video_gallery'
};

export function useStrapiCircleResources({ filterByRights = true } = {}) {
  const {
    allStrapiCircleResource: { resources }
  } = useStaticQuery(query);

  const { getRights } = useRights();
  const rights = getRights(Object.values(rightKeyBySlugs));
  const userBetaStatus = useSelector(selectUserBetaStatus);
  const { getActionById } = useStrapiActions();

  const actionPropsBySlug = useMemo(
    () => ({
      [ACTION_SLUGS.BETA_APPLY]: {
        disabled: userBetaStatus !== BETA_STATUS.NOT_APPLIED
      }
    }),
    [userBetaStatus]
  );

  const _resources = useMemo(() => {
    return sortByKey(
      filterByRights
        ? resources.filter(({ slug }) => rights[rightKeyBySlugs[slug]])
        : resources
    ).map(resource => {
      const _resource = {
        ...resource,
        rights: rights[rightKeyBySlugs[resource.slug]],
        action: getActionById(resource._action?.actions?.id)
      };

      if (_resource.action) {
        const { action } = _resource;
        _resource.action = mergeProps(action, [actionPropsBySlug[action.slug]]);
      }

      return _resource;
    });
  }, [resources, rights, actionPropsBySlug, filterByRights, getActionById]);

  const getResourceBySlug = useCallback(
    slug => {
      if (Array.isArray(slug)) {
        return slug.map(slug => {
          return _resources.find(resource => resource.slug === slug);
        });
      }

      return _resources.find(resource => resource.slug === slug);
    },
    [_resources]
  );

  return { resources: _resources, getResourceBySlug };
}
