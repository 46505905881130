import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import upperFirst from 'lodash-es/upperFirst';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { JobStatus } from 'components/_shared/JobStatus/JobStatus';

import { selectUser } from 'state';
import { useStrapiCommonContent } from 'hooks';

import './UserApplications.styles.scss';

export const UserApplications = ({ className }) => {
  const user = useSelector(selectUser);
  const commonContent = useStrapiCommonContent();

  const profilePercentage = user.user_profile_percentage || 0;

  const _className = cn('user-devrev-applications', className);

  const renderProfileProgress = () => {
    return (
      <div className='flex items-start md:items-center inner-hairline p-4 md:p-6 lg:w-1/2'>
        <div className='profile-progress-percent h-16 w-16 mr-3 md:mr-6 flex-shrink-0'>
          <CircularProgressbar
            value={profilePercentage}
            text={`${profilePercentage}%`}
          />
        </div>
        <div>
          <p className='text-base leading-tight'>
            {commonContent.applicationstatustip}
          </p>
        </div>
      </div>
    );
  };

  const renderApplications = () => {
    return (
      <div className='applications space-y-12'>
        {user.applications.map(application => {
          return (
            <div
              className='pt-4 md:pt-6 top-hairline mb-6'
              key={application.key}
            >
              <header className=''>
                <h4 className='text-lg md:text-xl lg:text-2xl leading-tight'>
                  {application.job_title}
                </h4>
                <p className='text-lg md:text-xl lg:text-2xl leading-tight opacity-50'>
                  {upperFirst(application.job_location)}
                </p>
              </header>
              <p className='text-lg md:text-xl lg:text-2xl leading-tight'>
                {`${commonContent.jobapplicationstatus}: `}
                <JobStatus status={application.status}></JobStatus>
              </p>

              <p className='text-lg md:text-xl lg:text-2xl leading-tight'>
                {`We have shared the details about the next steps on your email address.`}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={_className}>
      {renderApplications()}

      {renderProfileProgress()}
    </div>
  );
};
