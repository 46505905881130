import EventEmitter from 'events';

class ModalControllerEvents extends EventEmitter {
  toggleModal = (modalName, props) => {
    this.emit('toggleModal', { modalName, props });
  };

  onToggleModal = callback => {
    this.on('toggleModal', callback);
    return () => this.removeListener('toggleModal', callback);
  };
}

export const modalControllerEvents = new ModalControllerEvents();
