import React from 'react';

export const ModalDevRevAnimation = () => {
  return (
    <svg width="1114" height="1114" viewBox="0 0 1114 1114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M591.664 577.067C591.473 573.79 590.34 570.637 588.399 567.989C586.458 565.34 583.792 563.31 580.723 562.142C577.655 560.973 574.314 560.717 571.103 561.404C567.892 562.091 564.948 563.691 562.626 566.013C560.304 568.334 558.703 571.278 558.015 574.488C557.328 577.698 557.583 581.039 558.751 584.108C559.919 587.177 561.949 589.842 564.597 591.783C567.245 593.725 570.398 594.859 573.676 595.05C573.803 595.055 573.93 595.034 574.048 594.989C574.167 594.943 574.275 594.874 574.366 594.786C574.457 594.697 574.53 594.591 574.578 594.474C574.627 594.356 574.651 594.23 574.65 594.103V578.048H590.717C590.844 578.049 590.971 578.024 591.088 577.975C591.206 577.925 591.312 577.853 591.401 577.761C591.489 577.669 591.558 577.56 591.603 577.441C591.649 577.322 591.669 577.195 591.664 577.067Z" className='solid-line'/>
      <path d="M541.757 593.738L524.471 564.355C524.386 564.213 524.34 564.05 524.339 563.884C524.338 563.717 524.38 563.554 524.463 563.41C524.545 563.265 524.664 563.145 524.808 563.062C524.952 562.979 525.115 562.935 525.281 562.936H559.853C560.019 562.936 560.183 562.979 560.326 563.063C560.47 563.146 560.589 563.266 560.672 563.41C560.754 563.554 560.797 563.717 560.797 563.883C560.796 564.049 560.751 564.212 560.667 564.355L543.381 593.738C543.298 593.879 543.179 593.996 543.037 594.078C542.894 594.159 542.733 594.202 542.569 594.202C542.405 594.202 542.244 594.159 542.102 594.078C541.959 593.996 541.84 593.879 541.757 593.738Z" className='solid-line'/>
      <path d="M558.811 543.037C558.621 539.759 557.486 536.607 555.545 533.959C553.603 531.311 550.938 529.281 547.869 528.113C544.8 526.946 541.46 526.69 538.249 527.377C535.038 528.064 532.095 529.665 529.773 531.987C527.451 534.308 525.85 537.251 525.163 540.462C524.476 543.672 524.731 547.013 525.899 550.081C527.067 553.15 529.097 555.815 531.745 557.756C534.393 559.697 537.546 560.832 540.824 561.022C540.951 561.027 541.077 561.005 541.195 560.959C541.313 560.914 541.421 560.844 541.512 560.756C541.603 560.668 541.675 560.562 541.724 560.445C541.773 560.328 541.798 560.203 541.798 560.076V543.997H557.865C557.99 543.997 558.115 543.973 558.231 543.924C558.347 543.876 558.452 543.805 558.54 543.716C558.628 543.626 558.698 543.52 558.744 543.404C558.791 543.287 558.814 543.162 558.811 543.037Z" className='solid-line'/>
      <path d="M572.184 558.673L554.895 529.287C554.811 529.144 554.767 528.981 554.766 528.815C554.765 528.649 554.808 528.485 554.891 528.341C554.973 528.197 555.092 528.078 555.236 527.994C555.38 527.911 555.543 527.867 555.709 527.867H590.281C590.446 527.869 590.608 527.914 590.75 527.998C590.893 528.082 591.01 528.202 591.092 528.346C591.173 528.49 591.215 528.652 591.214 528.817C591.213 528.983 591.168 529.145 591.085 529.287L573.805 558.673C573.722 558.814 573.603 558.93 573.461 559.011C573.319 559.091 573.158 559.134 572.995 559.134C572.832 559.134 572.671 559.091 572.529 559.011C572.387 558.93 572.268 558.814 572.184 558.673V558.673Z" className='solid-line'/>
      <circle cx="557" cy="557" r="90" className='dashed-line reversed'/>
      <circle cx="557" cy="557" r="157" className='solid-line'/>
      <circle cx="557" cy="557" r="328" className='dotted-line'/>
      <circle cx="557" cy="557.001" r="424" className='dotted-line reversed'/>
      <circle cx="557" cy="557" r="272" className='dashed-line'/>
      <circle cx="557" cy="557" r="556" className='dotted-line'/>
    </svg>
  );
};
