import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

import './BackLink.styles.scss';

export const BackLink = ({ className, href, text }) => {
  const _className = cn('back-link', className);

  return (
    <Link to={href} className={_className}>
      <span className='back-link-arrow'>←</span>
      <span className='back-link-text'>{text}</span>
    </Link>
  );
};
