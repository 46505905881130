import { configureStore } from '@reduxjs/toolkit';

import { userReducer } from './user';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    user: userReducer
  }
});
