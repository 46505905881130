import React from 'react';
import cn from 'classnames';

import './InputText.styles.scss';

export const InputText = ({
  className,
  type = 'text',
  variant,
  hasError = false,
  ...props
}) => {
  const _className = cn('input-text', variant, className, {
    hasError,
    [`
      disabled:text-gray-300
      disabled:cursor-not-allowed
      peer
      placeholder-transparent
      focus:ring-0
      block
      w-full
      text-lg
      md:text-xl
      lg:text-2xl
      border-0
      devrev-input
      px-0
      pt-0
      pb-0
      lg:pb-2 outline-none focus:outline-none`]: variant === 'secondary'
  });

  return (
    <input
      {...props}
      type={type}
      className={_className}
      placeholder={variant === 'secondary' ? 'placeholder' : props.placeholder}
    />
  );
};
