export const CIRCLE_STATUS = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  APPLIED: 'applied',
  NOT_APPLIED: 'not_applied',
  APPLIED_AND_NOT_VERIFIED: 'applied_and_not_verified',
  OUTER_CIRCLE: 'outer_circle'
};

export const CIRCLE_RESOURCE_SLUGS = {
  DEV_REV_BETA_ACCESS: 'dev-rev-beta-access',
  MEET_US_ON_DISCORD: 'meet-us-on-discord',
  PRODUCT_LED_RESOURCES: 'product-led-resources',
  VIDEO_GALLERY: 'video-gallery',
  DOCS: 'docs'
};
