import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import unescape from 'lodash-es/unescape';

import { ROUTES } from '_constants';
import { trackEvent } from 'utils';

import { Page } from 'components/_shared/Page';
import { SocialShare } from 'components/_shared/Social';
import { BackLink } from 'components/_shared/BackLink';
import { Button } from 'components/_shared/Button';
import { modalControllerEvents } from '_events';

import { useUserApplications, useUserActions, useUserAccess } from 'state';

import './PositionPost.styles.scss';

const query = graphql`
  query PositionPostQuery {
    strapiCommonContent {
      back_to_job_board
      apply_now
      already_applied_notice
      check_application_status_button
    }
  }
`;

export const PositionPost = ({
  pageContext: { position, applicationForm }
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { strapiCommonContent } = useStaticQuery(query);
  const { isLoaded: isApplicationsLoaded } = useUserApplications();
  const { isAuthenticated } = useUserAccess();
  const { hasApplied, getUserApplications, login } = useUserActions();

  useEffect(() => {
    if (!isAuthenticated || isApplicationsLoaded) return;
    getUserApplications();
  }, [isAuthenticated, isApplicationsLoaded, getUserApplications]);

  useEffect(() => {
    if (isFormOpen) {
      modalControllerEvents.toggleModal('applicationFormModal', {
        position,
        applicationForm,
        isOpen: true,
        onClose: () => {
          setIsFormOpen(false);
        }
      });
    }
  }, [isFormOpen, position, applicationForm]);

  // Need this as modals are not yet subscribed to modalControllerEvents at first render
  useEffect(() => {
    if (isAuthenticated) {
      setIsFormOpen(window.location.search.includes('apply=true'));
    }
  }, [isAuthenticated]);

  return (
    <Page className='page-position' meta={{ title: position.title }}>
      <div className='header-spacer h-24' />
      <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 px-3 md:px-6 relative lg:pt-26 lg:flex-row'>
        <div className='w-full md:w-full lg:w-80 lg:mr-12 flex-shrink-0 relative'>
          <BackLink
            className='absolute -top-14'
            href={ROUTES.JOIN_POSITIONS}
            text={strapiCommonContent.back_to_job_board}
          />
          <div className='text-3xl flex justify-center text-center md:text-left buggy-align md:justify-start pb-6 md:text-4xl lg:text-5xl leading-none'>
            <h2 className='font-title w-5/6 md:w-2/3 lg:w-auto'>
              {position.title}
            </h2>
          </div>
          <div className='buggy-align mx-auto w-5/6 md:w-full mb-12 lg:m-0 text-center md:text-left text-lg md:text-xl px-3 md:px-0 lg:text-2xl lg:max-width-none leading-tight'>
            {position.location.name}
          </div>
          <div className='flex justify-center md:justify-start items-center gap-3 mx-auto w-5/6 md:w-full mb-12 lg:mt-6 text-center md:text-left text-lg md:text-xl px-3 md:px-0 lg:text-2xl lg:max-width-none'>
            <Button
              className='inline-block'
              variant='primary'
              onClick={() => {
                trackEvent(`Job - Viewed Post`, { position });
                if (!isAuthenticated) {
                  login(ROUTES.POSITION_APPLY_BY_ID(position.initialId));
                } else {
                  setIsFormOpen(true);
                }
              }}
            >
              {strapiCommonContent.apply_now}
            </Button>
            <SocialShare size='h-9 w-9 md:h-10 md:w-10 lg:h-11 lg:w-11' />
          </div>
        </div>

        <div>
          {hasApplied(position.initialId) && (
            <div className='flex flex-col items-start inner-hairline p-4 md:p-6 lg:p-9 mb-12'>
              <div className='text-lg text-gray-400'>
                {strapiCommonContent.already_applied_notice}
              </div>
              <Button
                type='button'
                className='mt-6 hover:underline'
                onClick={() => navigate(ROUTES.USER_DEVREV_ACCOUNT(true))}
              >
                {strapiCommonContent.check_application_status_button}
              </Button>
            </div>
          )}

          <article
            className='position text-2xl leading-7'
            dangerouslySetInnerHTML={{ __html: unescape(position.content) }}
          ></article>
        </div>
      </div>
    </Page>
  );
};
