import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import axios from 'axios';

import { API, axiosInstance } from 'api';

import { useUserActions } from 'state';
import { trackEvent } from 'utils';

function hasAuth0Cookie() {
  return Object.entries(Cookies.get() || {}).some(([key, value]) => {
    return /auth0\..*\.authenticated/.test(key) ? value : false;
  });
}

export function useAuthSetCurrentUser() {
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(() => hasAuth0Cookie());
  const { setCurrentUser } = useUserActions();

  useEffect(() => {
    if (!isAuthenticated) return;

    const authInterceptorId = axiosInstance.interceptors.request.use(
      async request => {
        const token = await getAccessTokenSilently();
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${token}`
        };
        return request;
      }
    );

    const authErrorInterceptorId = axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            logout({ localOnly: true });
          }
        }
        throw error;
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(authInterceptorId);
      axiosInstance.interceptors.response.eject(authErrorInterceptorId);
    };
  }, [isAuthenticated, getAccessTokenSilently, logout]);

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      setIsLoading(true);

      try {
        trackEvent('User - Login');

        const [currentUser, sessionToken] = await Promise.all([
          API.user.getCurrentUser({ parseResponseMessageOnError: false }),
          API.user.getSessionToken()
        ]);

        

        setCurrentUser({
          ...currentUser.data,
          firstname:
            currentUser.data.firstname || user?.given_name || user?.name,
          lastname: currentUser.data.lastname || user?.family_name,
          session_token: sessionToken.data.access_token
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          setCurrentUser({
            email: user?.email,
            firstname: user?.given_name || user?.name,
            lastname: user?.family_name,
            devrev_linkedin: '',
            devrev_github: '',
            devrev_website: ''
          });
        } else {
          logout({ localOnly: true });
          if (!axios.isAxiosError(error) || error.response?.status !== 403) {
            throw error;
          }
        }
      }

      setIsLoading(false);
    })();
  }, [isAuthenticated, user, setCurrentUser, logout]);

  return { isLoading };
}
