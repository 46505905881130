import React, { useState, useMemo } from 'react';
import { Link, navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import slugify from 'slugify';
import cn from 'classnames';

import { ROUTES } from '_constants';

import { Page } from 'components/_shared/Page';
import { Section } from 'components/_shared/Section';
import { Tabs } from 'components/_shared/Tabs';
import { ListPerks } from 'components/_shared/List';
import { HackathonSubmissions } from '../Submissions';
import { HackathonRegistrationCTA } from '../Registration';
import { WaitlistTooltip } from 'components/WaitlistTooltip';
import { HackathonSubmissionsMessage } from '../Submissions/Message';

import { selectUserIsHackathonRegistered } from 'state';
import { useUserAccess } from 'state/hooks';
import { parseMarkdown, sortByKey } from 'utils';

import './HackathonPage.styles.scss';

export const HackathonPage = ({ className, ...props }) => {
  const {
    strapiHackathonSignupPage: page,
    strapiHackathonRule: rules,
    strapiHack0AnnouncementsPage: announcementsPage,
    strapiHack0ResourcesPage: resourcesPage,
    allStrapiHack0Announcement: { nodes: announcements },
    allStrapiHack0Resource: { nodes: resources },
    strapiHack0GalleryPage,
    strapiCommonContent
  } = props.data;

  const TAB_KEYS = {
    Overview: 'Overview',
    Resources: 'Resources',
    Announcements: 'Announcements',
    Submissions: 'Submissions',
    Rules: 'Rules'
  };

  const META = {
    [TAB_KEYS.Overview]: {
      title: page.page_seo_description,
      description: page.page_seo_description
    },
    [TAB_KEYS.Resources]: {
      title: resourcesPage.page_seo_description,
      description: resourcesPage.page_seo_description
    },
    [TAB_KEYS.Announcements]: {
      title: announcementsPage.page_seo_title,
      description: announcementsPage.page_seo_description
    },
    [TAB_KEYS.Submissions]: {
      title: page.page_seo_description,
      description: page.page_seo_description
    },
    [TAB_KEYS.Rules]: {
      title: rules.page_seo_title,
      description: rules.page_seo_description
    }
  };

  const { isAuthenticated } = useUserAccess();
  const isUserRegistered = useSelector(selectUserIsHackathonRegistered);

  const _className = cn('hackathon', className);

  const [activeTabKey, setActiveTabKey] = useState(META[TAB_KEYS.Overview]);

  const announcementsItems = useMemo(() => {
    return sortByKey(announcements)
      .map(announcement => ({
        content: `${announcement.title}<br>${announcement.body.data.body}`,
        meta: announcement.date
      }))
      .reverse();
  }, [announcements]);

  const resourcesItems = useMemo(() => {
    return sortByKey(resources)
      .map(resource => ({
        heading: resource.title,
        content: resource.content.data.content
      }))
      .reverse();
  }, [resources]);

  const tabs = {
    [TAB_KEYS.Overview]: (
      <ListPerks
        key={TAB_KEYS.Overview}
        variant='primary'
        items={[
          { heading: page.blade1_title, content: page.blade1_body.data.blade1_body },
          { heading: page.blade2_title, content: page.blade2_body.data.blade2_body },
          { heading: page.blade3_title, content: page.blade3_body.data.blade3_body },
          { heading: page.blade4_title, content: page.blade4_body.data.blade4_body },
          { heading: page.blade5_title, content: page.blade5_body.data.blade5_body },
          { heading: page.blade6_title, content: page.blade6_body.data.blade6_body },
          { heading: page.blade7_title, content: page.blade7_body.data.blade7_body }
        ]}
      />
    ),
    [TAB_KEYS.Resources]: (
      <div>
        {!isAuthenticated && isUserRegistered && (
          <HackathonSubmissionsMessage
            message={parseMarkdown(page.thank_you_for_applying.data.thank_you_for_applying)}
          />
        )}
        <ListPerks
          key={TAB_KEYS.Resources}
          variant='primary'
          items={resourcesItems}
        />
      </div>
    ),
    [TAB_KEYS.Announcements]: (
      <ListPerks
        key={TAB_KEYS.Announcements}
        variant='primary'
        contentMaxLength={160}
        contentMaxRows={4}
        items={announcementsItems}
      />
    ),
    [TAB_KEYS.Submissions]: <HackathonSubmissions hackathonId='0' />,
    [TAB_KEYS.Rules]: (
      <ListPerks
        key={TAB_KEYS.Rules}
        variant='primary'
        items={[{ content: rules.page_body.data.page_body }]}
      />
    )
  };

  const tabsMenuChildren = strapiHack0GalleryPage.enabled && (
    <Link to={ROUTES.HACKATHON_SUBMISSIONS}>
      {strapiCommonContent.hack0_submissions}
    </Link>
  );

  return (
    <Page meta={META[activeTabKey]} className={_className} withContainer>
      <Section
        heading={parseMarkdown(page.page_title, { wrapper: false })}
        subheading={page.page_sub_title}
        className='hero'
      >
        <HackathonRegistrationCTA
          text={
            isUserRegistered
              ? page.already_registered
              : `${page.register_cta}`
          }
          deadline={page.deadline}
          disabled={true}
          onSubmit={() =>
            navigate(
              `${window.location.pathname}?tab=${slugify(
                TAB_KEYS.Resources
              ).toLowerCase()}`,
              {
                replace: true
              }
            )
          }
        />
      </Section>

      <Section>
        {!isUserRegistered && (
          <WaitlistTooltip
            heading={page.tooltip_heading}
            text={page.tooltip_content}
            triggerClassNames={['.tabs-menu-button.isDisabled']}
          />
        )}

        <Tabs
          tabs={tabs}
          activeTabLabel={label => `${label} →`}
          disabledTabKeys={
            !isUserRegistered && [
              TAB_KEYS.Resources,
              TAB_KEYS.Announcements,
              TAB_KEYS.Submissions
            ]
          }
          onChange={setActiveTabKey}
          tabsMenuChildren={tabsMenuChildren}
        />
      </Section>
    </Page>
  );
};
