import React from 'react';
import cn from 'classnames';
import { Widget } from '@typeform/embed-react';

import './Typeform.styles.scss';

export const Typeform = ({ className, id, ...props }) => {
  const _className = cn('typeform', className);

  return (
    <Widget {...props} id={`${id}?typeform-welcome=0`} className={_className} />
  );
};
