import { useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query VideosQuery {
    allStrapiCircleVideo {
      circleVideos: nodes {
        id
        published_at: publishedAt
        restricted
        order
        title: video_title
        description: video_description
        url: video_url
        thumbnail: video_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }
  }
`;

export const useStrapiVideos = () => {
  const {
    allStrapiCircleVideo: { circleVideos }
  } = useStaticQuery(query);

  const getVideosByIds = useCallback(
    (ids = []) => {
      if (!ids?.length) return [];
      return ids.map(id => circleVideos.find(video => video.id === id));
    },
    [circleVideos]
  );

  return { circleVideos, getVideosByIds };
};
