import React, { memo, useEffect, useState } from 'react';

import {
  UserFellowshipModal,
  UserScholarshipModal,
  UserSwagModal
} from './User';

import { VideoPlayerModal } from './Video';
import { EventRSVPModal } from './Event';
import { ApplicationFormModal } from './Position';
import { HackathonRegistrationModal } from './Hackathon';
import { TypeformModal } from './_shared/Typeform';

import { modalControllerEvents } from '_events';
import { mergeObjects, mergeProps } from 'utils';

const MODAL_COMPONENTS = {
  userScholarship: UserScholarshipModal,
  userSwag: UserSwagModal,
  userFellowship: UserFellowshipModal,
  videoPlayer: VideoPlayerModal,
  eventRSVP: EventRSVPModal,
  applicationFormModal: ApplicationFormModal,
  hackathonRegistation: HackathonRegistrationModal,
  typeform: TypeformModal
};

export const ModalController = memo(() => {
  const [{ modalName, props: modalProps }, setActiveModal] = useState({});

  const ActiveModal = MODAL_COMPONENTS[modalName];

  useEffect(() => {
    return modalControllerEvents.onToggleModal(event => {
      setActiveModal(event);
    });
  }, []);

  if (!modalName || !ActiveModal) return null;

  return React.createElement(
    ActiveModal,
    mergeProps(modalProps, {
      onClose: () => {
        setActiveModal(props => {
          return mergeObjects(props, { props: { isOpen: false } });
        });
      }
    })
  );
});
