import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';

import { ROUTES } from '_constants';

import { Logo } from 'components/Logo';
import { Link } from 'components/_shared/Link';

import { useStrapiMenuLinks } from 'hooks';
import { stringKeyReplacer } from 'utils';

import './Footer.styles.scss';

const query = graphql`
  query FooterQuery {
    footer: strapiFooter {
      copyrights
      message
      message_flags
      menu_primary {
        link {
          menu_link {
            slug
          }
        }
      }
      menu_secondary {
        link {
          menu_link {
            slug
          }
        }
      }
      menu_bottom {
        link {
          menu_link {
            slug
          }
        }
      }
    }
  }
`;

const Links = ({ className, links = [] }) => {
  if (!links?.length) return null;

  return (
    <ul className={cn('footer-links', className)}>
      {links.map(link => (
        <li key={link.slug} className='footer-links-item'>
          <Link className='footer-link' href={link.url}>
            {link.children}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export const Footer = ({ className, isDark, ...props }) => {
  const { footer } = useStaticQuery(query);
  const { getLinksBySlugs } = useStrapiMenuLinks();

  const _className = cn('footer', { isDark }, className);

  const [primaryLinks, secondaryLinks, bottomLinks] = useMemo(() => {
    return [footer.menu_primary, footer.menu_secondary, footer.menu_bottom].map(
      links => {
        return getLinksBySlugs(
          links.link.map(({ menu_link: { slug } }) => slug)
        ).map(link => ({ ...link, children: link.text }));
      }
    );
  }, [
    footer.menu_primary,
    footer.menu_secondary,
    footer.menu_bottom,
    getLinksBySlugs
  ]);

  return (
    <footer {...props} className={_className}>
      <div className='footer-content'>
        <div className='footer-col'>
          <Link href={ROUTES.ROOT} className='footer-logo'>
            <Logo />
          </Link>

          {footer.message && (
            <p className='footer-message'>
              <span>{footer.message}</span>
              {footer.message_flags && (
                <span className='flags'>
                  {footer.message_flags.split(' ').map(flag => (
                    <span key={flag}>{flag}</span>
                  ))}
                </span>
              )}
            </p>
          )}
        </div>

        <div className='footer-col'>
          <div className='footer-links-group'>
            {!!primaryLinks?.length && (
              <Links className='primary' links={primaryLinks} />
            )}
            {!!secondaryLinks?.length && (
              <Links className='secondary' links={secondaryLinks} />
            )}
          </div>

          <div className='footer-bottom'>
            {footer.copyrights && (
              <p className='footer-copyrights'>
                {stringKeyReplacer(footer.copyrights, {
                  year: new Date().getFullYear()
                })}
              </p>
            )}

            {!!bottomLinks?.length && (
              <Links className='bottom' links={bottomLinks} />
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};
