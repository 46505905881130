import React from 'react';
import cn from 'classnames';

import './Button.styles.scss';

// TODO: Update this component
// * Update structure
// * Remove unused style variants
// * Add new variants

export const Button = ({
  children,
  className,
  disabled,
  variant,
  size,
  color,
  icon,
  isIconFloat = false,
  iconPosition = 'left',
  ...props
}) => {
  const _className = cn(
    className?.includes('link') ? 'link' : 'button',
    variant,
    {
      [`color-${color}`]: color,
      [`size-${size}`]: size,
      [`icon-${iconPosition}`]: iconPosition,
      isIconFloat
    },
    className
  );

  return (
    <button type='button' {...props} className={_className} disabled={disabled}>
      {disabled && <span className={cn('button-mask', className)} />}
      {icon && <span className='icon'>{icon}</span>}
      {children && <span className='children'>{children}</span>}
    </button>
  );
};
