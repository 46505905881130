const root = '/v1/user/';

const submitTShirt = `${root}t-shirt-submission`;

const applyFellowship = `${root}apply-for-fellowship`;

const sessionTokenExchange = `${root}token/sessionprofile`;

const betaAllowed = `${root}typeform/beta/`;

export const userEndpoints = {
  root,
  submitTShirt,
  applyFellowship,
  sessionTokenExchange,
  betaAllowed
};
