import { useSelector } from 'react-redux';
import {
  selectUserIsApplicationsLoaded,
  selectUserApplications
} from '../user/user.slice';

export function useUserApplications() {
  const isLoaded = useSelector(selectUserIsApplicationsLoaded);
  const applications = useSelector(selectUserApplications);
  return { isLoaded, applications };
}
