import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { FormikProvider, useFormik } from 'formik';
import cn from 'classnames';

import { withFunction } from 'utils';

import './Form.styles.scss';
import { FormProvider } from './Form.context';

export const Form = forwardRef(
  (
    {
      children,
      className,
      initialValues = {},
      validationSchema,
      isLoading = false,
      onReset,
      onSubmit,
      onError,
      params = {},
      variant,
      ...props
    },
    ref
  ) => {
    const rootRef = useRef(null);

    const [shouldScrollToError, setShouldScrollToError] = useState(false);

    const formik = useFormik({
      validateOnBlur: false,
      ...params,
      initialValues,
      validationSchema,
      onReset,
      onSubmit
    });

    const _className = cn('form', variant, className);

    const hasError = Object.keys(formik.errors).some(field => {
      return formik.touched[field];
    });

    useImperativeHandle(ref, () => ({ element: rootRef.current, ...formik }), [
      formik
    ]);

    useEffect(() => {
      if (!shouldScrollToError) return;
      rootRef.current[Object.keys(formik.errors)[0]]?.focus?.();
      setShouldScrollToError(false);
    }, [shouldScrollToError, formik.errors]);

    useEffect(() => {
      if (!hasError) {
        return setShouldScrollToError(false);
      }

      setShouldScrollToError(true);
      onError?.();
    }, [hasError, onError]);

    return (
      <form
        {...props}
        ref={rootRef}
        className={_className}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
      >
        <FormProvider variant={variant}>
          <FormikProvider value={formik}>
            {children && withFunction(children, formik)}
            {isLoading && <div className='form-preloader' />}
          </FormikProvider>
        </FormProvider>
      </form>
    );
  }
);
