import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cn from 'classnames';

import { ROUTES } from '_constants';

import { Link } from 'components/_shared/Link';
import { Logo } from 'components/Logo';
import { BetaSignUpButton } from 'components/Beta';
import { HeaderMenu, HeaderMenuButton } from './Menu';

import { useHeaderAnimation } from './Header.animation';
import { useStrapiCommonContent, useStrapiMenuLinks } from 'hooks';
import { mergeProps } from 'utils';

import './Header.styles.scss';

const query = graphql`
  query HeaderQuery {
    header: strapiHeader {
      title
      menu_title
      menu_subtitle
      menu_subtitle_mobile
    }
  }
`;

export const Header = React.forwardRef(
  ({ className, title, subtitle, scroller, ...props }, ref) => {
    const { header } = useStaticQuery(query);
    const { beta_signup, beta_signup_mobile } = useStrapiCommonContent();
    const { currentLink } = useStrapiMenuLinks();
    const { medium, large } = useBreakpoint();

    const { signUpButton, menuButton, isDark } = mergeProps(
      {
        isDark: false,
        menuButton: {
          title: header.menu_title,
          subtitle: large ? header.menu_subtitle : header.menu_subtitle_mobile
        },
        signUpButton: {
          text: medium ? beta_signup : beta_signup_mobile
        }
      },
      [
        props,
        ({ isDark, menuButton, signUpButton }) => ({
          menuButton: {
            color: menuButton.color
              ? menuButton.color
              : isDark
              ? 'white-10'
              : 'greyShade-5'
          },
          signUpButton: {
            color: signUpButton.color
              ? signUpButton.color
              : isDark
              ? 'white'
              : 'blackShade-90'
          }
        })
      ]
    );

    const rootRef = useRef();
    const menuRef = useRef();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const { isVisible, setIsVisible, getProgress } = useHeaderAnimation(
      rootRef,
      scroller
    );

    const handleMouseEnter = () => setIsVisible(true);

    const handleMouseLeave = () => {
      if (menuRef.current?.isOpen || getProgress() === 0) return;
      setIsVisible(false);
    };

    const handleMenuButtonClick = () => menuRef.current?.toggle();

    useEffect(() => {
      !isVisible && menuRef.current?.setIsOpen(false);
    }, [isVisible]);

    useImperativeHandle(ref, () => rootRef);

    const _title = title || header.title;
    const _subtitle = subtitle || currentLink?.text;

    const _className = cn(
      'header',
      { isMenuOpen, isVisible, isDark },
      className
    );

    return (
      <header
        ref={rootRef}
        className={_className}
        role='presentation'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className='header-content'>
          <div className='header-group'>
            <Link className='header-logo' href={ROUTES.ROOT}>
              <Logo />
            </Link>

            {(_title || _subtitle) && (
              <div className='header-info'>
                {_title && <p className='header-title'>{_title}</p>}
                {_subtitle && <p className='header-subtitle'>{_subtitle}</p>}
              </div>
            )}
          </div>

          <div className='header-group'>
            <HeaderMenuButton
              {...menuButton}
              isMenuOpen={isMenuOpen}
              onClick={handleMenuButtonClick}
            />
            <BetaSignUpButton {...signUpButton} />
          </div>
        </div>

        <HeaderMenu
          ref={menuRef}
          clickOutsideIgnoredClassNames={['header-menu-button']}
          isDark={isDark}
          onIsOpenChange={setIsMenuOpen}
        />
      </header>
    );
  }
);
