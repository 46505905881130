import React from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import identity from 'lodash-es/identity';
import pick from 'lodash-es/pick';
import pickBy from 'lodash-es/pickBy';

import { Modal } from 'components/_shared/Modal';
import { Form, FormField, FormGroup } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';
import { ModalDevRevAnimation } from 'components/_shared/ModalDevRevAnimation/ModalDevRevAnimation';

import { selectUser, useUserActions } from 'state';
import { mergeObjects } from 'utils';

import { useCallback } from 'react';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  firstname: Yup.string().required().label('First name'),
  lastname: Yup.string().required().label('Last name'),
  devrev_linkedin: Yup.string()
    .matches(
      /^((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))/,
      'Value must be a valid LinkedIn URL'
    )
    .label('LinkedIn address')
    .required(),
  devrev_github: Yup.string().matches(
    /^(http(s?):\/\/)?(www\.)?github\.([a-z])+\/([-A-Za-z0-9]{1,})+\/?$/,
    'Value must be a valid GitHub URL'
  ),
  devrev_website: Yup.string().matches(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    'Value must be a valid URL'
  )
});

export const UserJoinModal = ({
  className,
  initialValues = {},
  onSubmit,
  ...props
}) => {
  const user = useSelector(selectUser);

  const fields = {
    subheader: 'Enter the Circle',
    header: 'Tell us about yourself'
  };

  const { isLoading, logout, updateCurrentUser } = useUserActions();

  const _className = cn('', className);

  const defaultInitialValues = {
    email: '',
    firstname: '',
    lastname: '',
    devrev_linkedin: '',
    devrev_github: '',
    devrev_website: ''
  };

  const _initialValues = mergeObjects(
    defaultInitialValues,
    pick(pickBy(user, identity), Object.keys(defaultInitialValues)),
    initialValues
  );

  const handleBack = useCallback(() => {
    logout();
  }, [logout]);

  const handleSubmit = async values => {
    updateCurrentUser(values);
    onSubmit?.(values);
  };

  const applyForm = () => {
    return (
      <>
        <h4 className='text-lg'>{fields.subheader}</h4>
        <h2 className='text-3xl md:text-4xl lg:text-5xl mt-2 font-normal devrev-large-text-wrapper'>
          {fields.header}
        </h2>

        <Form
          initialValues={_initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          params={{ enableReinitialize: true }}
          variant='secondary'
        >
          <FormGroup>
            <FormField
              name='email'
              label='Email *'
              disabled={!!_initialValues.email}
            />

            <FormGroup direction='row'>
              <div className='md:mr-6'>
                <FormField name='firstname' label='First name *' />
              </div>
              <FormField name='lastname' label='Last name *' />
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <FormField name='devrev_linkedin' label='LinkedIn address *' />
            <FormField name='devrev_github' label='GitHub profile' />
            <FormField name='devrev_website' label='Personal website' />
          </FormGroup>

          <div className='flex justify-between items-end'>
            <Button
              className='block mt-10 text-lg md:text-2xl'
              disabled={isLoading}
              onClick={handleBack}
            >
              Logout
            </Button>
            <Button
              type='submit'
              className='block mt-10 text-lg md:text-2xl'
              disabled={isLoading}
            >
              {isLoading ? 'Saving ...' : 'Continue →'}
            </Button>
          </div>
        </Form>
      </>
    );
  };

  return (
    <Modal {...props} className={_className}>
      <div className='flex'>
        <div className='hidden lg:block lg:w-1/2 relative'>
          <div className='absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 -ml-16'>
            <ModalDevRevAnimation />
          </div>
        </div>
        <div className='w-full lg:w-1/2'>{applyForm()}</div>
      </div>
    </Modal>
  );
};
