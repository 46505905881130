import { useCallback, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import {
  ACTION_METHODS,
  ACTION_ROUTES_BY_SLUG,
  BETA_STATUS,
  ROUTES
} from '_constants';

import { useUserActions, selectUser, selectUserBetaStatus } from 'state/user';

import { modalControllerEvents } from '_events';
import { getTypeformId, mergeObjects } from 'utils';

const query = graphql`
  query ActionsQuery {
    allStrapiAction {
      actions: nodes {
        id
        title
        text
        description {
          data {
            description
          }
        }
        link
        method
        disabled
        slug
      }
    }
  }
`;

export const useStrapiActions = () => {
  const {
    allStrapiAction: { actions }
  } = useStaticQuery(query);

  const user = useSelector(selectUser);
  const userBetaStatus = useSelector(selectUserBetaStatus);
  const { login, logout, submitBetaSignIn } = useUserActions();

  const methods = useMemo(
    () => ({
      [ACTION_METHODS.CIRCLE_ENTER]: () => () => login(ROUTES.CIRCLE_SUCCESS),
      [ACTION_METHODS.CIRCLE_LOGIN]: () => login,
      [ACTION_METHODS.CIRCLE_LOGOUT]: () => logout,
      [ACTION_METHODS.BETA_APPLY]: action => () => {
        if (!action.link?.includes('typeform')) return;
        const params = new URLSearchParams(window.location.search);
        const parsed = Object.fromEntries(params);
        let hiddenFields = {
          utm_campaign: parsed.utm_campaign,
          utm_source: parsed.utm_source,
          utm_medium: parsed.utm_medium,
          utm_term: parsed.utm_term,
          utm_content: parsed.utm_content,
          email: user.email,
          linkedin: user.devrev_linkedin,
          referralcode: parsed.referral_code
        };
        if (user.firstname && user.lastname) {
          hiddenFields['name'] = `${user.firstname} ${user.lastname}`;
        }
        return modalControllerEvents.toggleModal('typeform', {
          isOpen: true,
          form: {
            id: getTypeformId(action.link),
            hidden: { hiddenFields },
            onSubmit: submitBetaSignIn
          }
        });
      },
      [ACTION_METHODS.BETA_APPLY_PAGE]: () => {
        return () => navigate(ROUTES.BETA_FORM_APPLY + window?.location?.search);
      }
    }),
    [
      login,
      logout,
      user.email,
      user.firstname,
      user.lastname,
      user.devrev_linkedin,
      submitBetaSignIn
    ]
  );

  const actionPropsByMethod = useMemo(
    () => ({
      [ACTION_METHODS.NAVIGATE]: action => ({
        link: action.link || ACTION_ROUTES_BY_SLUG[action.slug]
      }),
      [ACTION_METHODS.BETA_APPLY_PAGE]: () => ({
        disabled: userBetaStatus !== BETA_STATUS.NOT_APPLIED
      })
    }),
    [userBetaStatus]
  );

  const _actions = useMemo(() => {
    return actions.map(action => {
      return mergeObjects(
        { ...action, exec: methods[action.method]?.(action) },
        [
          actionPropsByMethod[action.method]?.(action),
          source => ({
            isLink:
              source.method === ACTION_METHODS.NAVIGATE ||
              (action.link && !action.method)
          })
        ]
      );
    });
  }, [actions, methods, actionPropsByMethod]);

  const getActionById = useCallback(
    id => {
      if (Array.isArray(id)) {
        return id.map(id => _actions.find(action => action.id === id));
      }

      return _actions.find(action => action.id === id);
    },
    [_actions]
  );

  const getActionBySlug = useCallback(
    slug => {
      if (Array.isArray(slug)) {
        return slug.map(slug => _actions.find(action => action.slug === slug));
      }

      return _actions.find(action => action.slug === slug);
    },
    [_actions]
  );

  return { actions: _actions, getActionById, getActionBySlug };
};
