import { useMemo } from 'react';

export function useDefaultInitialValues(steps) {
  return useMemo(() => {
    const stepDefaultValues = [];
    for (const step of steps) {
      const values = {};
      for (const section of step.sections) {
        for (const row of section.rows) {
          for (const child of row) {
            if (child.field) {
              values[child.field.name] = child.field.defaultValue || '';
            }
          }
        }
      }
      stepDefaultValues.push(values);
    }
    return stepDefaultValues;
  }, [steps]);
}
