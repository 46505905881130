import React from 'react';
import cn from 'classnames';

import { Button } from 'components/_shared/Button';

import { modalControllerEvents } from '_events';

import './HackathonRegistrationCTA.styles.scss';

export const HackathonRegistrationCTA = ({
  className,
  text,
  deadline,
  onSubmit,
  ...props
}) => {
  const _className = cn(
    'hackathon-registration-cta',
    'outer-hairline',
    className
  );

  const handleClick = () => {
    modalControllerEvents.toggleModal('hackathonRegistation', {
      isOpen: true,
      onSubmit: () => {
        onSubmit?.();
        modalControllerEvents.toggleModal('hackathonRegistation', {
          isOpen: false
        });
      }
    });
  };

  return (
    <Button
      {...props}
      type='button'
      className={_className}
      onClick={handleClick}
    >
      <span className='cta-text'>{text}</span>
      <span className='cta-text cta-subtext'>
        Deadline:
        <br />
        {deadline}
      </span>
    </Button>
  );
};
