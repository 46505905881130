import React from 'react';
import cn from 'classnames';

import './InputCheckboxGroup.styles.scss';

export const InputCheckboxGroup = ({
  id,
  className,
  options = [],
  value = [],
  variant,
  size,
  direction = 'col',
  hasError,
  onChange,
  ...props
}) => {
  const _className = cn(
    'input input-checkbox',
    variant,
    direction,
    size,
    { hasError },
    className
  );

  const handleChange = (event, option) => {
    event.customValue = value.includes(option.value)
      ? value.filter(value => value !== option.value)
      : [...value, option.value];

    onChange?.(event);
  };

  return (
    <div id={id} className={_className}>
      {options.map(option => {
        const _id = `${id}-${option.value}`;
        const isChecked = value.includes(option.value);

        return (
          <label
            key={option.value}
            className={cn('input-checkbox-control', {
              isChecked,
              isDisabled: option.isDisabled
            })}
            htmlFor={_id}
          >
            <input
              {...props}
              id={_id}
              type='checkbox'
              className='input-checkbox-input'
              checked={isChecked}
              disabled={option.isDisabled}
              onChange={event => handleChange(event, option)}
            />
            <span className='input-checkbox-check inner-hairline' />
            <span className='input-checkbox-children'>{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};
