import React from 'react';
import cn from 'classnames';

import { Link } from '../Link';
import { Button } from '../Button';

import { mergeProps } from 'utils';

export const Action = ({
  className,
  variant,
  size,
  icon,
  color,
  disabled,
  iconPosition,
  isIconFloat,
  buttonProps = {},
  linkProps = {},
  asStyles,
  isLink = false,
  ...props
}) => {
  const Element = isLink ? Link : Button;

  const stylingProps = {
    variant,
    size,
    color,
    disabled,
    iconPosition,
    isIconFloat
  };

  const stylingClasses = cn(variant, {
    disabled,
    [`size-${size}`]: size,
    [`color-${color}`]: color,
    [`icon-${iconPosition}`]: iconPosition,
    isIconFloat
  });

  const _props = mergeProps(
    {
      className: cn('action', { isLink }, className),
      children: props.text,
      disabled: props.disabled
    },
    [
      asStyles === 'button' &&
        (!isLink ? stylingProps : { className: cn('button', stylingClasses) }),

      asStyles === 'link' &&
        (isLink ? stylingProps : { className: cn('link', stylingClasses) }),

      !asStyles && stylingProps,

      !isLink && [{ onClick: props.exec }, buttonProps],

      isLink && [{ href: props.link, target: props.target }, linkProps]
    ]
  );

  return <Element {..._props} icon={icon} />;
};
