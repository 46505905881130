import ReactDOM from 'react-dom';

import isFunction from 'lodash-es/isFunction';
import isUndefined from 'lodash-es/isUndefined';
import isNil from 'lodash-es/isNil';
import isString from 'lodash-es/isString';
import cn from 'classnames';

import { mergeObjects } from './mergeObjects';

export const mergeProps = (initialProps, newProps) => {
  return mergeObjects(initialProps, newProps, (value, newValue, key) => {
    if (key.match(/children|ref|component|render/)) {
      return !isUndefined(newValue) ? newValue : value;
    }

    if ([value, newValue].every(isString) && value === newValue) return value;

    if (key.match('className')) return cn(value, newValue);

    if (isFunction(value) || isFunction(newValue)) {
      if (newValue === false) return undefined;
      return (...args) => {
        value?.(...args);
        newValue?.(...args);
      };
    }
  });
};

export const withFunction = (value, args) => {
  return isFunction(value) ? value(args) : value;
};

export const getLocalStorageItem = key => {
  if (typeof window === 'undefined') return undefined;

  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export function isDate(value) {
  return (
    value instanceof Date ||
    Object.prototype.toString.call(value) === '[object Date]'
  );
}

function isDateString(value) {
  if (!value || !isNaN(+value)) return false;
  return !!new Date(value).valueOf();
}

export function sortByKey(
  arr = [],
  { key = 'order', equalKey, breakValue = -1 } = {}
) {
  return [...arr].sort((a, b) => {
    const isEqual = a[key] === b[key];
    const _key = isEqual && equalKey ? equalKey : key;
    const [aValue, bValue] = [a[_key], b[_key]];

    if (isEqual && !equalKey) return 0;

    if (!isNil(aValue) && isNil(bValue)) return -1;

    if ([aValue, bValue].some(i => i === breakValue)) {
      return aValue === breakValue ? 1 : -1;
    }

    if (isDate(aValue) || isDateString(aValue)) {
      return new Date(bValue) - new Date(aValue);
    }

    if (typeof aValue === 'string') {
      if ([aValue, bValue].some(v => !isNaN(v))) {
        return aValue - bValue;
      }

      return aValue.localeCompare(bValue);
    }

    if (typeof aValue === 'number') {
      return aValue - bValue;
    }

    return 1;
  });
}

export function getInitialValuesByFields(fields = [], nameKey = 'name') {
  return fields.reduce((initialValues, field) => {
    let initialValue = '';

    if (field.type === 'multi-select') initialValue = [];

    return { ...initialValues, [field[nameKey]]: initialValue };
  }, {});
}

export function simulateLinkClick(href) {
  if (typeof window === 'undefined') return undefined;
  const link = document.createElement('a');
  link.setAttribute('href', href);
  link.setAttribute('target', '_blank');
  link.setAttribute('rel', 'noreferrer');
  link.style.cssText += 'position: absolute; opacity: 0; visibility:hidden;';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function checkIsTouchEnabled() {
  if (typeof window === 'undefined') return false;

  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

export function getViewportSize() {
  if (typeof window === 'undefined') return { width: 0, height: 0 };
  return {
    width: Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ),
    height: Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )
  };
}

export function buildGetScrollDirection() {
  let prevY = window.scrollY;
  let prevTouchedY = null;

  return event => {
    if (event?.type === 'wheel') {
      return event.deltaY > 0 ? 1 : 0;
    }

    if (event?.type === 'touchstart') {
      prevTouchedY = event.changedTouches[0].screenY;
      return -1;
    }

    if (event?.type === 'touchend') {
      const { screenY } = event.changedTouches[0];
      const diff = Math.abs(prevTouchedY - screenY);
      if (screenY === prevTouchedY || diff <= 30) return -1;
      return screenY < prevTouchedY ? 1 : 0;
    }

    return prevY <= (prevY = window.scrollY) ? 1 : 0;
  };
}

export function stringKeyReplacer(str, keys) {
  for (const key in keys) {
    str = str.split(`{${key}}`).join(keys[key]);
  }
  return str;
}

export const urlBuilder = stringKeyReplacer;

export function tr(translation, keys = null) {
  if (!keys) return translation;
  return stringKeyReplacer(translation, keys);
}

export function getYouTubeVideoIdByURL(url) {
  if (!url) return '';
  return (
    url.match(
      //eslint-disable-next-line
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    )?.[2] || ''
  );
}

export function getYouTubeEmbedVideoById(videoId, params = {}) {
  const _params = new URLSearchParams({ rel: 0, ...params }).toString();
  return `https://www.youtube-nocookie.com/embed/${videoId}?${_params}`;
}

export function truncate(str, max, suffix) {
  if (str.length < max) return str;

  return `${str.substr(
    0,
    str.substr(0, max - suffix.length).lastIndexOf(' ')
  )}${suffix}`;
}

export function truncateText(excerpt, length) {
  if (!excerpt) return '';
  return length ? truncate(excerpt, length, '...') : excerpt;
}

export function appendComponentToDOM(component, target) {
  ReactDOM.render(component, target.appendChild(document.createElement('div')));
}

export function matchVideoExtension(str) {
  return str.match(/.*(mp4|mov|wmv|avi|flv|mkv)$/);
}

export function matchVideoLink(str) {
  return str.match(/^http.*\.(mp4|mov|wmv|avi|flv|mkv)$/);
}
