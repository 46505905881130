import React from 'react';
import * as Yup from 'yup';
import cn from 'classnames';

import { Form, FormField, FormGroup } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';
import { asRequired } from 'utils/forms';

const validationSchema = labels =>
  Yup.object().shape({
    projectGithubUrl: Yup.string()
      .matches(
        /^(http(s?):\/\/)?(www\.)?github\.([a-z])+\/([-A-Za-z0-9]{1,})+\/?$/,
        labels.form_error_invalidGithub
      )
      .required(labels.form_error_required),
    projectPresentationUrl: Yup.string()
      .matches(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
        labels.form_error_invalidUrl
      )
      .required(labels.form_error_required),
    projectVideoUrl: Yup.string()
      .matches(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
        labels.form_error_invalidUrl
      )
      .required(labels.form_error_required)
  });

export const HackathonSubmissionsForm = ({
  className,
  initialValues,
  submitButtonText = 'Submit',
  isLoading = false,
  labels,
  ...props
}) => {
  const _className = cn('hackathon-submissions-form', className);

  const _initialValues = {
    projectGithubUrl: '',
    projectPresentationUrl: '',
    projectVideoUrl: '',
    ...initialValues
  };

  return (
    <Form
      {...props}
      className={_className}
      initialValues={_initialValues}
      validationSchema={validationSchema(labels)}
      isLoading={isLoading}
    >
      <FormGroup>
        <FormField
          name='projectGithubUrl'
          type='text'
          label={asRequired(labels.form_githubLink_label)}
          placeholder={labels.form_link_placeholder}
        />
        <FormField
          name='projectPresentationUrl'
          type='text'
          label={asRequired(labels.form_presentationLink_label)}
          placeholder={labels.form_link_placeholder}
        />
        <FormField
          name='projectVideoUrl'
          type='text'
          label={asRequired(labels.form_videoLink_label)}
          placeholder={labels.form_link_placeholder}
        />
      </FormGroup>

      <Button type='submit' variant='primary' disabled={isLoading}>
        {submitButtonText}
      </Button>
    </Form>
  );
};
