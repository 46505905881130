import React from 'react';
import cn from 'classnames';

import { Modal } from 'components/_shared/Modal';
import { HackathonRegistrationForm } from '../Form';

import './HackathonRegistrationModal.styles.scss';

export const HackathonRegistrationModal = ({
  className,
  onSubmit,
  ...props
}) => {
  const _className = cn('hackathon-registration-modal', className);

  return (
    <Modal {...props} className={_className}>
      <HackathonRegistrationForm onSubmit={onSubmit} />
    </Modal>
  );
};
