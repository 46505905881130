import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query CommonComponentsQuery {
    strapiComponentslabel {
      accountsettingslabel
      applicationstatusdetaillabel
      applicationstatustip
      homepagestartover
      jobapplicationlabel
      jobapplicationstatus
      logoutlabel
      pagenonfoundlabel
      socialmediaconnect
    }

    strapiCommonContent {
      arrow
      author
      back_to_blog
      back_to_news
      continue_on
      read_more
      github
      view_on_github
      back_to_hackathon_gallery
      hack0_submissions
      lets_connect
      go_to_blog
      go_to_videos
      submit
      faq
      quick_links
      back_to_marketplace
      type
      category
      join_us_heading
      join_us_subheading
      beta_signup
      beta_signup_mobile
      sign_up
      get_started
    }
  }
`;

export const useStrapiCommonContent = () => {
  const data = useStaticQuery(query);

  return useMemo(
    () => ({
      ...data.strapiComponentslabel,
      ...data.strapiCommonContent
    }),
    [data]
  );
};
