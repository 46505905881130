import React from 'react';
import cn from 'classnames';

import { Action } from '../Action';

import './ActionList.styles.scss';

export const ActionList = ({
  className,
  actions = [],
  actionProps = {},
  direction = 'row'
}) => {
  const _className = cn('action-list', direction, className);

  if (!actions?.length) return null;

  return (
    <ul className={_className}>
      {actions.map(action => (
        <li key={action.id} className='action-list-item'>
          <Action {...action} {...actionProps} />
        </li>
      ))}
    </ul>
  );
};
