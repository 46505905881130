import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import { IconPlayButton, LogoIconColor } from 'assets';

import { WaitlistTooltip } from 'components/WaitlistTooltip';
import { truncateText, trackEvent } from 'utils';

import { selectUser, useUserActions, useUserAccess } from 'state';
import { modalControllerEvents } from '_events';

import './VideoList.styles.scss';

const query = graphql`
  query VideosListQuery {
    strapiVideospage {
      tooltip_loggedin
      tooltip_not_loggedin
      tooltip_loggedin_heading
      tooltip_not_loggedin_heading
    }
  }
`;

export const VideoList = ({
  className,
  videos = [],
  variant,
  descriptionLength,
  withRestriction = false
}) => {
  const { strapiVideospage: fields } = useStaticQuery(query);
  const { circleStatus } = useSelector(selectUser);
  const { isAuthenticated } = useUserAccess();
  const { login } = useUserActions();
  const _className = cn('video-list', variant, className);
  const iframes = useMemo(() => {
    
    if (!videos?.length) return null;
    return videos.reduce((iframes, video) => {
      return {
        ...iframes,
        [video.id]: (
          <iframe
            title={video.title}
            src={video.url}
            frameBorder='0'
            webkitallowfullscreen='true'
            mozallowfullscreen='true'
            allowFullScreen
          />
        )
      };
    }, {});
  }, [videos]);

  const handleVideoClick = useCallback(
    video => {
      if (
        !withRestriction ||
        (isAuthenticated && circleStatus.isApproved) ||
        !video.restricted
      ) {
        trackEvent(`Video - View ${video.id}`, video);
        return modalControllerEvents.toggleModal('videoPlayer', {
          isOpen: true,
          video: iframes[video.id]
        });
      }

      if (!isAuthenticated) {
        trackEvent(`Circle - View Application Page`);
        login();
      }
    },
    [iframes, isAuthenticated, circleStatus.isApproved, login, withRestriction]
  );

  return (
    <>
      {withRestriction && !isAuthenticated && (
        <WaitlistTooltip
          heading={fields.tooltip_not_loggedin_heading}
          text={fields.tooltip_not_loggedin}
          triggerClassNames={['.video-list-item:not(.isAllowed)']}
        />
      )}

      {withRestriction && isAuthenticated && circleStatus.isApplied && (
        <WaitlistTooltip
          heading={fields.tooltip_loggedin_heading}
          text={fields.tooltip_loggedin}
          triggerClassNames={['.video-list-item:not(.isAllowed)']}
        />
      )}

      <ul className={_className}>
        {videos.map(video => (
          <li
            key={video.id}
            className={cn('video-list-item', {
              isAllowed:
                !withRestriction ||
                (isAuthenticated && circleStatus.isApproved) ||
                !video.restricted
            })}
          >
            <article className='video'>
              <div className='video-thumbnail'>
                {video.thumbnail ? (
                  <GatsbyImage
                    image={getImage(video.thumbnail.localFile)}
                    alt={video.title}
                  />
                ) : (
                  <div className='default'>
                    <LogoIconColor className='devrev-logo-icon' />
                  </div>
                )}
                <button
                  className='video-button-play'
                  onClick={() => handleVideoClick(video)}
                >
                  <IconPlayButton />
                </button>
              </div>

              <div className='video-content'>
                <h4 className='video-title'>{video.title}</h4>

                {video.date && (
                  <time className='video-date' dateTime={video.date}>
                    {video.date}
                  </time>
                )}

                {video.description && (
                  <p className='video-description'>
                    {truncateText(video.description, descriptionLength)}
                  </p>
                )}
              </div>
            </article>
          </li>
        ))}
      </ul>
    </>
  );
};
