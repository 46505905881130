import { useCallback, useMemo } from 'react';

import { useStrapiBlogPosts } from './useStrapiBlogPosts';
import { useStrapiPLGPosts } from './useStrapiPLGPosts';

export function useStrapiPosts() {
  const { posts: blogPosts } = useStrapiBlogPosts();
  const { posts: plgPosts } = useStrapiPLGPosts();

  const posts = useMemo(() => {
    return [blogPosts, plgPosts].flat();
  }, [blogPosts, plgPosts]);

  const getPostsByIds = useCallback(
    (ids = []) => {
      if (!ids?.length) return [];
      return ids.map(id => posts.find(post => post.id === id));
    },
    [posts]
  );
  return { posts, getPostsByIds };
}
