import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query PositionsQuery {
    allPositions {
      nodes {
        content
        departments {
          id
          name
        }
        id
        internal_job_id
        location {
          name
        }
        offices {
          id
          location
          name
        }
        title
        initialId
        requisition_id
        education
        absolute_url
        questions {
          label
          required
          fields {
            name
            type
            values {
              label
              value
            }
          }
        }
      }
    }
  }
`;

export const useApiPositions = () => {
  const data = useStaticQuery(query);
  return data.allPositions.nodes;
};

export const useApiPositionById = id => {
  const positions = useApiPositions();
  return positions.find(({ initialId }) => initialId === id);
};
