import React from 'react';
import cn from 'classnames';
import { LinkedinShareButton, TwitterShareButton } from 'react-share';

import { IconLinkedIn, IconTwitter } from 'assets';

export const SocialShare = ({
  className,
  url,
  title,
  description,
  size = 'h-7 w-7 md:h-7 md:w-7 lg:h-11 lg:w-11'
}) => {
  const _className = cn('social-share', 'flex items-center', className);
  const _url = url || typeof window !== 'undefined' ? window.location.href : '';

  return (
    <div className={_className}>
      <div className='social-link-group'>
        <TwitterShareButton
          className={cn(
            'text-white focus:outline-none twitter-button muted',
            size
          )}
          url={_url}
          quote={description}
        >
          <IconTwitter />
        </TwitterShareButton>

        <LinkedinShareButton
          className={cn(
            'text-white focus:outline-none linkedin-button muted',
            size
          )}
          url={_url}
          title={title}
          summary={description}
        >
          <IconLinkedIn />
        </LinkedinShareButton>
      </div>
    </div>
  );
};
