import React from 'react';
import cn from 'classnames';

import './InputRadio.styles.scss';

export const InputRadio = ({
  id,
  className,
  options = [],
  value,
  variant,
  direction = 'row',
  hasError,
  onChange,
  ...props
}) => {
  const _className = cn(
    'input input-radio',
    variant,
    direction,
    { hasError },
    className
  );

  return (
    <div id={id} className={_className}>
      {options.map(option => {
        const _id = `${id}-${option.label}`;

        return (
          <label
            key={option.value}
            className={cn('input-radio-control', {
              isChecked: value === option.value
            })}
            htmlFor={_id}
          >
            <input
              {...props}
              id={_id}
              type='radio'
              className='input-radio-input'
              checked={value === option.value}
              onChange={e => {
                e.target.value = option.value;
                onChange?.(e);
              }}
            />
            <span className='input-radio-check' />
            <span className='input-radio-children'>{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};
