import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import upperFirst from 'lodash-es/upperFirst';
const query = graphql`
  query JobStatusQuery {
    strapiCommonContent {
      job_active
      job_hired
      job_rejected
      job_processing
    }
  }
`;

export const JobStatus = ({ status }) => {
  const { strapiCommonContent } = useStaticQuery(query);
  return <span> {upperFirst(strapiCommonContent[`job_${status}`])} </span>;
};
