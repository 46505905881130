import { graphql, useStaticQuery } from 'gatsby';
/**
 *
 * @returns {{
 * stepOfSteps: string
 * continueButton: string
 * backButton: string
 * sendingApplication: string
 * errorWhileSendingApplication: string
 * thankYouHeading: string
 * thankYouDescription: string
 * addMoreLinks: string
 * fieldIsRequired: string
 * textInputDefaultPlaceholder: string
 * fileUploadSelectFile: string
 * fileTooLargeError: string
 * }}
 */
export function useApplicationFormLabels() {
  return useStaticQuery(graphql`
    query ApplicationFormLabels {
      labels: strapiApplicationForm {
        stepOfSteps
        continueButton
        backButton
        sendingApplication
        errorWhileSendingApplication
        thankYouHeading
        thankYouDescription
        addMoreLinks
        fieldIsRequired
        textInputDefaultPlaceholder
        fileUploadSelectFile
        fileTooLargeError
      }
    }
  `).labels;
}

