import { gsap as _gsap } from 'gsap';
import _ScrollTrigger from 'gsap/ScrollTrigger';
import _ScrollToPlugin from 'gsap/ScrollToPlugin';

export const gsap = _gsap;
export const ScrollTrigger = _ScrollTrigger;
export const ScrollToPlugin = _ScrollToPlugin;

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
}
