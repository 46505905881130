import axios from 'axios';

const captcha_key = process.env.CAPTCHA_KEY;

export const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_API_URL
});

const handleError = request => {
  if (request.config.parseResponseMessageOnError === false) {
    return Promise.reject(request);
  }
  const responseMessage = request?.response?.data?.message;
  let parsedError = responseMessage || 'Request error';
  return Promise.reject(parsedError);
};

const getCaptcha = async () => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(captcha_key)
        .then(function (token) {
          resolve(token);
        })
        .catch(ex => reject(ex));
    });
  });
};

axiosInstance.interceptors.request.use(async request => {
  request.headers.captcha_bypass =
    'fostNTmV0tZpMi0yaFRjFOLdQoxR6vZheZ_55KVDBLnp3ruCiQ2jOKesIf2eBpmbQv-tOskvYav-WMdI1nHTVQ';
  return request;
}, handleError);

axiosInstance.interceptors.response.use(response => response, handleError);
