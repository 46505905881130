import React from 'react';

import { ACTION_SLUGS } from '_constants';

import { Page } from 'components/_shared/Page';
import { Section } from 'components/_shared/Section';
import { Markdown } from 'components/_shared/Markdown';
import { Action, ActionList } from 'components/_shared/Action';

import { useStrapiActions, useStrapiCircleResources } from 'hooks';

import './BetaPageCircle.styles.scss';

export const BetaPageCircle = props => {
  const { page } = props;

  const { getActionBySlug } = useStrapiActions();
  const { resources } = useStrapiCircleResources();

  const actions = getActionBySlug([
    ACTION_SLUGS.BETA_EXPLORE,
    ACTION_SLUGS.MY_ACCOUNT_LINK
  ]);

  const renderResources = () => {
    if (!resources?.length) return null;

    return (
      <Section heading={page.resources_title} className='resources'>
        <ul className='resources-list'>
          {resources.map(resource => (
            <li key={resource.id} className='resources-list-item'>
              {resource.title && (
                <h4 className='resource-title'>{resource.title}</h4>
              )}
              <div className='resource-content'>
                {resource.content && (
                  <Markdown
                    className='resource-content'
                    variant='secondary'
                    markdown={resource.content?.data?.content}
                  />
                )}

                {resource.action && (
                  <Action
                    {...resource.action}
                    className='resource-action'
                    buttonProps={{ variant: 'quaternary' }}
                    linkProps={{ variant: 'primary' }}
                  />
                )}
              </div>
            </li>
          ))}
        </ul>
      </Section>
    );
  };

  return (
    <Page className='beta-circle' template='v2' meta={page.seo}>
      <Section
        className='intro'
        variant='wide-split'
        heading={page.beta_waitlist_intro.title}
        subheading={page.beta_waitlist_intro.supheading.data.supheading}
      >
        <Markdown
          className='message'
          variant='secondary'
          markdown={page.beta_waitlist_intro.subheading.data.subheading}
        />

        {!!actions?.length && (
          <ActionList
            className='actions'
            actions={actions}
            actionProps={{
              linkProps: { variant: 'primary' },
              buttonProps: { variant: 'quaternary' }
            }}
          />
        )}

        {renderResources()}
      </Section>
    </Page>
  );
};
