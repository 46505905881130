import { axiosInstance } from '../instance';
import { userEndpoints } from './user.endpoints';

const getCurrentUser = config => {
  return axiosInstance.get(userEndpoints.root, config);
};

const updateCurrentUser = body => {
  return axiosInstance.put(userEndpoints.root, body);
};

const submitTShirt = body => {
  return axiosInstance.post(userEndpoints.submitTShirt, body);
};

const applyFellowship = body => {
  return axiosInstance.post(userEndpoints.applyFellowship, body);
};

const getSessionToken = () => {
  return axiosInstance.get(userEndpoints.sessionTokenExchange);
}

const getBetaResponse = (responseId) => {
  return axiosInstance.get(userEndpoints.betaAllowed + responseId);
}

export const userRequests = {
  getCurrentUser,
  updateCurrentUser,
  submitTShirt,
  applyFellowship,
  getSessionToken,
  getBetaResponse,
};
