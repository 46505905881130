import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import * as Yup from 'yup';

import { Modal } from 'components/_shared/Modal';
import { Form, FormField, FormGroup } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';

import { selectUser, useUserActions } from 'state';
import { mergeObjects } from 'utils';

import './UserFellowshipModal.styles.scss';

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required().label('First name'),
  lastname: Yup.string().required().label('Last name'),
  devrev_fellowship_university: Yup.string().required().label('University')
});

export const UserFellowshipModal = ({ className, onSubmit, ...props }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const user = useSelector(selectUser);
  const { isLoading, applyFellowship } = useUserActions();
  const _className = cn('user-devrev-fellowship', className);

  const _initialValues = mergeObjects({
    firstname: user.firstname || '',
    lastname: user.lastname || '',
    devrev_fellowship_university: '',
    devrev_fellowship_major: '',
    devrev_fellowship_gpa: '',
    devrev_fellowship_why: ''
  });

  const handleFormSubmit = async values => {
    try {
      await applyFellowship(values);
      setIsSubmitted(true);
      onSubmit?.(values);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      {...props}
      className={_className}
      classes={{ children: 'self-stretch' }}
    >
      <div className='flex flex-col lg:flex-row h-full'>
        <div className='md:mr-6 lg:w-80 lg:mr-12 flex-shrink-0'>
          <h5 className='font-title text-lg'>DevRev Circle Fellowship</h5>
          <h2 className='font-title text-3xl md:text-4xl lg:text-5xl mt-4'>
            Tell us about yourself.
          </h2>
        </div>

        {!isSubmitted ? (
          <Form
            initialValues={_initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            className='w-full mt-12 lg:mt-0 flex-1'
            isLoading={isLoading}
          >
            <FormGroup direction='row'>
              <FormField
                name='firstname'
                label='First Name'
                placeholder='Type here...'
              />
              <FormField
                name='lastname'
                label='Last Name'
                placeholder='Type here...'
              />
            </FormGroup>
            <FormGroup>
              <FormField
                name='devrev_fellowship_university'
                label='University'
                placeholder='Type here...'
              />
            </FormGroup>
            <FormGroup direction='row'>
              <FormField
                name='devrev_fellowship_major'
                label='Major'
                placeholder='Type here...'
              />
              <FormField
                name='devrev_fellowship_gpa'
                type='number'
                label='GPA'
                min={0}
                max={5}
                step={0.01}
                placeholder='0'
              />
            </FormGroup>
            <FormGroup>
              <FormField
                type='textarea'
                name='devrev_fellowship_why'
                label='Why are you interested in Circle fellowship ?'
                placeholder='Type here...'
              />
            </FormGroup>

            <Button
              type='submit'
              className='block mt-auto ml-auto'
              variant='primary'
              disabled={isLoading}
            >
              Continue →
            </Button>
          </Form>
        ) : (
          <div className='flex text-center items-center justify-center h-full text-xl'>
            <p>
              Thank you for applying.
              <br />
              We have received your application and will be in touch shortly.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};
