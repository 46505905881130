import React from 'react';
import cn from 'classnames';
import isNumber from 'lodash-es/isNumber';

import './InputNumber.styles.scss';

export const InputNumber = ({
  className,
  variant,
  min,
  max,
  hasError = false,
  onChange,
  ...props
}) => {
  const _className = cn('input-number', variant, className, {
    hasError,
    [`
      disabled:text-gray-300
      disabled:cursor-not-allowed
      peer
      placeholder-transparent
      focus:ring-0
      block
      w-full
      text-lg
      md:text-xl
      lg:text-2xl
      border-0
      devrev-input
      px-0
      pt-0
      pb-0
      lg:pb-2 outline-none focus:outline-none`]: variant === 'secondary'
  });

  const handleChange = event => {
    const value = parseFloat(event.target.value.replaceAll('[^\\d.]', ''));

    event.target.value = value;

    if (isNumber(min) && value < min) {
      event.target.value = min;
    }

    if (isNumber(max) && value > max) {
      event.target.value = max;
    }

    onChange?.(event);
  };

  return (
    <input
      {...props}
      type='number'
      className={_className}
      placeholder={variant === 'secondary' ? 'placeholder' : props.placeholder}
      onChange={handleChange}
    />
  );
};
