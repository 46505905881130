export function trackEvent(event, data={}) {
    return window?.analytics?.track(event, data)
}

//Read the userId from either cookies, or localstorage, and then send it.  If not found segment will use the anonymous Id
export function identifyUser(traits={}) {
    let userId = getUserId()
    window?.analytics?.identify(userId, traits)
}

export function identifyUserByEmail(email, traits={}) {
    window?.analytics?.identify(email, traits)
}

//Function to identify the user once segment is ready
export function onReady(userEmail) {
    if (userEmail && userEmail !== 'null') {
        identifyUserByEmail(userEmail)
    } else if (!parseCookie(document.cookie).ajs_user_id && (window.localStorage.ajs_user_id || window.localStorage.ajs_user_traits)) {
        identifyUser()
    }
}

const getUserId = () => {
    let userId = parseCookie(document?.cookie).ajs_user_id || window?.localStorage?.ajs_user_id
    if ((!userId || userId === 'null') && window?.localStorage?.ajs_user_traits) {
        try {
            userId = JSON.parse(window.localStorage.ajs_user_traits).email
        }
        catch {}
    }
    return userId
}

const parseCookie = str =>
  str
  .split(';')
  .map(v => v.split('='))
  .reduce((acc, v) => {
    acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
    return acc;
  }, {});
