import React, { useMemo } from 'react';
import cn from 'classnames';

import { List } from '../List';
import { TextContent } from 'components/_shared/TextContent';

import './ListPerks.styles.scss';

export const ListPerks = ({
  className,
  items = [],
  contentMaxLength = null,
  contentMaxRows = 3,
  headingElement: ItemHeading = 'h4',
  withCollapseContentButton = false,
  ...props
}) => {
  const _className = cn('list-perks', className);

  const renderItems = useMemo(() => {
    return items.map(item => {
      const canRenderHeader = !!item.heading;

      return (
        <>
          <div className='list-item-body'>
            {canRenderHeader && (
              <div className='list-item-header'>
                {item.heading && (
                  <ItemHeading className='list-item-heading'>
                    {item.heading}
                  </ItemHeading>
                )}
              </div>
            )}

            {item.content && (
              <TextContent
                className='list-item-content'
                maxLength={contentMaxLength}
                maxRows={contentMaxRows}
                withCollapseButton={withCollapseContentButton}
              >
                {item.content}
              </TextContent>
            )}
          </div>

          {item.meta && <div className='list-item-meta'>{item.meta}</div>}
        </>
      );
    });
  }, [items, withCollapseContentButton, contentMaxLength, contentMaxRows]);

  return <List {...props} className={_className} items={renderItems} />;
};
