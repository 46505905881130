import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { HackathonSubmissionsMessage } from './Message';
import { HackathonSubmissionsForm } from './Form';

import { selectUserHackathonSubmission, useUserActions } from 'state';

import { graphql, useStaticQuery } from 'gatsby';
import { parseMarkdown } from 'utils';

const query = graphql`
  query HackathonSubmissionsPageQuery {
    labels: strapiHack0SubmissionsPage {
      submission_received_message {
        data {
          submission_received_message
        }
      }
      form_githubLink_label
      form_presentationLink_label
      form_videoLink_label
      form_submit_text
      form_save_text
      form_error_invalidGithub
      form_error_invalidUrl
      form_error_required
      form_link_placeholder
    }
  }
`;

export const HackathonSubmissions = ({ className, hackathonId }) => {
  const { isLoading, submitHackathon } = useUserActions();
  const { labels } = useStaticQuery(query);

  const userSubmission = useSelector(selectUserHackathonSubmission);
  const hasSubmission = Object.values(userSubmission).some(Boolean);

  const _className = cn('hackathon-submissions', className);

  const handleSubmitClick = async values => {
    await submitHackathon({ hackathonId, ...values });
  };

  return (
    <div className={_className}>
      {hasSubmission && (
        <HackathonSubmissionsMessage
          message={parseMarkdown(labels.submission_received_message.data.submission_received_message)}
        />
      )}

      <HackathonSubmissionsForm
        isLoading={isLoading}
        initialValues={userSubmission}
        submitButtonText={
          hasSubmission ? labels.form_save_text : labels.form_submit_text
        }
        labels={labels}
        onSubmit={handleSubmitClick}
      />
    </div>
  );
};
