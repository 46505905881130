import React, {
  Children,
  cloneElement,
  isValidElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import cn from 'classnames';
import slugify from 'slugify';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { Button } from '../Button';

import './Tabs.styles.scss';

const slugifyTab = tabKey => slugify(tabKey).toLowerCase();

export const Tabs = memo(
  ({
    className,
    activeTabKey,
    activeTabLabel,
    disabledTabKeys = [],
    initialTabKeyIndex = 0,
    tabs = [],
    tabsMenuChildren = null,
    urlParamName = 'tab',
    onChange
  }) => {
    const { search: params, pathname } = useLocation();
    const paramTabSlug = new URLSearchParams(params).get(urlParamName);

    const tabKeys = useMemo(() => Object.keys(tabs), [tabs]);
    const firstTabKey = tabKeys[initialTabKeyIndex];

    const isDisabled = useCallback(
      tabKey => disabledTabKeys?.includes?.(tabKey),
      [disabledTabKeys]
    );

    const switchTab = useCallback(
      (key, replace = false) => {
        navigate(`${pathname}?${urlParamName}=${slugifyTab(key)}`, { replace });
      },
      [pathname, urlParamName]
    );

    const getActiveTabKey = useCallback(() => {
      if (!paramTabSlug) return activeTabKey || firstTabKey;

      const tabKeyIndexByParamSlug = tabKeys.findIndex(
        key => slugifyTab(key) === paramTabSlug
      );

      const tabKeyByParamSlug = tabKeys[tabKeyIndexByParamSlug];

      if (isDisabled(tabKeyByParamSlug)) return switchTab(firstTabKey, true);

      return tabKeyByParamSlug;
    }, [
      paramTabSlug,
      activeTabKey,
      firstTabKey,
      tabKeys,
      isDisabled,
      switchTab
    ]);

    const [_activeTabKey, setActiveTabKey] = useState(getActiveTabKey);
    useEffect(() => setActiveTabKey(getActiveTabKey()), [getActiveTabKey]);

    const _className = cn(
      'tabs',
      slugifyTab(_activeTabKey || ''),
      tabs[_activeTabKey]?.className,
      className
    );

    const handleMenuButtonClick = useCallback(
      key => switchTab(key),
      [switchTab]
    );

    useEffect(() => onChange?.(_activeTabKey), [_activeTabKey, onChange]);

    const renderTabsMenuChildren = Children.map(tabsMenuChildren, child => {
      if (!child) return null;
      return cloneElement(child, {
        className: cn('tabs-menu-button', child?.props?.className)
      });
    });

    const renderMenu = () => {
      return (
        <div className='tabs-menu'>
          {Object.keys(tabs).map(key => {
            const isActive = _activeTabKey === key;
            const _isDisabled = isDisabled(key);

            return (
              <Button
                key={key}
                className={cn(
                  'tabs-menu-button',
                  { isDisabled: _isDisabled },
                  { isActive }
                )}
                onClick={() => handleMenuButtonClick(key)}
                disabled={_isDisabled}
              >
                {(isActive && activeTabLabel?.(key)) || key}
              </Button>
            );
          })}
          {renderTabsMenuChildren}
        </div>
      );
    };

    const renderTab = () => {
      let activeTab = tabs[_activeTabKey]?.render;

      if (!isValidElement(activeTab)) {
        activeTab = tabs[_activeTabKey];
      }

      if (!activeTab) return null;

      const _isDisabled = isDisabled(_activeTabKey);

      return cloneElement(activeTab, {
        className: cn(
          'tabs-tab',
          { isDisabled: _isDisabled },
          activeTab.props.className
        )
      });
    };

    return (
      <div className={_className}>
        {renderMenu()}
        {renderTab()}
      </div>
    );
  }
);
