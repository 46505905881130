import React from 'react';
import cn from 'classnames';

import { withFunction } from 'utils';

import './Section.styles.scss';

export const Section = ({
  className,
  children,
  heading,
  subheading,
  supheading,
  icon,
  headerChildren,
  variant,
  color
}) => {
  const _className = cn('section', variant, color, className);

  const renderIcon = () => {
    let _icon = icon;

    if (!_icon) return null;

    if (typeof _icon === 'string') {
      _icon = <img src={icon} alt={heading} />;
    }

    return <span className='section-icon'>{_icon}</span>;
  };
  let renderSupheading = supheading?.data?.supheading || supheading
  let renderSubheading = subheading?.data?.subheading || subheading

  return (
    <section className={_className}>
      <header className='section-header'>
        {renderIcon()}

        {supheading && (
          <p className='section-supheading'>{withFunction(renderSupheading)}</p>
        )}

        {heading && (
          <h2 className='section-heading'>{withFunction(heading)}</h2>
        )}

        {subheading && (
          <p className='section-subheading'>{withFunction(renderSubheading)}</p>
        )}
        {headerChildren}
      </header>
      {children && <div className='section-children'>{children}</div>}
    </section>
  );
};
