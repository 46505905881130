import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import { ROUTES, WEBSITE_TITLE } from '_constants';

import { mergeObjects } from 'utils';

export const SEO = props => {
  const { pathname } = useLocation();

  const {
    site: { siteMetadata }
  } = useStaticQuery(query);

  const getTitleTemplate = title => {
    if (pathname.match(ROUTES.ROOT) && title.includes(WEBSITE_TITLE)) {
      return null;
    }

    return siteMetadata.titleTemplate;
  };

  const meta = mergeObjects({}, [
    siteMetadata,
    props,
    ({ title }) => ({ titleTemplate: getTitleTemplate(title) }),
    { keywords: props.keywords?.replace(/,[\s]*/g, ', ') || '' }
  ]);

  return (
    <Helmet title={meta.title} titleTemplate={meta.titleTemplate}>
      {meta.description && (
        <meta name='description' content={meta.description} />
      )}

      {meta.image && <meta name='image' content={meta.image} />}

      {meta.url && <meta property='og:url' content={meta.url} />}
      {!!props.article && <meta property='og:type' content='article' />}
      {meta.title && <meta property='og:title' content={meta.title} />}
      {meta.image && <meta property='og:image' content={meta.image} />}
      {meta.description && (
        <meta property='og:description' content={meta.description} />
      )}

      <meta name='twitter:card' content='summary_large_image' />
      {meta.title && <meta name='twitter:title' content={meta.title} />}
      {meta.image && <meta name='twitter:image' content={meta.image} />}
      {meta.description && (
        <meta name='twitter:description' content={meta.description} />
      )}

      {meta.keywords && <meta name='keywords' content={meta.keywords} />}
    </Helmet>
  );
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
        titleTemplate
        description
        image
      }
    }
  }
`;
