import { createSelector, createSlice } from '@reduxjs/toolkit';

import { BETA_STATUS, CIRCLE_STATUS } from '_constants';

import { mergeObjects, getCookieLaw, setCookieLaw } from 'utils';

const USER_DEFAULT_STATE = {
  applications: [],
  devrev_event_ids: [],
  isApplicationsLoaded: false,
  circleStatus: {
    isApplied: false,
    isApproved: false,
    isRejected: false,
    isInReview: false
  },
  cookie: {
    isChecked: false,
    isAllowed: false
  },
  session_token: '',
  devrev_circle_member: CIRCLE_STATUS.NOT_APPLIED,
  devrev_linkedin: '',
  devrev_research_and_beta_opt_in: null,
  hackathonRegistration0: false,
  hackathonSubmission0: {
    projectGithubUrl: '',
    projectPresentationUrl: '',
    projectVideoUrl: ''
  },
  beta_user: BETA_STATUS.NOT_APPLIED
};

const USER_INITIAL_STATE = mergeObjects(USER_DEFAULT_STATE, [
  { cookie: getCookieLaw() }
]);

const userSlice = createSlice({
  name: 'user',
  initialState: USER_INITIAL_STATE,
  reducers: {
    setEntity: (state, action) => {
      const { devrev_circle_member: circleStatus } = action.payload;

      return mergeObjects(state, [
        action.payload,
        !('beta_user' in action.payload) && {
          beta_user: BETA_STATUS.NOT_APPLIED
        },
        'devrev_circle_member' in action.payload && {
          circleStatus: {
            isApplied: circleStatus === CIRCLE_STATUS.APPLIED,
            isApproved:
              circleStatus === CIRCLE_STATUS.APPROVED ||
              circleStatus === CIRCLE_STATUS.OUTER_CIRCLE,
            isRejected: circleStatus === CIRCLE_STATUS.REJECTED,
            isInReview: circleStatus && circleStatus.match(/applied|rejected/)
          }
        }
      ]);
    },

    setApplications: (state, action) => {
      state.applications = action.payload;
      state.isApplicationsLoaded = true;
    },

    addEvent: (state, action) => {
      state.devrev_event_ids = [
        ...(state.devrev_event_ids || []),
        action.payload
      ];
    },

    setCookie: (state, action) => {
      const mergedState = mergeObjects(state, { cookie: action.payload });
      setCookieLaw(mergedState.cookie);
      return mergedState;
    },

    setIsHackathonRegistered: (state, action) => {
      state.hackathonRegistration0 = action.payload;
    },

    setHackathonSubmission: (state, action) => {
      state.hackathonSubmission0 = action.payload;
    },

    submitBetaInterest: state => {
      state.beta_user = BETA_STATUS.INTERESTED;
    }
  }
});

export const { reducer: userReducer, actions: userActions } = userSlice;

export const selectUser = state => state.user;

export const selectUserApplications = createSelector(
  selectUser,
  ({ applications }) => applications
);

export const selectUserCircleStatus = createSelector(
  selectUser,
  ({ devrev_circle_member }) => devrev_circle_member
);

export const selectUserIsApplicationsLoaded = createSelector(
  selectUser,
  ({ isApplicationsLoaded }) => isApplicationsLoaded
);

export const selectUserCookie = createSelector(
  selectUser,
  ({ cookie }) => cookie
);

export const selectUserIsHackathonRegistered = createSelector(
  selectUser,
  ({ hackathonRegistration0 }) => hackathonRegistration0
);

export const selectUserHackathonSubmission = createSelector(
  selectUser,
  ({ hackathonSubmission0 }) => hackathonSubmission0
);

export const selectUserCircleStatuses = createSelector(
  selectUser,
  ({ circleStatus }) => circleStatus
);

export const selectUserBetaStatus = createSelector(
  selectUser,
  ({ beta_user }) => beta_user
);

export const selectUserLinkedin = createSelector(
  selectUser,
  ({ devrev_linkedin }) => devrev_linkedin
);

export const selectUserEmail = createSelector(selectUser, ({ email }) => email);

export const selectSessionToken = createSelector(
  selectUser,
  ({ session_token }) => session_token
);
