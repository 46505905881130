import { useCallback, useEffect, useRef, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { gsap } from 'utils';

export function useHeaderAnimation(headerRef, scroller) {
  const { medium } = useBreakpoint();

  const lastProgressRef = useRef(0);
  const lastDirectionRef = useRef(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const animation = gsap.to(headerRef.current, {
      scrollTrigger: {
        scroller: scroller?.(headerRef.current),
        onUpdate: ({ direction, progress }) => {
          lastProgressRef.current = progress;
          if (progress === 0) return setIsVisible(true);
          if (lastDirectionRef.current === direction) return;
          lastDirectionRef.current = direction;
          setIsVisible(direction < 0);
        }
      }
    });

    return () => {
      if (!animation) return;
      lastDirectionRef.current = 0;
      animation.scrollTrigger.kill();
      animation.kill();
    };
  }, [scroller, headerRef]);

  useEffect(() => {
    // * Enable scrolling logic for pages without default scroll with overlay (e.g. Main.jsx)
    if (!scroller || !headerRef) return;

    const _scroller = scroller(headerRef.current);
    const overlay = headerRef.current.querySelector('.header-menu-overlay');

    if (!overlay) return;

    let wheelTimeout = false;

    const handleWheelStart = () => {
      overlay.style.pointerEvents = 'none';
    };

    const handleWheelEnd = () => {
      overlay.style.pointerEvents = 'initial';
    };

    const wheelListener = () => {
      handleWheelStart();
      if (wheelTimeout) clearTimeout(wheelTimeout);
      wheelTimeout = setTimeout(handleWheelEnd, 200);
    };

    const clearEffect = () => {
      clearTimeout(wheelListener);
      _scroller.removeEventListener('wheel', wheelListener);
    };

    if (!medium) {
      clearEffect();
    } else {
      _scroller.addEventListener('wheel', wheelListener);
    }

    return clearEffect;
  }, [scroller, medium, headerRef]);

  const getProgress = useCallback(() => lastProgressRef.current, []);

  return { isVisible, setIsVisible, getProgress };
}
