import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectUser, selectUserCookie } from 'state';

import { identifyUser, identifyUserByEmail } from '../../utils/segment';

export const CookieController = () => {
  const user = useSelector(selectUser);
  const { isAllowed } = useSelector(selectUserCookie);

  useEffect(() => {
    if (!window?.analytics || !isAllowed) return;

    const wasLoggedOut = !user.email && !!Cookies.get('ajs_user_id');
    wasLoggedOut && window.analytics.reset();

    window.analytics.load?.(window.analytics._writeKey);
    if(user.email) {
      identifyUserByEmail(user.email)
    } else {
      identifyUser()
    }
  }, [isAllowed, user.email]);

  return null;
};
