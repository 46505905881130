import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import cn from 'classnames';
import isUndefined from 'lodash-es/isUndefined';

import { IconCross } from 'assets';

import { Button } from '../Button';
import { Overlay } from '../Overlay';

import { UseClickOutsideConditional } from 'hooks';

import { useModalAnimation } from './Modal.animation';
import './Modal.styles.scss';

export const Modal = forwardRef(
  (
    {
      className,
      children,
      clickOutsideParams,
      isOpen,
      closeOnOutsideClick = true,
      isPersisted = false,
      onClose,
      onOutsideClick,
      classes = {},
      ...props
    },
    ref
  ) => {
    const rootRef = useRef(null);
    const modalRef = useRef(null);
    const overlayRef = useRef(null);

    const [_isOpen, setIsOpen] = useState(isOpen);
    useEffect(() => !isUndefined(isOpen) && setIsOpen(isOpen), [isOpen]);

    const handleClose = () => {
      setIsOpen(false);
      onClose?.();
    };

    const [closeWithAnimation] = useModalAnimation(rootRef, overlayRef, {
      isOpen: _isOpen,
      onComplete: handleClose
    });

    const handleOutsideClick = () => {
      if (isPersisted) return;
      closeOnOutsideClick && closeWithAnimation();
      onOutsideClick?.();
    };

    const toggle = isOpen => {
      const shouldClose = (!isUndefined(isOpen) && !isOpen) || _isOpen;
      shouldClose ? closeWithAnimation() : setIsOpen(true);
    };

    useImperativeHandle(ref, () => ({
      element: rootRef.current,
      modal: modalRef.current,
      toggle
    }));

    useEffect(() => {
      document.body.style.overflow = _isOpen ? 'hidden' : 'auto';

      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [_isOpen]);

    if (!_isOpen) return null;

    return (
      <>
        <UseClickOutsideConditional
          elementRef={rootRef}
          callback={handleOutsideClick}
          params={clickOutsideParams}
        />

        <Overlay ref={overlayRef} className='modal-overlay' />

        <div
          {...props}
          ref={modalRef}
          className={cn(
            'modal w-full h-screen overflow-auto',
            { isOpen: _isOpen },
            className
          )}
        >
          <div className='modal-paper md:p-6 min-h-screen flex items-stretch'>
            <div
              ref={rootRef}
              className={cn(
                'bg-white p-6 md:p-16 w-full flex items-center modal-children-wrapper',
                classes?.root
              )}
            >
              {!isPersisted && (
                <Button
                  className='absolute modal-button-close top-6 right-6 md:top-10 md:right-10 outline-none focus:outline-none'
                  icon={<IconCross />}
                  onClick={closeWithAnimation}
                />
              )}
              {children && (
                <div className={cn('modal-children w-full', classes?.children)}>
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
);
