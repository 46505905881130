import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';

import { Loader } from './Loader';

export const query = graphql`
  query RedirectsQuery {
    strapiRedirect {
      redirects {
        strapi_json_value {
          fromPath
          toPath
        }
      }
    }
  }
`;

export const RedirectsController = ({ children, location, isSSR }) => {
  const {
    strapiRedirect: { redirects }
  } = useStaticQuery(query);

  const matchedRedirect = redirects?.strapi_json_value?.find(({ fromPath }) => {
    return location?.pathname.replace(/\/$/, '') === fromPath;
  });

  if (matchedRedirect?.toPath) {
    !isSSR && navigate(matchedRedirect.toPath, { replace: true });

    return (
      <div>
        <Loader isOpen />
      </div>
    );
  }

  return children;
};
