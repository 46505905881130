import React from 'react';
import cn from 'classnames';

import { SEO as Seo } from 'components/SEO';
import { PageTemplateDefault, PageTemplateV2 } from './Template';

const TEMPLATE_COMPONENTS = {
  default: PageTemplateDefault,
  v2: PageTemplateV2
};

export const Page = React.forwardRef(
  (
    { className, meta = {}, template = 'default', isDark = false, ...props },
    ref
  ) => {
    const _className = cn('page', template, { isDark }, className);
    const Template = TEMPLATE_COMPONENTS[template] || null;

    return (
      <>
        <Seo {...meta} />
        <Template {...props} ref={ref} className={_className} isDark={isDark} />
      </>
    );
  }
);
