import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { ROUTES } from '_constants';

import { Loader } from 'components/Loader';

import { useStrapiActions } from 'hooks';

import './BetaPageApply.styles.scss';

export const BetaPageApply = () => {
  const action = useStrapiActions().getActionBySlug('open-beta-sign-up');

  useEffect(() => {
    navigate(action.link || ROUTES.ROOT, { replace: true });
  }, [action.link]);

  return <Loader isOpen />;
};
