const key = email => `temp_application_${email}`;

const TEMP_APPLICATIONS_TIMEOUT_MILLISECONDS = 1000 * 60 * 30; // 30 minutes

function cleanupTempApplications(email) {
  // Remove applications from any possible previous user
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key.startsWith('temp_application') && !key.endsWith(email)) {
      window.localStorage.removeItem(key);
    }
  }
  // Remove applications that are older than 30 minutes
  const value = window.localStorage.getItem(key(email)) || '[]';
  const applications = JSON.parse(value);
  const validApplications = applications.filter(application => {
    const diff = Date.now() - parseInt(application.id);
    return diff < TEMP_APPLICATIONS_TIMEOUT_MILLISECONDS;
  });
  if (applications.length !== validApplications.length) {
    window.localStorage.setItem(key(email), JSON.stringify(validApplications));
  }
}

export function getTempApplications(email) {
  if (!email) return [];
  cleanupTempApplications(email);
  const value = window.localStorage.getItem(key(email));
  return value ? JSON.parse(value) : [];
}

export function addTempApplication(email, position) {
  const application = {
    key: Date.now() + '',
    status: 'processing',
    job_title: position.title,
    job_location: position?.location?.name || '',
    job_post_id: position.initialId,
    id: Date.now() + ''
  };
  const applications = getTempApplications(email);
  applications.push(application);
  window.localStorage.setItem(key(email), JSON.stringify(applications));
  return applications;
}

export function removeTempApplication(email, application) {
  const applications = getTempApplications(email).filter(
    a => a.key !== application.key
  );

  window.localStorage.setItem(key(email), JSON.stringify(applications));
}
