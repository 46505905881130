import React from 'react';
import cn from 'classnames';

import { IconClose } from 'assets';

import './HeaderMenuButton.styles.scss';

export const HeaderMenuButton = ({
  className,
  title,
  subtitle,
  color = 'greyShade-5',
  isMenuOpen = false,
  ...props
}) => {
  const _className = cn(
    'header-menu-button',
    `color-${color}`,
    { isMenuOpen },
    className
  );

  return (
    <button {...props} className={_className}>
      <span className='header-menu-button-icon'>
        <IconClose />
      </span>

      {title && <span className='header-menu-button-title'>{title}</span>}

      {subtitle && (
        <span className='header-menu-button-subtitle'>{subtitle}</span>
      )}
    </button>
  );
};
