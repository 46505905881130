import React, { useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';

import { ROUTES } from '_constants';

import { AuthUserController } from './AuthUserController';

export const AuthProvider = ({ children, location, isSSR }) => {
  const [withJoinModal, setWithJoinModal] = useState(true);

  const handleRedirectCallback = (appState = {}) => {
    const { returnTo = ROUTES.ROOT } = appState;
    const betaApplyPathRegExp = new RegExp(`^${ROUTES.BETA_FORM_APPLY}`, 'g');
    setWithJoinModal(!betaApplyPathRegExp.test(returnTo));
    navigate(returnTo, { replace: true });
  };

  return (
    <Auth0Provider
      clientId={process.env.GATSBY_AUTH0_CONFIG_CLIENT_ID}
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      organization={process.env.GATSBY_AUTH0_CONFIG_ORG_ID}
      audience={process.env.GATSBY_AUTH0_CONFIG_AUDIENCE}
      returnTo={location.origin}
      redirectUri={
        !isSSR &&
        new URL(process.env.GATSBY_AUTH0_CONFIG_REDIRECT_URI, location.origin)
          .href
      }
      onRedirectCallback={handleRedirectCallback}
    >
      <AuthUserController withJoinModal={withJoinModal}>
        {children}
      </AuthUserController>
    </Auth0Provider>
  );
};
