import React, { useRef, useEffect, useState, useCallback } from 'react';
import { navigate } from 'gatsby';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { assign } from 'lodash';

import { Modal } from 'components/_shared/Modal';
import { Form, FormField, FormGroup } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';
import { ROUTES } from '../../../../_constants';

import { selectUser } from 'state';
import { mergeObjects, mergeProps, parseMarkdown, tr } from 'utils';

import './ApplicationFormModal.styles.scss';
import {
  useApplicationFormLabels,
  useFormSteps,
  useApplyForJob
} from '../hooks';
import { useDefaultInitialValues } from '../hooks/use-default-initial-values';
import { graphql, useStaticQuery } from 'gatsby';
import { ModalDevRevAnimation } from 'components/_shared/ModalDevRevAnimation/ModalDevRevAnimation';

const ThankYouForApplication = ({ labels }) => {
  return (
    <div className='flex'>
      <div className='hidden lg:block lg:w-1/2 relative'>
        <div className='absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 -ml-16'>
          <ModalDevRevAnimation />
        </div>
      </div>
      <div className='w-full lg:w-1/2'>
        <h3 className='text-3xl md:text-4xl lg:text-5xl mt-2 font-normal mb-6'>
          {labels.thankYouHeading}
        </h3>
        {parseMarkdown(labels.thankYouDescription)}
      </div>
    </div>
  );
};

const GroupItem = ({ item, ...props }) => {
  const { textInputDefaultPlaceholder } = useApplicationFormLabels();
  if (item.component) {
    return React.createElement(
      item.component.type,
      mergeProps(item.component.props, props)
    );
  } else if (item.field) {
    if (
      !item.field.placeholder &&
      (item.field.type === 'text' || item.field.type === 'textarea')
    ) {
      props.placeholder = textInputDefaultPlaceholder;
    }
    // Ignore required as we are checking the fields using Yup
    const { required, ...fieldProps } = item.field;
    return <FormField {...mergeProps(fieldProps, props)} />;
  }
  return <></>;
};

const ApplicationFormSection = ({ section }) => {
  return (
    <>
      <div className='text-base mb-6'>{section.label}</div>
      {section.rows.map((row, index) => (
        <FormGroup key={index} direction={row.length > 1 ? 'row' : 'col'}>
          {row.map((rowItem, index) => (
            <GroupItem key={index} item={rowItem} />
          ))}
        </FormGroup>
      ))}
    </>
  );
};

const query = graphql`
  query ApplicationFormModalQuery {
    config: strapiApplicationForm {
      maxUploadFileSizeInMB
    }
  }
`;

export const ApplicationFormModal = ({
  className,
  onSubmit,
  position,
  applicationForm,
  ...props
}) => {
  const labels = useApplicationFormLabels();
  const modalRef = useRef(null);
  const scrollToTop = useCallback(() => {
    if (modalRef?.current?.modal) {
      modalRef.current.modal.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);
  const { config } = useStaticQuery(query);
  const user = useSelector(selectUser);
  const [currentStep, setCurrentStep] = useState(0);
  const { totalSteps, steps } = useFormSteps(position, applicationForm, config);
  const {
    isDone,
    applyForJob,
    error: applyError,
    isLoading: applyLoading
  } = useApplyForJob(applicationForm);

  const nextStep = useCallback(
    () => setCurrentStep(step => Math.min(totalSteps - 1, step + 1)),
    [totalSteps]
  );
  const prevStep = useCallback(
    () => setCurrentStep(step => Math.max(step - 1, 0)),
    []
  );

  useEffect(() => {
    if (isDone) {
      navigate(ROUTES.USER_DEVREV_ACCOUNT(true));
    }
  }, [isDone]);

  const _className = cn('application-form-modal', className);

  const defaultInitialValues = useDefaultInitialValues(steps);

  defaultInitialValues[0] = mergeObjects(defaultInitialValues[0], {
    email: user.email,
    first_name: user.firstname || '',
    last_name: user.lastname || ''
  });

  const [formData, setFormData] = useState({});

  return (
    <Modal
      ref={modalRef}
      {...props}
      className={_className}
      classes={assign(props?.classes || {}, {
        root: !isDone ? 'p-6 md:p-10' : '',
        children: !isDone ? 'self-stretch pt-10' : ''
      })}
    >
      {!isDone && (
        <div className='flex flex-col lg:flex-row h-full'>
          <div className='flex flex-col gap-4 md:mr-6 lg:w-80 lg:mr-12 flex-shrink-0'>
            <div className='md:text-sm lg:text-base'>
              {tr(labels.stepOfSteps, {
                currentStep: currentStep + 1,
                totalSteps
              })}
            </div>
            <h4 className='text-3xl md:text-4xl lg:text-5xl font-normal'>
              {steps[currentStep].label}
            </h4>
            <p className='md:text-base lg:text-lg'>{position.title}</p>
          </div>
          <div className='w-full mt-12 lg:mt-0 flex-1'>
            {steps.map((step, index) => (
              <Form
                key={index}
                initialValues={defaultInitialValues[index]}
                validationSchema={step.validationSchema}
                onSubmit={async data => {
                  setFormData(d => ({ ...d, ...data }));
                  if (index + 1 === totalSteps) {
                    await applyForJob(position, {
                      ...formData,
                      ...data
                    });
                  } else {
                    nextStep();
                    scrollToTop();
                  }
                }}
                className={cn('flex flex-col')}
                style={currentStep !== index ? { display: 'none' } : {}}
              >
                <div className='h-full flex-1 lg:pl-12'>
                  {step.sections.map((section, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index > 0 && <hr className='lg:-ml-12 my-12' />}
                        <ApplicationFormSection section={section} />
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className='flex-none flex items-center gap-12 w-full border-t-05 border-black-30 mt-12 pt-12 pl-12 pb-8'>
                  <Button
                    onClick={() => {
                      if (currentStep === 0) {
                        modalRef?.current?.toggle(false);
                      } else {
                        prevStep();
                        setTimeout(scrollToTop, 50);
                      }
                    }}
                  >
                    {labels.backButton}
                  </Button>
                  <div className='w-full flex-1' />
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={applyLoading}
                  >
                    {!applyLoading && labels.continueButton}
                    {applyLoading && labels.sendingApplication}
                  </Button>
                </div>
                {applyError && (
                  <div className='flex-none flex items-center justify-end gap-12 w-full -mt-3 pl-12'>
                    <p className='text-2xl text-red-800'>
                      {labels.errorWhileSendingApplication}
                    </p>
                  </div>
                )}
              </Form>
            ))}
          </div>
        </div>
      )}
      {isDone && <ThankYouForApplication labels={labels} />}
    </Modal>
  );
};
