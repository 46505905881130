import React from 'react';

import { RedirectsController } from './RedirectsController';
import { AuthProvider } from './Auth';
import { CookieController, CookiePanel } from './Cookie';
import { ModalController } from './ModalController';
import { Loader } from './Loader';

export const PageElement = ({ element, props: { location }, isSSR }) => {
  return (
    <RedirectsController location={location} isSSR={isSSR}>
      <AuthProvider location={location} isSSR={isSSR}>
        <CookieController />
        <ModalController />
        <CookiePanel />
        {element}
        <Loader isOpen={false} />
      </AuthProvider>
    </RedirectsController>
  );
};
