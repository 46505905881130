const isArray = require('lodash/isArray');
const isEmpty = require('lodash/isEmpty');
const isFunction = require('lodash/isFunction');
const mergeWith = require('lodash/mergeWith');

function mergeObjects(initialObject, source, customizer) {
  let _source = source;

  if (isArray(source)) {
    _source = source.reduce((initialObject, source) => {
      return !source
        ? initialObject
        : mergeObjects(initialObject, source, customizer);
    }, initialObject);
  }

  if (isFunction(source)) _source = source(initialObject);

  const defaultCustomizer = (value, sourceValue) => {
    if (isArray(value) && !isEmpty(sourceValue)) return sourceValue;
    if (isFunction(value) && sourceValue) return sourceValue;
  };

  return mergeWith({}, initialObject, _source, customizer || defaultCustomizer);
}

module.exports.mergeObjects = mergeObjects;
