const root = '/v1/events';

const registerToHackathon = `${root}/registerhackathon`;
const submitHackathon = `${root}/submithackathon`;

export const hackathonEndpoints = {
  root,
  registerToHackathon,
  submitHackathon
};
