import React, { useState } from 'react';
import { navigate, Link } from 'gatsby';
import cn from 'classnames';
import groupBy from 'lodash-es/groupBy';
import uniq from 'lodash-es/uniq';

import { ROUTES } from '_constants';

import { Button } from 'components/_shared/Button';

import { useApiPositions } from 'hooks';
import { useUserActions } from 'state';
import { useUserAccess } from 'state/hooks';

import './PositionList.styles.scss';

export const PositionList = ({ className, ...props }) => {
  const _className = cn('position-list', className);

  const { isAuthenticated } = useUserAccess();
  const { login } = useUserActions();

  const positions = useApiPositions();
  const locations = uniq(positions.map(({ location }) => location.name)).sort();
  const initialLocation = locations[0];

  const groupedPositions = Object.entries(
    groupBy(positions, position => position.location.name)
  ).reduce((normalized, group) => {
    let departments = groupBy(
      group[1],
      position => position.departments[0]?.name
    );

    departments = Object.keys(departments)
      .sort()
      .reduce((sortedDepartments, key) => {
        return { ...sortedDepartments, [key]: departments[key] };
      }, {});

    return { ...normalized, [group[0]]: departments };
  }, {});

  const [activeLocation, setActiveLocation] = useState(initialLocation);
  const [activePositions, setActivePositions] = useState(
    groupedPositions[initialLocation]
  );

  const handleLocationClick = location => {
    setActiveLocation(() => {
      setActivePositions(groupedPositions[location]);
      return location;
    });
  };

  const renderOnlyCityAndCountry = location => {
    const splitLocation = location.split(',');
    return `${splitLocation[0]}, ${splitLocation[splitLocation.length - 1]}`;
  };

  const formatLocationName = location => {
    const formats = {
      'United States': 'US'
    };

    return Object.entries(formats).reduce((formatedLocation, [key, value]) => {
      const locationKey = Object.keys(formats).find(key => {
        return formatedLocation.includes(key);
      });

      if (!locationKey) return formatedLocation;

      return formatedLocation.replace(key, value);
    }, location);
  };

  const handleCheckApplicationStatusClick = () => {
    if (isAuthenticated) {
      navigate(ROUTES.USER_DEVREV_ACCOUNT(true));
    } else {
      login(ROUTES.USER_DEVREV_ACCOUNT(true));
    }
  };

  const renderLocations = () => {
    if (!locations.length) return null;

    return (
      <section className='position-list-location'>
        <h3 className='position-list-location-title'>Select location</h3>
        <ul className='position-list-location-list'>
          {locations.map(location => (
            <li
              key={location}
              className={cn('position-list-location-item', {
                isActive: location === activeLocation
              })}
            >
              <Button
                className='position-list-location-button'
                onClick={() => handleLocationClick(location)}
              >
                {formatLocationName(renderOnlyCityAndCountry(location))}
              </Button>
            </li>
          ))}
        </ul>
      </section>
    );
  };

  const renderPositions = () => {
    if (!Object.keys(activePositions).length) return null;
    return Object.entries(activePositions).map(([department, positions]) => (
      <section key={department} className='position-list-group'>
        <h3 className='position-list-group-title'>{department}</h3>
        {!!positions.length && (
          <ul className='position-list-group-positions'>
            {positions.map(position => (
              <li key={position.initialId} className='position-list-item'>
                <Link
                  to={ROUTES.POSITION_BY_ID(position.initialId)}
                  className='position-list-item-link'
                >
                  {`${position.title}`}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </section>
    ));
  };

  return (
    <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 px-3 md:px-6 relative md:pt-12 md:flex-row'>
      <div className='w-full md:w-60 lg:w-80 md:mr-6 lg:mr-12 flex-shrink-0'>
        <div className='text-3xl flex pt-12 md:pt-0 pb-3 md:pb-6 md:text-4xl lg:text-5xl leading-none'>
          <h2 className='font-title'>Current Openings</h2>
        </div>
        <div className='max-w-xl text-lg md:text-xl lg:text-2xl lg:max-width-none leading-tight'>
          {renderLocations()}
        </div>
      </div>
      <div {...props} className={_className}>
        {renderPositions()}
        <Button
          type='button'
          className='mt-6 hover:underline'
          onClick={handleCheckApplicationStatusClick}
        >
          Check application status →
        </Button>
      </div>
    </div>
  );
};
