import cn from 'classnames';

import { mergeObjects, parseMarkdown } from 'utils';

import './Markdown.styles.scss';

export const Markdown = ({
  className,
  markdown,
  variant = 'default',
  ...props
}) => {
  const options = mergeObjects(
    { wrapper: { class: cn(variant, className) } },
    props
  );
  return parseMarkdown(markdown, options);
};
