import React from 'react';
import cn from 'classnames';

import './HackathonSubmissionsMessage.styles.scss';

export const HackathonSubmissionsMessage = ({ className, message }) => {
  const _className = cn(
    'hackathon-submissions-message',
    'outer-hairline',
    className
  );

  return <div className={_className}>{message}</div>;
};
