import React, { forwardRef } from 'react';
import cn from 'classnames';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { ConnectSection } from 'components/_shared/ConnectSection';

import { mergeProps } from 'utils';

import './PageTemplateDefault.styles.scss';

export const PageTemplateDefault = forwardRef(
  (
    {
      children,
      className,
      meta = {},
      classes = {},
      containerWidth,
      withContainer = false,
      withFooter = true,
      withConnectSection = false,
      drid,
      isDark = false,
      ...props
    },
    ref
  ) => {
    const headerProps = mergeProps(
      { className: 'page-header', isDark },
      props.header
    );

    return (
      <div {...props} ref={ref} data-drid={drid} className={className}>
        <Header {...headerProps} />

        <main className={cn('page-main', containerWidth, { withContainer })}>
          {children}
          {withConnectSection && <ConnectSection />}
        </main>

        {withFooter && <Footer className='page-footer' />}
      </div>
    );
  }
);
