import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ROUTES } from '_constants';

import { sortByKey } from 'utils';

const query = graphql`
  query BlogPostsQuery {
    allStrapiBlogPost {
      posts: nodes {
        id
        title
        slug
        excerpt {
          data {
            excerpt
          }
        }
        order
        date(formatString: "DD MMM YYYY")
        authors {
          name
          email
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }
  }
`;

export const useStrapiBlogPosts = () => {
  const {
    allStrapiBlogPost: { posts }
  } = useStaticQuery(query);

  const _posts = useMemo(() => {
    return posts.map(post => ({
      ...post,
      url: ROUTES.BLOG_BY_TITLE(post.slug)
    }));
  }, [posts]);

  const sortedPosts = useMemo(() => {
    return sortByKey(_posts, { equalKey: 'date' });
  }, [_posts]);

  return { posts: _posts, sortedPosts };
};
