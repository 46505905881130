import { useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

const query = graphql`
  query MenuLinksQuery {
    allStrapiMenuLink {
      links: nodes {
        slug
        text
        description
        url: link
        icon {
          localFile {
            svg {
              content
            }
          }
        }
      }
    }
  }
`;

export const useStrapiMenuLinks = () => {
  const {
    allStrapiMenuLink: { links }
  } = useStaticQuery(query);

  const location = useLocation();
  const currentLink = links.find(({ url }) => location.pathname.match(url));

  const getLinksBySlugs = useCallback(
    (slugs = []) => {
      if (!slugs?.length) return [];
      return slugs.map(slug => links.find(link => link.slug === slug));
    },
    [links]
  );

  return { links, currentLink, getLinksBySlugs };
};
