import EventEmitter from 'events';

class HeaderEvents extends EventEmitter {
  toggleMenu = props => {
    this.emit('toggleMenu', props);
  };

  onToggleMenu = callback => {
    this.on('toggleMenu', callback);
    return () => this.removeListener('toggleMenu', callback);
  };
}

export const headerEvents = new HeaderEvents();
