import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import { ROUTES } from '_constants';
import { LogoIconColor } from 'assets';

import { Page } from 'components/_shared/Page';
import { SocialShare } from 'components/_shared/Social';
import { Markdown } from 'components/_shared/Markdown';

import { useStrapiCommonContent } from 'hooks';
import { getYouTubeEmbedVideoById, getYouTubeVideoIdByURL } from 'utils';

import './HackathonSubmissionPage.styles.scss';

const BackLink = ({ className, ...props }) => {
  const commonContent = useStrapiCommonContent();
  const _className = cn('flex max-w-2xl w-full pb-4', className);

  return (
    <div {...props} className={_className}>
      <div className='mr-2 text-base lg:text-lg rotate-180'>
        {commonContent.arrow}
      </div>

      <Link
        className='max-w-xl text-base lg:text-lg hover:underline'
        to={ROUTES.HACKATHON_SUBMISSIONS}
      >
        {commonContent.back_to_hackathon_gallery}
      </Link>
    </div>
  );
};

export const HackathonSubmissionPage = props => {
  const { pageContext: submission } = props;
  const commonContent = useStrapiCommonContent();

  const renderPreview = () => {
    let preview = (
      <div className='default'>
        <LogoIconColor className='devrev-logo-icon' />
      </div>
    );

    if (submission.video_url) {
      const videoId = getYouTubeVideoIdByURL(submission.video_url);
      const src = getYouTubeEmbedVideoById(videoId);

      preview = (
        <iframe
          title={submission.title}
          src={src}
          allowFullScreen
          frameBorder='0'
          webkitallowfullscreen='true'
          mozallowfullscreen='true'
        />
      );
    } else if (submission.preview_image?.localFile) {
      preview = (
        <div className='submission-preview image'>
          <GatsbyImage
            image={getImage(submission.preview_image.localFile)}
            alt={submission.title}
          />
        </div>
      );
    }

    return <div className='submission-preview'>{preview}</div>;
  };

  const renderGitHubLink = submission.github_url && (
    <a
      className='submission-link-github'
      href={submission.github_url}
      target='_blank'
      rel='noreferrer'
    >
      {`${commonContent.view_on_github} ${commonContent.arrow}`}
    </a>
  );

  return (
    <Page
      className='hackathon-submission'
      meta={{
        title: submission.title,
        description: submission.short_description,
        keywords: submission.seo_keywords
      }}
    >
      <div className='header-spacer h-24' />

      <article>
        <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 px-3 md:px-6 relative lg:pt-12 lg:flex-row'>
          <div className='flex md:hidden w-full flex-shrink-0'>
            <BackLink />
          </div>

          <div className='w-full'>
            <header className='w-full md:hidden flex flex-col relative'>
              <h1 className='text-2xl leading-tight'>{submission.title}</h1>

              {submission.team_name && (
                <p className='mt-4 text-lg lg:text-xl'>
                  {submission.team_name}
                </p>
              )}

              {renderGitHubLink}

              <div className='flex mt-4 flex-wrap items-center md:items-start justify-between	flex-row md:flex-col'>
                <SocialShare
                  className='mb-4'
                  title={submission.title}
                  description={submission.short_description}
                />
              </div>
            </header>

            {renderPreview()}

            <div className='flex mt-6 flex-col md:flex-row'>
              <div className='md:w-1/3 w-full md:mr-6'>
                <header className='w-full hidden md:flex flex-col relative'>
                  <BackLink />

                  <h1 className='text-lg md:text-2xl lg:text-4xl leading-tight'>
                    {submission.title}
                  </h1>

                  {submission.team_name && (
                    <p className='mt-4 text-lg lg:text-xl'>
                      {submission.team_name}
                    </p>
                  )}

                  {renderGitHubLink}

                  <div className='flex mt-4 flex-wrap items-center md:items-start justify-between	flex-row md:flex-col'>
                    <SocialShare
                      className='mb-4'
                      title={submission.title}
                      description={submission.short_description}
                    />
                  </div>
                </header>
              </div>
              <main className='w-full'>
                <Markdown markdown={submission.description.data.description} />
                <hr className='my-12' />
              </main>
            </div>
          </div>
        </div>
      </article>
    </Page>
  );
};
