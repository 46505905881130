import { useCallback, useState, useMemo } from 'react';
import { API } from 'api';
import { useAsync } from 'hooks';
import { useUserActions, selectUser } from 'state';
import { useSelector } from 'react-redux';
import { addTempApplication } from 'state/user/applications';
import { identifyUser } from '../../../../utils/segment';

async function toFormData(data) {
  const resultData = new FormData();
  for (const name in data) {
    if (data.hasOwnProperty(name)) {
      resultData.append(name, data[name]);
    }
  }
  return resultData;
}

async function toTraits(data) {
  const traits = {};
  for (const name in data) {
    if (data.hasOwnProperty(name)) {
      if (!(data[name] instanceof File)) {
        traits[name] = data[name];
      }
    }
  }
  return traits;
}

function getFieldData(key, data, config) {
  const fieldName = config[key];
  return fieldName ? data[fieldName] : '';
}

export function useApplyForJob(applicationForm) {
  const [isDone, setIsDone] = useState();
  const { isLoading, error, handleAsync } = useAsync();
  const { updateCurrentUser } = useUserActions();
  const user = useSelector(selectUser);
  const keyToFieldName = useMemo(() => {
    const map = {};
    for (const step of applicationForm) {
      for (const section of step.sections) {
        for (const row of section.rows) {
          for (const item of row) {
            if (item.key && item.question) {
              const fieldName = item.question?.fields?.[0]?.name;
              if (fieldName) {
                map[item.key] = fieldName;
              }
            }
          }
        }
      }
    }
    return map;
  }, [applicationForm]);

  const updateUserData = useCallback(
    async data => {
      if (!user.firstname || !user.lastname) {
        return updateCurrentUser({
          email: user.email,
          firstname: data.first_name,
          lastname: data.last_name,
          devrev_github: getFieldData('github', data, keyToFieldName),
          devrev_linkedin: getFieldData('linkedin', data, keyToFieldName),
          devrev_website: getFieldData('website', data, keyToFieldName)
        });
      }
    },
    [updateCurrentUser, user, keyToFieldName]
  );

  const applyForJob = useCallback(
    async (position, data) => {
      identifyUser({ ...toTraits(data) });
      setIsDone(false);
      await handleAsync(async () => {
        const updateUserPromise = updateUserData(data);
        const body = await toFormData(data);
        await API.jobs.applyForJob(position.initialId, body);
        addTempApplication(user.email, position);
        await updateUserPromise;
      });
      setIsDone(true);
    },
    [handleAsync, updateUserData, user]
  );

  return { isDone, isLoading, error, applyForJob };
}
