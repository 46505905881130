import { axiosInstance } from '../instance';
import { hackathonEndpoints } from './hackathon.endpoints';

const registerToHackathon = body => {
  return axiosInstance.post(hackathonEndpoints.registerToHackathon, body);
};

const submitHackathon = body => {
  return axiosInstance.post(hackathonEndpoints.submitHackathon, body);
};

export const hackathonRequests = {
  registerToHackathon,
  submitHackathon
};
