import { useEffect, useCallback } from 'react';

import { gsap } from 'utils';

const duration = 0.5;

export function useModalAnimation(ref, overlayRef, { isOpen, onComplete }) {
  useEffect(() => {
    if (!isOpen) return;

    gsap.fromTo(
      overlayRef.current,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration }
    );

    gsap.fromTo(
      ref.current,
      { autoAlpha: 0, scale: 0.96 },
      { autoAlpha: 1, scale: 1, duration }
    );
  }, [ref, overlayRef, isOpen]);

  const animateOut = useCallback(() => {
    gsap.to(overlayRef.current, { autoAlpha: 0, duration });
    gsap.to(ref.current, {
      autoAlpha: 0,
      scale: 0.96,
      duration,
      onComplete
    });
  }, [ref, overlayRef, onComplete]);

  return [animateOut];
}
