module.exports.drids = {
  BURGER_MENU: 'BURGER_MENU',
  BURGER_JOIN_US: 'BURGER_JOIN_US_LINK',
  DEVREV_LOGO: 'DEVREV_LOGO_LINK',
  NAV: {
    COMPANY: 'COMPANY_LINK',
    PEOPLE: 'PEOPLE_LINK',
    NEWS: 'NEWS_LINK',
    BLOG: 'BLOG_LINK',
    JOIN_US: 'JOIN_US_LINK',
    TERMS_OF_SERVICE: 'TERMS_OF_SERVICE_LINK'
  },
  PAGE: {
    COMPANY: 'PAGE_COMPANY',
    HOME: 'PAGE_HOME',
    PEOPLE: 'PAGE_PEOPLE',
    NEWS: 'PAGE_NEWS',
    BLOG: 'PAGE_BLOG',
    JOIN_US: 'PAGE_JOIN_US',
    TERMS_OF_SERVICE: 'PAGE_TERMS_OF_SERVICE'
  },
  COMPANY_NAV: {
    COMPANY_PEOPLE: 'COMPANY_PEOPLE_LINK',
    COMPANY_INVESTORS: 'COMPANY_INVESTORS_LINK',
    COMPANY_GROWING: 'COMPANY_GROWING_LINK',
    JOIN_US_BODY: 'JOIN_US_BODY_LINK',
    JOIN_US_FOOTER: 'JOIN_US_FOOTER_LINK'
  },
  NEWS_BLOG_NAV: {
    ARTICLES: 'ARTICLES_LINK'
  },
  ARTICLES: {
    ARTICLES_BODY: 'ARTICLES_BODY_LINK',
    ARTICLES_TITLE: 'ARTICLES_TITLE_LINK'
  }
};
