import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import omit from 'lodash-es/omit';

import { IconArrowLeft } from 'assets';

import { ROUTES } from '_constants';

import { Form, FormField, FormGroup } from 'components/_shared/Form';
import { Section } from 'components/_shared/Section';
import { Button } from 'components/_shared/Button';

import { useUserActions } from 'state';
import { asRequired, parseMarkdown, tr } from 'utils';

import './HackathonRegistrationForm.styles.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { useUser, useUserAccess } from 'state/hooks';

const joinValidationSchema = labels =>
  Yup.object().shape({
    firstName: Yup.string().required(labels.form_error_required),
    lastName: Yup.string().required(labels.form_error_required),
    email: Yup.string()
      .email(labels.form_error_invalidEmail)
      .required(labels.form_error_required),
    linkedinUrl: Yup.string()
      .matches(
        /^((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))/,
        labels.form_error_invalidLinkedIn
      )
      .required(labels.form_error_required),
    phone: Yup.string().matches(
      /(?:(?=^\+?)^(\+?...)\d{9}$)/g,
      labels.form_error_invalidPhoneNumber
    )
  });

const registerValidationSchema = labels =>
  Yup.object().shape({
    hasTeammates: Yup.string().required(labels.form_error_required),
    whoToldYou: Yup.string().required(labels.form_error_required),
    specificTold: Yup.string().when('whoToldYou', {
      is: value => ['Devpost', 'Other'].includes(value),
      then: Yup.string().required(labels.form_error_required)
    }),
    agreements: Yup.array().min(2, labels.form_agreements_error)
  });

const query = graphql`
query HackathonRegistrationFormQuery {
  labels: strapiHackathonSignupPage {
    form_agreements_eligibility {
      data {
        form_agreements_eligibility
      }
    }
    form_agreements_error
    form_agreements_label
    form_agreements_rules {
      data {
        form_agreements_rules
      }
    }
    form_community_guidelines {
      data {
        form_community_guidelines
      }
    }
    form_email_label
    form_error_invalidLinkedIn
    form_error_invalidPhoneNumber
    form_error_invalidEmail
    form_error_required
    form_firstName_label
    form_hasTeammates_label
    form_hasTeammates_options {
      strapi_json_value {
        label
        value
      }
    }
    form_lastName_label
    form_linkedin_label
    form_phone_label
    form_select_placeholder
    form_specificToldInput_label
    form_specificTold_label
    form_specificTold2_label
    form_specificTold_options {
      strapi_json_value {
        label
        value
      }
    }
    form_step0_action
    form_step0_heading
    form_step0_subheading
    form_step0_supheading
    form_step1_heading
    form_step1_action
    form_step1_subheading
    form_step1_supheading
    form_text_placeholder
    form_whoToldYou_label
    form_whoToldYou_options {
      strapi_json_value {
        label
        value
      }
    }
  }
}

`;

export const HackathonRegistrationForm = ({ className, onSubmit }) => {
  const { isAuthenticated } = useUserAccess();
  const user = useUser();
  const { isLoading, registerToHackathon } = useUserActions();
  const { labels } = useStaticQuery(query);

  const _className = cn('hackathon-registration-form', className);

  const initialValues = {
    hackathonId: 0,
    firstName: user.firstname || '',
    lastName: user.lastname || '',
    email: user.email || '',
    phone: '',
    linkedinUrl: user.devrev_linkedin,
    hasTeammates: '',
    whoToldYou: '',
    specificTold: '',
    agreements: []
  };

  const [formState, setFormState] = useState({ step: 0 });

  useEffect(() => {
    if (isAuthenticated) {
      setFormState(state => ({ ...state, step: 1 }));
    }
  }, [isAuthenticated]);

  const formSteps = {
    0: {
      supheading: labels.form_step0_supheading,
      heading: labels.form_step0_heading,
      subheading: labels.form_step0_subheading,
      actionText: labels.form_step0_action,
      validationSchema: joinValidationSchema(labels),
      onSubmit: (_, form) => {
        Object.keys(initialValues).forEach(field => {
          form.setFieldTouched(field, false);
          form.setFieldError(field, '');
        });

        setFormStep();
      },
      render: () => (
        <>
          <FormGroup direction='row'>
            <FormField
              type='text'
              name='firstName'
              label={asRequired(labels.form_firstName_label)}
              placeholder={labels.form_text_placeholder}
            />
            <FormField
              type='text'
              name='lastName'
              label={asRequired(labels.form_lastName_label)}
              placeholder={labels.form_text_placeholder}
            />
          </FormGroup>
          <FormGroup direction='row'>
            <FormField
              type='email'
              name='email'
              label={asRequired(labels.form_email_label)}
              placeholder={labels.form_text_placeholder}
            />
            <FormField
              type='phone'
              name='phone'
              label={labels.form_phone_label}
              placeholder={labels.form_text_placeholder}
            />
          </FormGroup>
          <FormGroup>
            <FormField
              type='text'
              name='linkedinUrl'
              label={asRequired(labels.form_linkedin_label)}
              placeholder={labels.form_text_placeholder}
            />
          </FormGroup>
        </>
      )
    },
    1: {
      supheading: labels.form_step1_supheading,
      heading: labels.form_step1_heading,
      subheading: labels.form_step1_subheading,
      actionText: labels.form_step1_action,
      validationSchema: registerValidationSchema(labels),
      onSubmit: async values => {
        if (isAuthenticated) {
          values.firstName = user.firstname;
          values.lastName = user.lastname;
          values.email = user.email;
        }
        const _values = {
          ...omit(values, 'agreements'),
          ...values.agreements.reduce((values, value) => {
            return { ...values, [value]: true };
          }, {})
        };

        await registerToHackathon(_values);

        onSubmit?.();
      },
      render: form => {
        return (
          <>
            <div className='text-2xl'>
              {parseMarkdown(
                tr(labels.form_community_guidelines.data.form_community_guidelines, {
                  termsUrl: ROUTES.TERMS_OF_SERVICE
                })
              )}
            </div>

            <FormGroup>
              <FormField
                type='select'
                name='hasTeammates'
                label={asRequired(labels.form_hasTeammates_label)}
                placeholder={labels.form_select_placeholder}
                options={labels.form_hasTeammates_options.strapi_json_value}
              />
              <FormField
                type='select'
                name='whoToldYou'
                label={asRequired(labels.form_whoToldYou_label)}
                placeholder={labels.form_select_placeholder}
                options={labels.form_whoToldYou_options.strapi_json_value}
                onChange={() => {
                  form.setFieldValue('specificTold', '');
                }}
              />

              {form.values.whoToldYou?.match('Devpost') && (
                <FormField
                  type='select'
                  name='specificTold'
                  label={asRequired(labels.form_specificTold_label)}
                  placeholder={labels.form_select_placeholder}
                  options={labels.form_specificTold_options.strapi_json_value}
                />
              )}

              {form.values.whoToldYou?.match('Other') && (
                <FormField
                  type='text'
                  name='specificTold'
                  label={asRequired(labels.form_specificTold2_label)}
                  placeholder={labels.form_text_placeholder}
                />
              )}

              <FormField
                type='checkboxGroup'
                name='agreements'
                label={labels.form_agreements_label}
                variant='primary'
                options={[
                  {
                    label: parseMarkdown(labels.form_agreements_eligibility),
                    value: 'readAndAgreeEligibility'
                  },
                  {
                    label: parseMarkdown(
                      tr(labels.form_agreements_rules.data.form_agreements_rules, {
                        rulesUrl: `${ROUTES.HACKATHON}?tab=rules`,
                        termsUrl: ROUTES.TERMS_OF_SERVICE
                      })
                    ),
                    value: 'readAndAgreeRules'
                  }
                ]}
              />
            </FormGroup>
          </>
        );
      }
    }
  };

  const setFormStep = step => {
    setFormState(state => {
      const nextStep = step || state.step + 1;
      if (!(nextStep in formSteps)) return state;
      return { ...state, step: nextStep };
    });
  };

  const step = formSteps[formState.step];

  return (
    <Section
      variant='aside'
      heading={step.heading}
      supheading={step.supheading}
      subheading={step.subheading}
      className={_className}
    >
      <Form
        isLoading={isLoading}
        initialValues={initialValues}
        validationSchema={step.validationSchema}
        onSubmit={step.onSubmit}
      >
        {form => (
          <>
            {step.render?.(form)}

            <div className='actions'>
              {(formState.step === 0 || isAuthenticated) && <div />}
              {formState.step !== 0 && !isAuthenticated && (
                <Button
                  className='action action-back'
                  type='button'
                  onClick={() => {
                    setFormState(state => ({
                      ...state,
                      step: state.step - 1
                    }));
                  }}
                >
                  <IconArrowLeft />
                </Button>
              )}

              {step.actionText && (
                <Button
                  className='action action-submit'
                  type='submit'
                  variant='primary'
                  disabled={isLoading}
                >
                  {step.actionText}
                </Button>
              )}
            </div>
          </>
        )}
      </Form>
    </Section>
  );
};
