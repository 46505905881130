import React, { useMemo, cloneElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import * as Yup from 'yup';

import { Modal } from 'components/_shared/Modal';
import { Form, FormField, FormGroup } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';
import { Section } from 'components/_shared/Section';
import { Divider } from 'components/_shared/Divider';

import { useUserActions } from 'state';
import { getInitialValuesByFields } from 'utils';

import './UserSwagModal.styles.scss';

const query = graphql`
  query SwagModalQuery {
    strapiTShirtSurvey2021 {
      survey_content {
        questions {
          name
          label
          type
          options
          isRequired
        }
      }
    }
  }
`;

function normalizeFields(fields) {
  return fields.reduce(
    (fields, field) => {
      const fieldNumber = +field.name.replace(/\D/g, '');
      const chunkNumber = fieldNumber < 9 ? 0 : 1;

      return chunkNumber === 0
        ? [[...fields[0], field], fields[1]]
        : [fields[0], [...fields[1], field]];
    },
    [[], []]
  );
}

function renderFormFields(fields = []) {
  const fieldComponetsByType = {
    text: <FormField type='text' placeholder='Type here...' />,
    select: <FormField type='select' placeholder='Select option' />,
    'multi-select': <FormField type='select' placeholder='Select options' />,
    boolean: <FormField type='radio' />
  };

  return fields.map(({ type, options, ...field }) => {
    const component = fieldComponetsByType[type];

    if (!component) return null;

    let _options = [];

    if (type.match(/select|boolean/) && options?.length) {
      _options = options.map(value => ({ label: value, value }));
    }

    return cloneElement(component, {
      ...field,
      key: field.name,
      options: _options
    });
  });
}

export const UserSwagModal = ({ className, onSubmit, ...props }) => {
  const {
    strapiTShirtSurvey2021: {
      survey_content: { questions: fields }
    }
  } = useStaticQuery(query);

  const initialValues = useMemo(() => {
    return getInitialValuesByFields(fields);
  }, [fields]);

  const validationSchema = useMemo(() => {
    const shape = fields.reduce((schema, field) => {
      const isRequired = field.isRequired === 'Yes';
      const isArray = field.type === 'multi-select';

      if (!isRequired) return schema;

      let rule = Yup.string().required('Value is required');

      if (isArray) {
        rule = Yup.array().min(1, 'Option is required');
      }

      return { ...schema, [field.name]: rule };
    }, {});

    return Yup.object().shape(shape);
  }, [fields]);

  const normalizedFields = useMemo(() => {
    return normalizeFields(fields);
  }, [fields]);

  const { isLoading } = useUserActions();

  const _className = cn('user-devrev-swag', className);

  const handleSubmit = async values => {
    onSubmit?.(values);
  };

  return (
    <Modal {...props} className={_className} classes={{ children: 'h-full' }}>
      <Section
        variant='aside'
        heading='Claim your DevRev swag'
        subheading='Just help us a few questions and details'
      >
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        >
          <h4 className='form-section-heading'>About your work</h4>
          <FormGroup>{renderFormFields(normalizedFields[0])}</FormGroup>

          <Divider variant='secondary' />

          <h4 className='form-section-heading'>Swag details</h4>
          <FormGroup>{renderFormFields(normalizedFields[1])}</FormGroup>

          <Divider variant='secondary' />

          <Button type='submit' variant='primary' disabled={isLoading}>
            Submit
          </Button>
        </Form>
      </Section>
    </Modal>
  );
};
