import './src/styles/tailwind.css';
import './src/styles/premade.css';
import './src/assets/fonts/fonts.css';

import { RootElement } from 'components/RootElement';
import { PageElement } from 'components/PageElement';

export const wrapRootElement = RootElement;

export const wrapPageElement = PageElement;
