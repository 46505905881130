import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

const query = graphql`
  query SocialLinksQuery {
    allStrapiSociallink {
      nodes {
        platform: socialplatform
        url: sociallink
        label
      }
    }
  }
`;

export const useStrapiSocialLinks = () => {
  const data = useStaticQuery(query);

  return useMemo(() => {
    return data.allStrapiSociallink.nodes.reduce(
      (links, { platform, label, url }) => ({
        ...links,
        [platform]: { label, url }
      }),
      {}
    );
  }, [data]);
};
