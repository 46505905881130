import React, {
  cloneElement,
  forwardRef,
  isValidElement,
  useMemo
} from 'react';

import { useField, useFormikContext } from 'formik';
import cn from 'classnames';
import isArray from 'lodash-es/isArray';
import isFunction from 'lodash-es/isFunction';
import upperFirst from 'lodash-es/upperFirst';

import {
  InputCheckboxGroup,
  InputNumber,
  InputRadio,
  InputSelect,
  InputText,
  InputTextarea,
  InputFile,
  InputPhone
} from 'components/_shared/Input';

import { useFormContext } from '../Form.context';
import { withFunction, mergeProps } from 'utils';

import './FormField.styles.scss';

const FIELD_TYPE_CONTROLS = {
  email: <InputText type='text' />,
  number: <InputNumber />,
  password: <InputText type='password' />,
  radio: <InputRadio />,
  checkboxGroup: <InputCheckboxGroup />,
  select: <InputSelect />,
  text: <InputText type='text' />,
  file: <InputFile />,
  files: <InputFile multiple />,
  textarea: <InputTextarea />,
  phone: <InputPhone />
};

let previousFieldId = 0;

export const FormField = forwardRef(
  (
    {
      id,
      className,
      name,
      type = 'text',
      label,
      error,
      message,
      control,
      variant: initialVariant,
      ...props
    },
    ref
  ) => {
    const { submitCount } = useFormikContext();
    const formContext = useFormContext();
    const variant = initialVariant || formContext.variant;

    const [field, meta, helpers] = useField(name);
    const _id = useMemo(() => id || `field-${++previousFieldId}`, [id]);

    const isFileInput = type === 'file' || type === 'files';
    const messageError =
      error ||
      // Formik doesn't report touched on file inputs
      (((isFileInput && submitCount > 0) || meta.touched) && meta.error);
    const canRenderMessages = messageError || message;

    const _className = cn(
      'form-field',
      variant,
      type,
      {
        disabled: props.disabled,
        hasError: messageError,
        hasValue:
          (isArray(field.value) ? field.value.length : field.value) &&
          !messageError
      },
      className
    );

    const renderControl = () => {
      let controlElement = null;
      const isCustomControl =
        control && (isFunction(control) || isValidElement(control));

      if (type) controlElement = FIELD_TYPE_CONTROLS[type];

      if (isCustomControl) {
        controlElement = withFunction(control, { field, meta, helpers });
      }

      if (!controlElement) return null;

      const mergedProps = mergeProps(controlElement.props, [
        {
          ...props,
          id: _id,
          className: 'form-control',
          variant,
          hasError: !!messageError
        },
        field,
        type === 'select' && {
          onChange: values => helpers.setValue(values)
        },
        type === 'checkboxGroup' && {
          onChange: event => helpers.setValue(event.customValue)
        },
        type === 'file' && {
          onChange: event => helpers.setValue(event.target.files[0])
        },
        type === 'files' && {
          onChange: event => helpers.setValue(event.target.files)
        }
      ]);

      return cloneElement(controlElement, mergedProps);
    };

    const renderLabel = () => {
      return (
        label && (
          <label
            htmlFor={_id}
            className={cn('field-label', {
              [`
              cursor-text
              absolute
              top-1/2
              lg:-translate-y-10
              md:-translate-y-9
              -translate-y-8
              peer-focus:lg:-translate-y-10
              peer-focus:md:-translate-y-9
              peer-focus:-translate-y-8
              opacity-100
              peer-focus:opacity-100
              peer-placeholder-shown:opacity-30
              peer-placeholder-shown:-translate-y-1/2
              peer-placeholder-shown:text-lg
              peer-placeholder-shown:md:text-xl
              peer-placeholder-shown:lg:text-2xl
              text-xs
              md:text-sm
              peer-focus:!text-xs
              peer-focus:md:!text-sm
              transition-all
              duration-300`]: variant === 'secondary'
            })}
          >
            {upperFirst(label)}
          </label>
        )
      );
    };
    return (
      <div ref={ref} className={_className}>
        {variant !== 'secondary' && renderLabel()}

        {renderControl()}

        {variant === 'secondary' && renderLabel()}

        {canRenderMessages && variant !== 'secondary' && (
          <div className='field-messages'>
            {messageError && (
              <p className='field-message error'>{upperFirst(messageError)}</p>
            )}
            {message && <p className='field-message'>{upperFirst(message)}</p>}
          </div>
        )}
        {canRenderMessages && variant === 'secondary' && (
          <div className='field-messages absolute -bottom-5 text-sm'>
            {messageError && (
              <p className='error'>{upperFirst(messageError)}</p>
            )}
            {message && <p>{upperFirst(message)}</p>}
          </div>
        )}
      </div>
    );
  }
);
