import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BETA_STATUS } from '_constants';
import { API } from 'api';

import { userActions, selectUser } from './user.slice';
import { useAsync } from 'hooks';
import { getTempApplications, removeTempApplication } from './applications';
import { identifyUser } from '../../utils/segment';
import { useAuth0 } from '@auth0/auth0-react';

export const useUserActions = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { isLoading, error, handleAsync } = useAsync();
  const { loginWithRedirect, logout: auth0Logout } = useAuth0();

  const login = useCallback(
    returnTo => {
      if (!returnTo) {
        returnTo = window.location.href.replace(window.location.origin, '');
      }
      loginWithRedirect({ prompt: 'login', appState: { returnTo } });
    },
    [loginWithRedirect]
  );

  const logout = useCallback(
    (returnTo = window.location.origin) => {
      if (typeof(returnTo) !== 'string') {
        returnTo = window.location.origin
      }
      auth0Logout({ returnTo });
    },
    [auth0Logout]
  );

  const getCurrentUser = useCallback(async () => {
    try {
      await handleAsync(async () => {
        const user = (await API.user.getCurrentUser()).data;
        dispatch(userActions.setEntity(user));
        identifyUser(user);
      });
    } catch (error) {
      throw error;
    }
  }, [dispatch, handleAsync]);

  const setCurrentUser = useCallback(
    user => {
      dispatch(userActions.setEntity(user));
      identifyUser(user);
    },
    [dispatch]
  );

  const updateCurrentUser = useCallback(
    async body => {
      await handleAsync(async () => {
        await API.user.updateCurrentUser(body);
        identifyUser(body);
        dispatch(userActions.setEntity(body));
      });
    },
    [dispatch, handleAsync]
  );

  const getUserApplications = useCallback(async () => {
    try {
      await handleAsync(async () => {
        if (!user?.email) return;
        const applications = (
          await API.jobs.getUserApplications({
            parseResponseMessageOnError: false
          })
        ).data;
        const tempApplications = getTempApplications(user?.email);
        // If we get application with status "active" from the PLX-proxy,
        // then we can assume that the temp application has been properly
        // processed by our integrations and Greenhouse
        for (const tempApplication of tempApplications) {
          const hasActiveApplication = applications.some(
            a =>
              a.job_post_id === tempApplication.job_post_id &&
              a.status === 'active'
          );
          if (hasActiveApplication) {
            removeTempApplication(user.email, tempApplication);
          } else {
            applications.push(tempApplication);
          }
        }
        dispatch(userActions.setApplications(applications));
      });
    } catch (error) {
      // This call shouldn't cause user to logut unless PLX Proxy explicitly says so
      if (error.status === 403) {
        logout();
      }
    }
  }, [dispatch, handleAsync, logout, user?.email]);

  const submitTShirt = useCallback(() => {
    dispatch(userActions.setEntity({ tshirt_details_submission: 'true' }));
    identifyUser({ tshirt_details_submission: true });
  }, [dispatch]);

  const submitBetaSignIn = useCallback(() => {
    dispatch(
      userActions.setEntity({
        devrev_research_and_beta_opt_in: 'Yes',
        beta_user: BETA_STATUS.APPLIED
      })
    );
    identifyUser({
      devrev_research_and_beta_opt_in: true,
      beta_user: BETA_STATUS.APPLIED
    });
  }, [dispatch]);

  const applyFellowship = useCallback(
    async body => {
      await handleAsync(async () => {
        await API.user.applyFellowship(body);
        identifyUser(body);
        dispatch(
          userActions.setEntity({ devrev_fellowship_member: 'applied' })
        );
      });
    },
    [dispatch, handleAsync]
  );

  const addEvent = useCallback(
    async id => {
      await handleAsync(async () => {
        await API.events.addEvent(id);
        dispatch(userActions.addEvent(id));
      });
    },
    [dispatch, handleAsync]
  );

  const hasApplied = useCallback(
    jobPostId => {
      const appliedStates = new Set(['active', 'hired', 'processing']);
      const hasActiveApplicationsForJob = (user?.applications || [])
        .filter(application => application.job_post_id === jobPostId)
        .some(application => appliedStates.has(application.status));
      return user?.isApplicationsLoaded && hasActiveApplicationsForJob;
    },
    [user?.applications, user?.isApplicationsLoaded]
  );

  const setCookiePreference = useCallback(
    cookie => {
      dispatch(userActions.setCookie(cookie));
    },
    [dispatch]
  );

  const registerToHackathon = useCallback(
    async values => {
      await handleAsync(async () => {
        await API.hackathon.registerToHackathon(values);
        dispatch(userActions.setIsHackathonRegistered(true));
      });
    },
    [dispatch, handleAsync]
  );

  const submitHackathon = useCallback(
    async values => {
      await handleAsync(async () => {
        await API.hackathon.submitHackathon(values);
        dispatch(userActions.setHackathonSubmission(values));
      });
    },
    [dispatch, handleAsync]
  );

  const submitBetaInterest = useCallback(() => {
    dispatch(userActions.submitBetaInterest());
  }, [dispatch]);

  return {
    isLoading,
    error,
    login,
    logout,
    getCurrentUser,
    setCurrentUser,
    updateCurrentUser,
    getUserApplications,
    submitTShirt,
    submitBetaSignIn,
    applyFellowship,
    addEvent,
    hasApplied,
    setCookiePreference,
    registerToHackathon,
    submitHackathon,
    submitBetaInterest
  };
};
