import React from 'react';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';

import { Modal } from 'components/_shared/Modal';

import { parseMarkdown } from 'utils';

import './UserScholarshipModal.styles.scss';

const query = graphql`
  query ModalScolarshipQuery {
    strapiProgrammingchallenge {
      buttontext
      buttonurl
      challangedesciption {
        data {
          challangedesciption
        }
      }
      challengetitle
    }
  }
`;

export const UserScholarshipModal = ({ className, onSubmit, ...props }) => {
  const fields = useStaticQuery(query).strapiProgrammingchallenge;

  const _className = cn('user-devrev-scholarship', className);

  return (
    <Modal {...props} className={_className}>
      <h2 className='font-title text-4xl lg:text-center font-bold'>
        {fields.challengetitle}
      </h2>
      <div className='text-lg text-left mt-6 lg:px-10'>
        {parseMarkdown(fields.challangedesciption)}
      </div>

      <a
        href={fields.buttonurl}
        className='inline-block button primary self-center mt-12'
      >
        {fields.buttontext}
      </a>
    </Modal>
  );
};
