import React, { createContext, useContext } from 'react';

const FormContext = createContext();

export const FormProvider = ({ children, variant }) => {
  return (
    <FormContext.Provider value={{ variant }}>{children}</FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);
