import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';

import { Link } from 'components/_shared/Link';
import { Overlay } from 'components/_shared/Overlay';

import { useClickOutside, useStrapiMenuLinks } from 'hooks';
import { headerEvents } from '_events';
import { sortByKey } from 'utils';

import './HeaderMenu.styles.scss';

const query = graphql`
  query HeaderMenuQuery {
    menu: strapiHeader {
      menu_primary {
        link {
          menu_link {
            slug
          }
        }
      }
      menu_secondary {
        link {
          menu_link {
            slug
          }
        }
      }
    }
  }
`;

export const HeaderMenu = React.forwardRef(
  (
    {
      className,
      clickOutsideIgnoredClassNames = [],
      isDark = false,
      onIsOpenChange
    },
    ref
  ) => {
    const { menu } = useStaticQuery(query);
    const { getLinksBySlugs } = useStrapiMenuLinks();

    const rootRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState();
    const { mediumMax } = useBreakpoint();
    const _className = cn('header-menu', { isDark, isOpen }, className);

    const [primaryLinks, secondaryLinks] = useMemo(() => {
      return [menu.menu_primary, menu.menu_secondary].map(links => {
        return getLinksBySlugs(
          links.link.map(({ menu_link: { slug } }) => slug)
        );
      });
    }, [menu.menu_primary, menu.menu_secondary, getLinksBySlugs]);

    useClickOutside(rootRef, () => setIsOpen(false), {
      ignoredClassNames: clickOutsideIgnoredClassNames
    });

    useEffect(() => onIsOpenChange?.(isOpen), [isOpen, onIsOpenChange]);

    useEffect(() => headerEvents.toggleMenu(isOpen), [isOpen]);

    useEffect(() => {
      if (!mediumMax) return (document.body.style.overflow = 'auto');
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
      return () => (document.body.style.overflow = 'auto');
    }, [mediumMax, isOpen]);

    useEffect(() => {
      if (!mediumMax) return setHeight(undefined);
      setHeight(`calc(${window.innerHeight}px - 6.25rem)`);
    }, [mediumMax, isOpen]);

    useImperativeHandle(
      ref,
      () => ({
        element: rootRef.current,
        isOpen,
        setIsOpen,
        toggle: () => setIsOpen(is => !is)
      }),
      [isOpen]
    );

    return (
      <>
        <nav ref={rootRef} className={_className} style={{ height }}>
          <Links
            className='primary'
            links={sortByKey(primaryLinks).map(link => ({
              ...link,
              children: (
                <React.Fragment key={link.slug}>
                  {link.icon?.localFile?.svg?.content && (
                    <span
                      className='link-icon'
                      dangerouslySetInnerHTML={{
                        __html: link.icon.localFile.svg.content
                      }}
                    />
                  )}

                  <div className='link-info'>
                    <span className='link-text'>{link.text}</span>
                    {link.description && (
                      <span className='link-description'>
                        {link.description}
                      </span>
                    )}
                  </div>
                </React.Fragment>
              )
            }))}
          />
          <Links
            className='secondary'
            links={sortByKey(secondaryLinks).map(link => ({
              ...link,
              children: link.text
            }))}
          />
        </nav>

        <Overlay className={cn('header-menu-overlay', { isOpen })} />
      </>
    );
  }
);

function Links({ className, links = [] }) {
  if (!links?.length) return null;

  return (
    <ul className={cn('header-menu-links', className)}>
      {links.map(link => (
        <li key={link.slug} className='header-menu-links-item'>
          <Link className='header-menu-link' href={link.url}>
            {link.children}
          </Link>
        </li>
      ))}
    </ul>
  );
}
