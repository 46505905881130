import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import isArray from 'lodash-es/isArray';

import { IconCheck, IconChevronDown } from 'assets';

import { Dropdown } from 'components/_shared/Dropdown';

import './InputSelect.styles.scss';

export const InputSelect = ({
  className,
  placeholder = 'Select...',
  options = [],
  value = '',
  optionsHeading,
  onChange
}) => {
  const rootRef = useRef(null);
  const dropdownRef = useRef(null);
  const onChangeCallback = useRef(onChange);

  const [_value, setValue] = useState(value);
  const isMultiple = isArray(_value);
  const formatedValue = isMultiple ? _value : [_value];
  const hasValue = !!formatedValue.some(Boolean);

  const _className = cn(
    'input input-select',
    { hasValue, isMultiple },
    className
  );

  useEffect(
    () => onChangeCallback.current?.(_value),
    [_value, onChangeCallback]
  );

  const handleSelectClick = () => {
    dropdownRef.current?.setIsOpen?.(isOpen => !isOpen);
  };

  const handleOptionClick = option => {
    if (isMultiple) {
      return setValue(values => {
        if (option.isSelected) {
          return values.filter(value => value !== option.value);
        }

        return [...values, option.value];
      });
    }
    setValue(option.isSelected ? '' : option.value);

    if (!isMultiple) {
      dropdownRef.current?.setIsOpen?.(isOpen => !isOpen);
    }
  };

  const renderValue = () => {
    return (
      <div className='input-select-value'>
        {formatedValue.map((_value, i) => {
          const { label } = options.find(option => option.value === _value);

          return (
            <span key={_value} className='input-select-value-label'>
              {i > 0 ? `, ${label}` : label}
            </span>
          );
        })}
      </div>
    );
  };

  const renderOptions = () => {
    return options.map(option => {
      const isSelected = formatedValue.some(value => value === option.value);

      return (
        <button
          key={option.value}
          type='button'
          className={cn('input-select-option', { isSelected })}
          onClick={() => handleOptionClick({ ...option, isSelected })}
        >
          <span className='input-select-option-label'>{option.label}</span>
          <span className='input-select-option-check'>
            <IconCheck />
          </span>
        </button>
      );
    });
  };

  return (
    <div ref={rootRef} className={_className}>
      <button
        type='button'
        className='input-select-body'
        onClick={handleSelectClick}
      >
        {hasValue && renderValue()}

        {!hasValue && placeholder && (
          <span className='input-select-placeholder'>{placeholder}</span>
        )}

        <span className='input-select-toggle'>
          <IconChevronDown />
        </span>
      </button>

      <Dropdown
        ref={dropdownRef}
        className='input-select-dropdown'
        clickOutsideParams={{ ignoredRefs: [rootRef] }}
      >
        {optionsHeading && (
          <h6 className='input-select-dropdown-heading'>{optionsHeading}</h6>
        )}

        {renderOptions()}
      </Dropdown>
    </div>
  );
};
