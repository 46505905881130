import React, { useEffect, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { IconArrowRight } from 'assets';

import { Page } from 'components/_shared/Page';
import { Action } from 'components/_shared/Action';

import { useStrapiActions } from 'hooks';

import './BetaPageLanding.styles.scss';

export const BetaPageLanding = ({ data: { page } }) => {
  const getStartedAction = useStrapiActions().getActionBySlug(
    'open-beta-get-started'
  );
  const mobileSignupAction =
    useStrapiActions().getActionBySlug('mobile-sign-up');
  const action = useRef(getStartedAction);

  useEffect(() => {
    if (window.outerWidth < 720) {
      action.current = mobileSignupAction;
    } else {
      action.current = getStartedAction;
    }
  });

  const headingParts = page.heading
    .split('<br>')
    .map(part => <span key={part}>{part}</span>);

  return (
    <Page
      className='landing beta'
      meta={page.seo}
      template='v2'
      container={{ variant: 'wide' }}
      headerProps={{ signUpButton: { color: 'pureBlue' } }}
    >
      <h2 className='heading'>{headingParts}</h2>

      <div className='content'>
        {page.image && (
          <div className='image-container'>
            <div className='image-wrapper'>
              <GatsbyImage
                className='image'
                image={getImage(page.image.localFile)}
                alt={page.heading}
              />
            </div>
          </div>
        )}

        <div className='info-wrapper'>
          <p className='leading'>{page.intro_text}</p>
          <p className='text'>{page.text}</p>
          <p className='text'>{page.text_2}</p>
          {action && (
            <Action
              {...action.current}
              asStyles='button'
              variant='quaternary'
              size='sm'
              color='pureBlue'
              icon={<IconArrowRight />}
              iconPosition='right'
              isIconFloat
              target='_self'
            />
          )}
        </div>
      </div>
    </Page>
  );
};
