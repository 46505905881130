import React, { cloneElement, isValidElement } from 'react';
import cn from 'classnames';

import { Container } from 'components/_shared/Container';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';

import { mergeProps } from 'utils';

import './PageTemplateV2.styles.scss';

export const PageTemplateV2 = ({
  className,
  children,
  header = <Header />,
  footer = <Footer />,
  headerProps,
  variant,
  container = {},
  isDark = false,
  ...props
}) => {
  const _className = cn(className, variant);

  const [_header, _footer] = Object.entries({ header, footer }).map(
    ([key, component]) => {
      if (!component || !isValidElement(component)) return null;
      return cloneElement(
        component,
        mergeProps(
          { className: cn(`page-${key}`, component.props?.className), isDark },
          [props[key], key === 'header' && headerProps]
        )
      );
    }
  );

  const containerProps = mergeProps(
    {
      className: 'page-content',
      tag: 'main',
      variant: 'secondary'
    },
    container
  );

  return (
    <div className={_className}>
      {_header}
      {children && <Container {...containerProps}>{children}</Container>}
      {_footer}
    </div>
  );
};
