import React, { useMemo } from 'react';
import cn from 'classnames';

import { withFunction } from 'utils';

import './List.styles.scss';

export const List = ({ className, variant, items = [] }) => {
  const _className = cn('list', variant, className);

  const renderItems = useMemo(() => {
    return items.map((item, i) => (
      <li key={i} className={cn('list-item', 'top-hairline')}>
        {withFunction(item)}
      </li>
    ));
  }, [items]);

  return <ul className={_className}>{renderItems}</ul>;
};
