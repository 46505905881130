import React, { useCallback, useState } from 'react';

import { useField } from 'formik';
import { FormField } from 'components/_shared/Form';
import { mergeProps } from 'utils';
import { Button } from 'components/_shared/Button';
import { useApplicationFormLabels } from '../hooks';

export const LINKS_FIELD_NAME = index => `links[${index}]`;

export function ApplicationLinksField({
  className,
  name,
  label,
  type,
  labelAddMore,
  placeholder,
  ...props
}) {
  const { addMoreLinks } = useApplicationFormLabels();
  const [, , { setValue }] = useField(name);
  const [links, setLinks] = useState(['']);

  const onBlurHandler = useCallback(
    (link, index, currentLinks) => {
      const newLinks = currentLinks.map((v, i) => (i === index ? link : v));
      setLinks(newLinks);
      setValue(newLinks.join('\r\n'));
    },
    [setValue]
  );

  return (
    <div className={className}>
      <FormField
        {...mergeProps(props, {
          name,
          type,
          className: 'hidden'
        })}
      />
      <div className='flex flex-col gap-3'>
        {links.map((link, index) => (
          <FormField
            key={index}
            name={LINKS_FIELD_NAME(index)}
            label={index === 0 ? label : null}
            value={link}
            onBlur={e => onBlurHandler(e.target.value, index, links)}
            placeholder={placeholder}
          />
        ))}
      </div>
      <Button
        className='mt-6'
        onClick={() => setLinks(links => [...links, ''])}
      >
        {addMoreLinks}
      </Button>
    </div>
  );
}
