import React, { useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';

import { Button } from 'components/_shared/Button';
import { WaitlistTooltip } from 'components/WaitlistTooltip';

import { useUser, useUserActions, useUserAccess } from 'state';
import { modalControllerEvents } from '_events';

import './EventList.styles.scss';

const query = graphql`
  query EventListQuery {
    strapiVideospage {
      tooltip_rsvp_loggedin
      tooltip_rsvp_loggedin_heading
      tooltip_not_loggedin
      tooltip_not_loggedin_heading
    }
  }
`;

export const EventList = ({ className, events = [] }) => {
  const { strapiVideospage: fields } = useStaticQuery(query);
  const { isAuthenticated } = useUserAccess();
  const { devrev_event_ids, circleStatus } = useUser();

  const { addEvent, login } = useUserActions();

  const _className = cn('event-list', className);

  const handleRSVPClick = useCallback(
    async event => {
      if (!isAuthenticated) {
        login();
        return;
      }

      if (circleStatus.isApproved) {
        try {
          if (!devrev_event_ids?.includes(event.event_key)) {
            await addEvent(event.event_key);
          }

          modalControllerEvents.toggleModal('eventRSVP', {
            event,
            isOpen: true
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    [isAuthenticated, circleStatus.isApproved, devrev_event_ids, addEvent, login]
  );

  return (
    <>
      {!isAuthenticated && (
        <WaitlistTooltip
          heading={fields.tooltip_not_loggedin_heading}
          text={fields.tooltip_not_loggedin}
          triggerClassNames={['.event-button-rsvp']}
        />
      )}

      {isAuthenticated && !circleStatus.isApproved && (
        <WaitlistTooltip
          heading={fields.tooltip_rsvp_loggedin_heading}
          text={fields.tooltip_rsvp_loggedin}
          triggerClassNames={['.event-button-rsvp']}
        />
      )}

      <ul className={_className}>
        {events.map(event => (
          <li key={event.event_key} className='event-list-item'>
            <article className='event'>
              <h4 className='event-title'>{event.event_name}</h4>

              {event.event_date && (
                <time className='event-date' dateTime={event.event_date}>
                  {event.event_date}
                </time>
              )}

              {event.event_descriptiion && (
                <p className='event-description'>{event.event_descriptiion}</p>
              )}

              <Button
                className='event-button-rsvp'
                onClick={() => handleRSVPClick(event)}
              >
                RSVP →
              </Button>
            </article>
          </li>
        ))}
      </ul>
    </>
  );
};
