import React from 'react';
import cn from 'classnames';

import { Modal } from 'components/_shared/Modal';
import { Typeform } from '../Typeform';

import './TypeformModal.styles.scss';

export const TypeformModal = ({ className, form = {}, ...props }) => {
  const _className = cn('typeform-modal', className);

  return (
    <Modal {...props} className={_className}>
      <Typeform {...form} />
    </Modal>
  );
};
