import React from 'react';
import cn from 'classnames';
import { Link as GatsbyLink } from 'gatsby';

import { mergeProps } from 'utils';

import './Link.styles.scss';

export const Link = ({
  children,
  className,
  variant,
  size,
  color,
  href,
  icon,
  ...props
}) => {
  const isExternal = !href?.startsWith('/');
  const Tag = 'a';

  const _props = mergeProps(
    {
      className: cn(
        className?.includes('button') ? 'button' : 'link',
        className,
        variant,
        { isExternal, [`color-${color}`]: color, [`size-${size}`]: size }
      )
    },
    [
      props,
      !isExternal && { href: href },
      isExternal && { href, target: '_blank', rel: 'noreferrer' },
      props.target && { target: props.target }
    ]
  );

  return (
    <Tag {..._props}>
      {icon && <span className='icon'>{icon}</span>}
      {children && <span className='children'>{children}</span>}
    </Tag>
  );
};
