import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';

import './InputFile.styles.scss';
import { Button } from 'components/_shared/Button';
import { IconCross } from 'assets';
import { useField } from 'formik';

const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MiB

export const InputFile = ({
  className,
  type = 'file',
  variant,
  value,
  hasError = false,
  buttonLabel,
  onChange,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  ...props
}) => {
  const [, , { setValue, setTouched }] = useField(props.name);
  const [files, setFiles] = useState(
    value ? (Array.isArray(value) ? value : [value]) : []
  );
  const inputRef = useRef(null);
  const _className = cn('input-file', variant, className, {
    hasError
  });

  const openBrowseFile = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const addFiles = useCallback(
    (/** @type File[] */ files) => {
      setTouched(true);
      if (props.multiple) {
        setFiles(currentFiles => {
          const filesToAdd = files
            .filter(file => file.size <= maxFileSize)
            .filter(file =>
              currentFiles.some(
                f =>
                  f.name === file.name &&
                  f.size === file.size &&
                  f.lastModified === file.lastModified &&
                  f.type === file.type
              )
            );
          return [...currentFiles, ...filesToAdd];
        });
      } else {
        setFiles([files[0]]);
      }
    },
    [maxFileSize, props.multiple, setTouched]
  );

  return (
    <div className={_className}>
      <input
        {...props}
        ref={inputRef}
        type={type}
        className='hidden absolute inset-0 opacity-0'
        onChange={ev => {
          addFiles(ev.target.files);
          onChange?.(ev);
        }}
      />
      {files.length === 0 && (
        <Button onClick={openBrowseFile}>{buttonLabel}</Button>
      )}
      {files.length > 0 && (
        <div className='flex flex-col gap-1 border border-black w-full p-4 text-2xl'>
          {files.map((file, index) => (
            <div className='flex items-center justify-start' key={index}>
              <div className='flex-1'>{file.name}</div>
              <Button
                className='flex-0 outline-none focus:outline-none opacity-30 hover:opacity-100 p-2'
                icon={<IconCross />}
                onClick={() => {
                  const newFiles = files.filter(f => f !== file);
                  setValue(props.multiple ? newFiles : newFiles[0]);
                  setFiles(newFiles);
                }}
              />
            </div>
          ))}
          {props.multiple && (
            <div className='mt-3'>
              <Button onClick={openBrowseFile}>{buttonLabel}</Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
