import { marked } from 'marked';
import HTMLReactParser from 'html-react-parser';
import classnames from 'classnames';

import { mergeObjects } from './mergeObjects';

export function parseMarkdown(markdown, options) {
  let result = null;
  if (!markdown) return result;

  const _options = mergeObjects(
    { wrapper: { tag: 'div', class: 'content-wrapper' }, marked: false },
    options,
    (sourceValue, value, key) => {
      if (key === 'class') return classnames(sourceValue, value);
    }
  );

  _options.marked && marked.use(_options.marked);

  result = marked.parse(markdown);

  if (_options.wrapper) {
    const { tag, ...attrs } = _options.wrapper;

    const parsedAttrs = Object.entries(attrs).reduce((attrs, [key, value]) => {
      return `${attrs} ${key}='${value}'`;
    }, '');

    result = `<${tag}${parsedAttrs}>${result}</${tag}>`;
  }

  return HTMLReactParser(result);
}
