exports.onClientEntry = function (_, pluginParams) {
  const { logs, rum, ...shared } = pluginParams;

  const logsParams = { ...shared, ...logs };
  const rumParams = { ...shared, ...rum };

  function ensureCookieLaw(cb) {
    import('../../src/utils/cookie-law').then(cookieLaw => {
      const { isChecked, isAllowed } = cookieLaw.getCookieLaw();
      if (!isChecked) {
        const handler = event => {
          const { isAllowed } = cookieLaw.getCookieLaw();
          if (isAllowed) {
            cb();
          }
          window.removeEventListener('cookie-law-changed', handler);
        };
        window.addEventListener('cookie-law-changed', handler);
      } else if (isAllowed) {
        cb();
      }
    });
  }

  if (logs && logsParams.enabled === true) {
    import('@datadog/browser-logs').then(ddBrowserLogs => {
      ddBrowserLogs.datadogLogs.init(logsParams);
      window.datadogLogs = ddBrowserLogs.datadogLogs;
    });
  }
  if (rum && rumParams.enabled === true) {
    ensureCookieLaw(() =>
      import('@datadog/browser-rum').then(ddBrowserRUM => {
        ddBrowserRUM.datadogRum.init(rumParams);
        window.datadogRum = ddBrowserRUM.datadogRum;
      })
    );
  }
};
