import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import identity from 'lodash-es/identity';
import pick from 'lodash-es/pick';
import pickBy from 'lodash-es/pickBy';

import { Page } from 'components/_shared/Page';
import { Button } from 'components/_shared/Button';
import { Form, FormField, FormGroup } from 'components/_shared/Form';
import { UserApplications } from '../Applications';

import { useStrapiCommonContent } from 'hooks';
import { loaderEvents } from '_events';
import {
  selectUser,
  selectUserIsApplicationsLoaded,
  useUserActions
} from 'state';
import { mergeObjects } from 'utils';

import './UserAccount.styles.scss';

const settingsValidationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  firstname: Yup.string().required().label('First name'),
  lastname: Yup.string().required().label('Last name'),
  devrev_linkedin: Yup.string()
    .matches(
      /^((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))/,
      'Value must be a valid LinkedIn URL'
    )
    .label('LinkedIn profile')
    .required(),
  devrev_github: Yup.string().matches(
    /^(http(s?):\/\/)?(www\.)?github\.([a-z])+\/([-A-Za-z0-9]{1,})+\/?$/,
    'Value must be a valid GitHub URL'
  ),
  devrev_website: Yup.string().matches(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    'Value must be a valid URL'
  )
});

export const UserAccountSettings = () => {
  const user = useSelector(selectUser);
  const { isLoading, updateCurrentUser } = useUserActions();

  const defaultSettingsInitialValues = {
    devrev_github: '',
    devrev_linkedin: '',
    devrev_website: '',
    email: '',
    firstname: '',
    lastname: ''
  };

  const settingsInitialValues = mergeObjects(
    defaultSettingsInitialValues,
    pick(pickBy(user, identity), Object.keys(defaultSettingsInitialValues))
  );

  return (
    <Form
      initialValues={settingsInitialValues}
      validationSchema={settingsValidationSchema}
      isLoading={isLoading}
      onSubmit={updateCurrentUser}
      params={{ enableReinitialize: true }}
      className='mt-0 md:-mt-6'
      variant='secondary'
    >
      <FormGroup>
        <FormField
          label='Email address *'
          disabled={true}
          type='email'
          name='email'
        />
      </FormGroup>

      <FormGroup>
        <FormGroup direction='row'>
          <FormField className='mr-6' label='First name *' name='firstname' />
          <FormField label='Last name *' name='lastname' />
        </FormGroup>
      </FormGroup>

      <FormGroup>
        <FormField label='LinkedIn profile *' name='devrev_linkedin' />
        <FormField label='GitHub profile' name='devrev_github' />
        <FormField label='Website' name='devrev_website' />
      </FormGroup>

      <Button
        type='submit'
        className='block mt-8'
        variant='primary'
        disabled={isLoading}
      >
        Save
      </Button>
    </Form>
  );
};

export const UserAccount = () => {
  const user = useSelector(selectUser);
  const isApplicationsLoaded = useSelector(selectUserIsApplicationsLoaded);
  const commonContent = useStrapiCommonContent();

  const { logout, getUserApplications } = useUserActions();

  useEffect(() => {
    const toggleLoader = isOpen => {
      !isApplicationsLoaded && loaderEvents.toggleLoader({ isOpen });
    };

    toggleLoader(true);

    (async () => {
      await getUserApplications();
      toggleLoader(false);
    })();
  }, [isApplicationsLoaded, getUserApplications]);

  return (
    <Page meta={{ title: 'DevRev Account' }}>
      <div className='header-spacer h-24' />

      <div className='space-y-12'>
        {!!user.applications.length && (
          <div className='w-full max-w-devrev-extra mx-auto flex flex-col pt-6 px-3 md:px-6 relative lg:pt-12 md:flex-row'>
            <div className='w-full md:w-60 lg:w-80 md:mr-6 lg:mr-12 flex-shrink-0'>
              <div className='text-3xl flex pb-6 md:text-4xl lg:text-5xl leading-none w-2/3 md:w-auto'>
                {commonContent.jobapplicationlabel}
              </div>
            </div>
            <div className='w-full'>
              <UserApplications />
            </div>
          </div>
        )}
        <div className='w-full max-w-devrev-extra mx-auto flex flex-col pt-6 px-3 md:px-6 relative lg:pt-12 md:flex-row'>
          <div className='w-full md:w-60 lg:w-80 md:mr-6 lg:mr-12 flex-shrink-0'>
            <div className='text-3xl flex pb-6 md:text-4xl lg:text-5xl leading-none'>
              {commonContent.accountsettingslabel}
            </div>
            <div className='text-base md:text-xl lg:text-2xl leading-tight'>
              <p>
                Signed in as <br className='hidden md:block' />
                {`${user.email}`}
              </p>
            </div>
            <Button
              className='button-logout text-lg md:text-xl lg:text-2xl leading-tight md:mt-6'
              onClick={() => logout()}
            >
              {`${commonContent.logoutlabel} →`}
            </Button>
          </div>
          <div className='w-full'>
            <UserAccountSettings />
          </div>
        </div>
      </div>
    </Page>
  );
};
