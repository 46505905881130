const ACTION_SLUGS = {
  BETA_APPLY: 'beta-apply',
  BETA_EXPLORE: 'beta-explore',
  CIRCLE_ENTER: 'circle-enter',
  CIRCLE_LOG_IN: 'circle-log-in',
  CIRCLE_LOG_OUT: 'circle-log-out',
  CIRCLE_MY_ACCOUNT_LINK: 'circle-my-account-link',
  CONTACT_EMAIL_LINK: 'contact-email-link',
  CONTACT_US_LINK: 'contact-us-link',
  DISCORD_LINK: 'discord-link',
  EXPLORE_CIRCLE_VIDEOS: 'explore-circle-videos',
  EXPLORE_PRODUCT_LED_RESOURCES: 'explore-product-led-resources',
  INSTAGRAM_LINK: 'instagram-link',
  JOIN_DISCORD: 'join-discord',
  LINKED_IN_LINK: 'linked-in-link',
  MEDIUM_LINK: 'medium-link',
  MY_ACCOUNT_LINK: 'my-account-link',
  PRIVACY_POLICY_LINK: 'privacy-policy-link',
  TERMS_OF_SERVICE_LINK: 'terms-of-service-link',
  TWITTER_LINK: 'twitter-link',
  FOOTER_GITHUB_INTEGRATION_LINK: 'footer-git-hub-integration-link',
  FOOTER_JIRA_INTEGRATION_LINK: 'footer-jira-integration-link',
  DOCS_LINK: 'docs-link'
};

const ACTION_METHODS = {
  BETA_APPLY: 'beta_apply',
  BETA_APPLY_PAGE: 'beta_apply_page',
  CIRCLE_ENTER: 'circle_enter',
  CIRCLE_LOGIN: 'circle_login',
  CIRCLE_LOGOUT: 'circle_logout',
  NAVIGATE: 'navigate'
};

module.exports.ACTION_SLUGS = ACTION_SLUGS;
module.exports.ACTION_METHODS = ACTION_METHODS;
