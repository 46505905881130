import React from 'react';

import { InputText } from '../Text';

export const InputPhone = ({ onChange, ...props }) => {
  const handleChange = event => {
    const { value } = event.target;
    const _value = value.replace(/[^+\d]/g, '');
    event.target.value = _value;
    onChange?.(event);
  };

  return <InputText {...props} type='tel' onChange={handleChange} />;
};
